.step {
    &__head {
        position: relative;
    }
    &__item {
        cursor: pointer;
        flex: auto;
    }
    &__line {
        background: #dbdbdb;
        height: 4px;
    }
    &__text {
        background: #dbdbdb;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        height: 20px;
        left: 50%;
        line-height: 20px;
        margin-left: -8px;
        position: absolute;
        text-align: center;
        top: -8px;
        width: 20px;
    }
    &__title {
        font-size: 18px;
        margin: 10px 0;
        text-align: center;
    }
}
.step__item.active {
    & ~ .step__item {
        cursor: initial;
    }
    .step__line,.step__text {
        // background: #ff6b00;
        background: #000;
    }
    .step__title {
        // color: #ff6b00;
        color: #000;
    }
}
.steps &-container {
    display: flex;
    margin: 20px auto;
}
