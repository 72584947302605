.in-tag-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 30px auto 0;
    width: 600px;
    .in-tag {
        background: #fff;
        border: 1px solid #e5e5e5;
        color: @base-font-color;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        width: 200px;
        & span {
            .font-size-rem(14);      display: block;
        }
        &:first-child {
            border-right: none;
        }
        &:last-child {
            border-left: none;
        }
        &:nth-child(2) {
            border-left: none;
            border-right: none;
        }
    }
    div.active {
        background: @btn-base-color;
        color: #fff;
    }
    div[class$='in-tag']:hover span {
        color: @btn-base-color;
    }
    .in-tag:first-child {
        border-right: 1px solid #e5e5e5;
    }
    .in-tag:last-child {
        border-left: 1px solid #e5e5e5;
    }
}
