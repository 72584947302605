.in-modal {
    .padding-bottom-rem(50);
    .padding-left-rem(20);
    .padding-right-rem(20);
    .padding-top-rem(50);
    background-size: cover;
    background: #ffffff;
    border-radius: 4px;
    padding: 3.5rem 2.7rem 4rem;
    text-align: center;
    position: relative;
    .icon-guanbi {
        .padding-rem(0);    .font-size-rem(30);    color: #ccc;    cursor: pointer;
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .icon-tishi1 {
        color: #f0a412;
    }
    .in-btn-group-new {
        .padding-top-rem(50);
    }
    > .icon-success {
        color: @btn-base-color;
    }
    > i {
        .font-size-rem(80);    text-align: center;    .padding-bottom-rem(30);
        display: block;
    }
    p {
        text-align: center;
        word-break: break-word;
    }
    img {
      width: 5rem;
    }
    .in-btn-group-new {
        justify-content: space-between;
        padding: 2rem 0 0;
        .in-btn {
            // background-color: @main-btn-color;
            background-color: #333;
            border-radius: 6px;
            font-size: 1.4rem;
            width: 49%;
            a {
                background-color: transparent;
                border-radius: 6px;
                display: block;
                font-size: 28px;
                height: 58px;
                line-height: 58px;
                padding: 0;
            }
        }
        .in-continue-btn {
            background-color: #ff3200;
        }
    }
    .in-modal-href {
        color: #666666;
        font-size: 14px!important;
        line-height: 1.4!important;
    }
    .share-notes {
      color: #ff3200;
      margin-top: 20px;
      font-size: 14px!important;
      line-height: 1.4!important;
    }
    .title-msg {
        margin-bottom: 20px;
    }
    .xp-icon-close {
        color: #666666;
        cursor: pointer;
        display: inline-block;
        font-size: 2.4rem;
        padding: 0;
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;
    }
    .xp-icon-success {
        color: @main-btn-color;
    }
    i {
        font-size: 5rem;
        margin-bottom: 2rem;
        padding-bottom: 0;
    }
    p {
        font-size: 16px;
        line-height: 1.4;
    }
    .btn-submit {
      align-items: center;
      font-size: 0.9rem;
      height: 2rem;
      justify-content: center;
      width: 6rem;
      margin-top: 3rem;
    }
    .in-input-group {
    margin-top: 16px;
    position: relative;
    .in-choose-city,.in-choose-country,.in-choose-state {
      align-items: center;
      background-color: #fff;
      border: 1px solid #a4a4a4;
      border-radius: 4px;
      color: #ccc;
      font-size: 15px;
      height: 62px;
      padding: 0 18px;
      width: 100%;
      &.active span {
        color: #333;
      }
      I {
        color: #979797;
        font-size: 28px;
      }
    }
    input {
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #a4a4a4;
      font-size: 15px;
      height: 62px;
      outline: none;
      padding: 0 18px;
      width: 100%;
    }
    span.is-danger {
      display: block;
      margin-top: 6px;
    }
    textarea {
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #a4a4a4;
      font-size: 15px;
      outline: none;
      padding: 12px 18px;
      width: 100%;
    }
  }


}
.in-modal-href {
    color: #666;
    line-height: 1.5;
    word-break: break-all;
}

.modal {
    &-content {
        max-height: 500px;
        overflow-y: scroll;
        padding: 24px;
    }
    &-footer {
        padding: 20px;
        text-align: center;
    }
    &-header {
        border-bottom: 1px solid #F0F0F0;
        color: #262626;
        font-size: 16px;
        font-weight: 500;
        line-height: 48px;
        position: relative;
        text-align: center;
        .xp-iconfont {
            color: #8C8C8C;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 0;
        }
    }
}
