.in-address-select {
    .padding-left-rem(10);
    .padding-right-rem(10);
    display: block;
    left: 0;
    position: absolute;
    top: 21px;
    width: 100%;
    z-index: 50;
    .active {
        background: #707070;
    }
    .in-swiper {
        background: #eee;
    }
    .swiper-container {
        border-radius: 5px;
        box-shadow: 0 0 30px #b5b3b3;
    }
    .swiper-slide li {
        cursor: pointer;
        height: 26px;
        &:hover {
            background: #707070;
        }
        span {
            .font-size-rem(14);    .inline-block();    .padding-left-rem(20);    .padding-right-rem(20);    .centered-line(26px);
        }
    }
}
