.in-home-banner {
    .size(100%, 786px);
    background: #ddd;
    overflow: hidden;
    position: relative;
    z-index: 10;
    .swiper-pagination {
        .swiper-pagination-bullet-active {
            background-color: #ff3200;
            width: 2rem;
            height: .5rem
        }
        span {
            .padding-left-rem(5px);    .padding-right-rem(5px);    opacity: 1;
            background: #E5E5E5;
            border-radius: 0;
            border-radius: 50%;
            height: 18px;
            width: 18px;
        }
    }
    .swiper-slide {
        a {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          display: block;
          height: auto;
          min-height: 786px;
        }
    }
    .swiper-button-next.swiper-button-white {
        border-radius: 4px 0  0 4px;
    }
    .swiper-button-prev.swiper-button-white {
        border-radius: 0 4px 4px 0;
    }
    .swiper-button-white:hover {
        background-color: rgba(216,216,216, 0.4);
    }
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 36px;
        .swiper-pagination-bullet {
            border-radius: .25rem;
        }
    }
}

@media only screen and (max-width: 1400px ) {
  .in-home-banner {
    .size(100%, auto);
    min-height: 550px;
    .swiper-slide {
      a {
        min-height: 550px;
      }
    }
  }
}

@media only screen and (max-width: 1580px ) and (min-width: 1400px ) {
  .in-home-banner {
    .size(100%, auto);
    min-height: 550px;
    .swiper-slide {
      a {
        min-height: 600px;
      }
    }
  }
}

@media only screen and (max-width: 1800px ) and (min-width: 1580px){
  .in-home-banner {
    .size(100%, auto);
    min-height: 550px;
    .swiper-slide {
      a {
        min-height: 700px;
      }
    }
  }
}



