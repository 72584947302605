.in-choose-attrs {
    .in-attrs {
        border-top: 1px solid #dddddd;
    }
    .in-label {
        .margin-top-rem(20);    .margin-bottom-rem(20);    .font-size-rem(14);    color: @base-font-color;
        text-transform: capitalize;
    }
    span {
        cursor: pointer;
    }
    ul {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        & li {
            .margin-bottom-rem(20);
        }
    }
    span {
        .font-size-rem(24px);
    }
}
.in-choose-area,.in-choose-district,.in-choose-city,.in-choose-country,.in-choose-state {
    .padding-top-rem(5);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    i {
        color: #999;
        line-height: 23px;
    }
    span {
        display: block;
        width: 100%;
    }
}
.in-city,.in-state {
    .in-address-select {
        top: -130px;
    }
    .swiper-container {
        height: 260px;
        width: 100%;
        & .swiper-slide {
            height: auto;
        }
    }
}
