.in-create-password-content {
    .in-input-col {
        padding-bottom: 0;
    }
    .in-input-control {
        .margin-top-rem(55);
    }
    .in-promptin {
        .padding-bottom-rem(0);
        & a {
            color: @btn-base-color;
        }
    }
}
