.comment {
    &__avatar {
        margin-right: 20px;
        .avatar {
            background: #f2f2f2;
            border-radius: 4px;
            display: block;
            height: 50px;
            overflow: hidden;
            width: 50px;
        }
    }
    &__content {
        color: #666;
        font-size: 16px;
        line-height: 1.2;
        padding: 40px 0;
        a {
            color: #ff9017;
        }
    }
    &__heading {
        border-bottom: 1px solid #333;
        padding: 20px 0;
    }
    &__info {
        color: #666;
        font-size: 12px;
    }
    &__item__badge {
        margin-bottom: 10px;
    }
    &__item__msg {
        font-size: 16px;
        line-height: 24px;
        img {
            max-width: 226px;
        }
    }
    &__title {
        line-height: 1.2;
        margin-bottom: 20px;
    }
    &__wrapper {
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        margin:30px 0;
        padding: 0 20px 20px 20px;
    }
}
.comment__list {
    .comment__content {
        padding: 0;
    }
    .comment__item {
        display: flex;
        padding: 30px 0;
        + .comment__item {
            border-top: 1px solid #666;
        }
    }
    .comment__title {
        margin: 0;
    }
}
.comment__msg &::before {
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: inline-block;
    height: 14px;
    margin-bottom: 2px;
    margin-right: 8px;
    vertical-align: middle;
    width: 14px;
}
.comment__msg::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjYgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjAwLjM5MSIgaGVpZ2h0PSIyMDAiPjxwYXRoIGQ9Ik0yNTYuMjgyIDM2NS43OTVjLTM1LjU2MyAwLTYzLjc4OCAyOS4zNTQtNjMuNzg4IDY2LjA0NiAwIDM2LjEyOCAyOC43OSA2Ni4wNDcgNjMuNzg4IDY2LjA0NyAzNS41NjQgMCA2My43ODktMjkuMzU0IDYzLjc4OS02Ni4wNDcgMC0zNi4xMjgtMjguNzktNjYuMDQ2LTYzLjc4OS02Ni4wNDZ6bTI1NS43MTggMGMtMzUuNTYzIDAtNjMuNzg4IDI5LjM1NC02My43ODggNjYuMDQ2IDAgMzYuMTI4IDI4Ljc5IDY2LjA0NyA2My43ODggNjYuMDQ3IDM1LjU2MyAwIDYzLjc4OC0yOS4zNTQgNjMuNzg4LTY2LjA0NyAwLTM2LjEyOC0yOC4yMjUtNjYuMDQ2LTYzLjc4OC02Ni4wNDZ6bTI1Ni4yODIgMGMtMzUuNTYzIDAtNjMuNzg4IDI5LjM1NC02My43ODggNjYuMDQ2IDAgMzYuMTI4IDI4Ljc5IDY2LjA0NyA2My43ODggNjYuMDQ3IDM1LjU2NCAwIDYzLjc4OS0yOS4zNTQgNjMuNzg5LTY2LjA0NyAwLTM2LjEyOC0yOC43OS02Ni4wNDYtNjMuNzg5LTY2LjA0NnpNODk1Ljg2IDMyLjE3NkgxMjguMTRDNTcuNTggMzIuMTc2IDAgOTEuNDUgMCAxNjQuODM0VjY2My44NWMwIDczLjM4NSA1OC43MDggMTQxLjEyNSAxMzAuOTY0IDE0MS4xMjVIMzI2LjI4YzMzLjg3IDM2LjEyOCAxNzQuOTk1IDE4Mi4zMzMgMTc0Ljk5NSAxODIuMzMzIDYuMjA5IDYuNzc0IDE2LjM3IDYuNzc0IDIyLjU4IDAgMCAwIDEwMy4zMDMtMTE2Ljg1MiAxNzAuNDc4LTE4Mi4zMzNoMTk5LjgzMmM3Mi4yNTYgMCAxMzAuOTY0LTY3Ljc0IDEzMC45NjQtMTQxLjEyNVYxNjQuODM0QzEwMjQgOTEuNDQ5IDk2Ni45ODYgMzIuMTc2IDg5NS44NTkgMzIuMTc2em02NC4zNTMgNjI5Ljk4YzAgMzYuMTI4LTMwLjQ4MyA3NC41MTQtNjYuNjExIDc0LjUxNEg2OTcuMTU1Yy0yMy43MDggMC00NS4xNiAyNC4yNzQtNDUuMTYgMjQuMjc0TDUxMy42OTQgOTAzLjE5NyAzNzUuOTU2IDc2MS41MDhzLTI1Ljk2Ny0yNC4yNzMtNDguNTQ3LTI0LjI3M0gxMzAuOTY0Yy0zNi4xMjggMC02Ni42MTEtMzguMzg2LTY2LjYxMS03NC41MTR2LTQ5NC41YzAtMzYuMTI4IDI4Ljc5LTY2LjA0NyA2My43ODgtNjYuMDQ3aDc2OC4yODJjMzUuNTY0IDAgNjMuNzg5IDI5LjM1NCA2My43ODkgNjYuMDQ3djQ5My45MzZ6bTAgMCIvPjwvc3ZnPg==');
}
.comment__see {
    margin-right: 20px;
    &::before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTUxMiAyNDBDMTc4LjcwNCAyNDAgMTIuMzA0IDQ4My44NzIgNS4zNzYgNDk0LjI1NmEzMS45OTcgMzEuOTk3IDAgMCAwIDAgMzUuNTA0QzEyLjMwNCA1NDAuMTI4IDE3OC43MDQgNzg0IDUxMiA3ODRzNDk5LjY5Ni0yNDMuODcyIDUwNi42MjQtMjU0LjI1NmEzMS45OTcgMzEuOTk3IDAgMCAwIDAtMzUuNTA0QzEwMTEuNjk2IDQ4My44NzIgODQ1LjI5NiAyNDAgNTEyIDI0MHptMCA0ODBjLTI0OC4xMTIgMC0zOTYuMjU2LTE1NC44NDgtNDM5LjgwOC0yMDguMDY0QzExNS42IDQ1OC42MDggMjYyLjk3NiAzMDQgNTEyIDMwNGMyNDguMTEyIDAgMzk2LjI1NiAxNTQuODQ4IDQzOS44MDggMjA4LjA2NEM5MDguNCA1NjUuMzkyIDc2MS4wMjQgNzIwIDUxMiA3MjB6bTAtMzUyYy03OS40MDggMC0xNDQgNjQuNjA4LTE0NCAxNDRzNjQuNTkyIDE0NCAxNDQgMTQ0IDE0NC02NC42MDggMTQ0LTE0NC02NC41OTItMTQ0LTE0NC0xNDR6bTAgMjI0Yy00NC4xMTIgMC04MC0zNS44ODgtODAtODBzMzUuODg4LTgwIDgwLTgwIDgwIDM1Ljg4OCA4MCA4MC0zNS44ODggODAtODAgODB6IiBmaWxsPSIjMzMzIi8+PC9zdmc+');
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        display: inline-block;
        height: 14px;
        margin-bottom: 2px;
        margin-right: 8px;
        vertical-align: middle;
        width: 14px;
    }
}
