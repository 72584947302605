.in-flash-sales-container {
    .in-flash-content {
        text-align: center;
        .in-ads-title {
            .padding-top-rem(50);
        }
        .in-flash-info {
            .clearfix();
        }
        .in-flash-list {
            .make-row();
            .margin-bottom-rem(60);
            .margin-top-rem(60);
            .in-flash-product {
                .make-column(1/2, 2%);
                .margin-bottom-rem(20);
                background: #fff;
                min-height: 307px;
                transition: all .5s;
                &:hover {
                    transform: translate(0px, -5px);          box-shadow: 0 0 30px #b5b3b3;
                }
            }
            > a:nth-child(2n+1),div:nth-child(2n+1) {
                margin-left: 0;
            }
        }
        .in-img {
            .margin-right-rem(10);    .margin-left-rem(10);
            align-items: center;
            display: flex;
            flex-direction: row;
            float: left;
            height: 300px;
            justify-content: center;
            width: 240px;
        }
        .in-info {
            .padding-bottom-rem(35);
            .padding-top-rem(45);
            text-align: left;
            .final span {
                .font-size-rem(24);
            }
            .finish {
                background: @base-font-color;
            }
            .icon-bag {
                .font-size-rem(30);
            }
            .in-ads-product-price {
                .padding-top-rem(26);      .padding-bottom-rem(26);
                display: inherit;
            }
            .in-price-final span {
                .font-size-rem(36);
            }
            h5 {
                .font-size-rem(26);      color: #000;      .padding-bottom-rem(20);      overflow: hidden;      text-overflow: ellipsis;      white-space: nowrap;      padding-right: 10px;
            }
            p {
                .font-size-rem(14);      line-height: 20px;      color: #000;      overflow: hidden;      text-overflow: ellipsis;      white-space: nowrap;      padding-right: 10px;
            }
        }
    }
    .in-flash-title {
        background: #212121;
        box-shadow: 0 2px 10px hsla(0,1%,71%,.8);
        .active {
            background: #ff3200;
            h6,span {
                color: #fff;
            }
            i {
                background: #ff3200;
                bottom: -10px;
                display: inline-block;
                height: 20px;
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                transform: rotate(45deg);      transform-origin: center;
                width: 20px;
            }
        }
        h6 {
            .font-size-rem(20);     font-weight: 400;
            .padding-bottom-rem(6);     .padding-top-rem(6);
            color: @base-font-color;
        }
        span {
            .padding-bottom-rem(6);     .padding-top-rem(6);
            color: @base-font-color;
        }
        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .in-enable {
                cursor: pointer;
            }
            li {
                align-items: center;
                border-right: 1px solid @base-font-color;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: 120px;
                justify-content: center;
                position: relative;
                & i {
                    display: none;
                }
                &:last-child {
                    border: none;
                }
            }
        }
    }
}
