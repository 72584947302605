.in-shipMethods {
    width: 50%;
    .in-price {
        font-weight: 600;
    }
    .in-title {
        .font-size-rem(18);
    }
    > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    span {
        .font-size-rem(16);    color: #000;
    }
    .in-delivery {
        color: @btn-base-color;
    }
}
.in-shipMethods-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 30px 0;
    &.active::before {
        background: url("../images/shipmethods.png") no-repeat 0 0;    width: 16px;    height: 16px;    .margin-right-rem(20);
        content: '';
        display: inline-block;
    }
    &::before {
        background: url("../images/shipmethods.png") no-repeat bottom;    width: 16px;    height: 16px;    .margin-right-rem(20);
        content: '';
        display: inline-block;
    }
}
