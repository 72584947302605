.verification-page {
  height: calc(100vh - 354px);
  margin-block-start: 147px;
  width: 100vw;
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verification-container {
  height: 27.656vw;
  width: 73.906vw;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .in-verification-text {
    text-align: center;
    line-height: 2.083vw;
    font-size: 1.667vw;
    margin-bottom: 2.083vw;
  }

  .in-otp-box {
    width: 27.083vw;
    height: 2.604vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input, span {
    font-family: DINPro-Regular;
    font-size: 1.042vw;
    line-height: 1;
    position: absolute;
  }

  .in-otp-box.in-text {
    border: 1px solid #000000;
    border-radius: 4px;
    margin-bottom: 1.302vw;
    color: #000000;
    text-align: center;
  }

  .in-otp-box.in-text.alert {
    border: 1px solid #ff3200;
  }

  .in-alert-text {
    color: #ff3200;
    font-size: 0.781vw;
    line-height: 1.563vw;
    margin: 0 0 25px 0;
  }

  .in-otp-box.in-btn {
    border: 1px solid #000100;
    border-radius: 6px;
    background: #000000;
    }
    span.in-submit {
      color: #ffffff
    }

  .in-otp-box.in-btn.pressed {
    background: #ffffff;
    pointer-events: none;
    span.in-submit {
      color: #000000;
    }
  }

}
