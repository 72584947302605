.form-block {
    margin: 30px 0;
    overflow: hidden;
    &.block .form__item {
        .form__content {
            flex: 1 1 80%;
            white-space: pre-wrap;
            word-break: break-all;
        }
        input,textarea {
            border: 1px solid #dbdbdb;
            width: 100%;
        }
        label {
            flex: 1 1 20%;
        }
    }
}
.form-block.block .form__item {
    input,textarea {
        padding: 6px;
    }
}
.form-error-msg {
    color: red;
    font-size: 12px;
    padding: 10px;
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    .form-item {
        margin-bottom: 20px;
        width: floor(91% / 3);
        input {
            border-radius: 4px;
            border: 1px solid #e8e8e8;
            height: 45px;
            padding: 0 12px;
            width: 100%;
        }
        label {
            color: #333333;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 8px;
        }
    }
    .form-item:nth-child(n+1) {
        margin-left: 1.5;
        margin-right: 1.5%;
    }
}
.form__actions {
    margin: 20px 0 0;
}
.form__item {
    align-items: center;
    display: flex;
    margin: 24px 0;
    .form__content {
        flex: auto;
    }
    .native-select {
        text-align: center;
        width: 200px;
    }
    label {
        flex: auto;
        padding-right: 20px;
        text-align: right;
    }
}
.form__title {
    display: inline-block;
    font-size: 16px;
    height: auto;
    line-height: 1em;
    margin-bottom: 30px;
}
.form_col {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    & + & {
        margin-left: 20px;
    }
    &_2 {
        display: flex;
        .form_col label {
            color: #666;
            display: block;
            font-size: 12px;
            margin: 10px 0;
            padding: 0;
            text-align: left;
        }
    }
}
