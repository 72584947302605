.account-newsletter {
    .in-information-container {
        margin-bottom: 0;
        padding: 29px 45px 60px;
        .in-btn-col {
            padding: 60px 0 0;
            text-align: center;
            button {
                background: #000;
                border-radius: 6px;
                font-size: 16px;
                height: 45px;
                width: 400px;
            }
        }
        .in-common-checkout {
            margin-right: 24px;
            .in-checkbox-input {
                align-items: center;
                background-color: #f3f3f3;
                border-radius: 2px;
                display: inline-flex;
                height: 16px;
                justify-content: center;
                line-height: 16px;
                margin-right: 8px;
                margin-top: 2px;
                width: 16px;
                i {
                    color: #f3f3f3;
                    font-size: 16px;
                }
            }
            input[type="checkbox"] {
                display: none;
                &:checked + .in-checkbox-input {
                    background-color: @main-btn-color;
                }
            }
            input[type="checkbox"]:checked + .in-checkbox-input {
                background-color: #000;
            }
            label {
                color: #333;
                cursor: pointer;
                display: flex;
                font-size: 16px;
            }
        }
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
}
