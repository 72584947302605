.timeline {
    list-style: none;
    margin: 20px 0;
    padding-left: 50px;
    &-item {
        padding-bottom: 20px;
        position: relative;
        &:last-child .timeline-item__tail {
            display: none;
        }
    }
    &-item__content {
        font-size: 15px;
    }
    &-item__node {
        align-items: center;
        background-color: #e4e7ed;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        position: absolute;
    }
    &-item__node--normal {
        height: 12px;
        left: -1px;
        width: 12px;
    }
    &-item__tail {
        border-left: 2px solid #e4e7ed;
        height: 100%;
        left: 4px;
        position: absolute;
    }
    &-item__timestamp {
        color: #909399;
        font-size: 13px;
        line-height: 1;
        margin-top: 8px;
    }
    &-item__wrapper {
        padding-left: 28px;
        position: relative;
        top: -3px;
    }
}
