.account-sales-order-view {
    .continue-pay-wrapper .in-btn .in-bg-orange {
        background-color: @main-btn-color;
        cursor: pointer;
    }
    .grand_total {
        color: #666666;
        font-size: 18px;
    }
    .in-items-ordered {
        padding: 0 24px 24px;
        &:hover {
            box-shadow: none;
        }
    }
    .in-order-information {
        margin-top: 20px;
        padding: 0 24px 24px;
        &:hover {
            box-shadow: none;
        }
        .in-information-wrapper {
            border: 0;
            padding: 29px 0;
            .history-info {
                box-sizing: border-box;
                margin-left: 0;
                padding-right: 20px;
                width: 50%;
                > i {
                    color: #999999;
                    font-size: 54px;
                }
            }
            .info {
                margin-left: 24px;
                .tel {
                    color: #999999;
                    font-size: 20px;
                    line-height: 1em;
                    margin-top: 8px;
                }
                h6 {
                    color: #333333;
                    font-size: 24px;
                    line-height: 1em;
                    margin-bottom: 8px;
                }
                p {
                    color: #666666;
                    font-size: 22px;
                }
            }
        }
        .in-payment-info {
            align-items: center;
            display: flex;
            padding: 11px 0;
            &::before {
                display: none;
            }
            .info {
                margin-left: 24px;
                h6 {
                    color: #333333;
                    font-size: 24px;
                    font-weight: normal;
                    line-height: 1em;
                    margin-bottom: 8px;
                }
                p {
                    color: #666666;
                    font-size: 22px;
                }
            }
            > i {
                color: #999999;
                font-size: 54px;
            }
        }
        > h6 {
            border-bottom: 1px solid #e8e8e8;
            font-size: 24px;
            height: 64px;
            padding-left: 0;
        }
    }
    .in-ram-popup {
        .in-ram-popup__content .subTitle {
            border-bottom: 0;
            font-size: 18px;
            margin-bottom: 0;
        }
        .in-title {
            background-color: #fff;
            h3 {
                color: #333;
                font-size: 28px;
                padding-top: 0px;
            }
            i {
                color: #333;
            }
        }
        table .item td {
            padding: 0 10px;
        }
        th.total {
            margin-top: 0;
        }
    }
    .in-rma-container {
        .in-history {
            background-color: #fff;
            margin-bottom: 20px;
            padding: 0 24px 24px;
            &:hover {
                box-shadow: none;
            }
            table {
                .in-history-action {
                    .in-btn a {
                        background-color: #ffebdc;
                        border: 1px solid #ff6b00;
                        color: @main-btn-color;
                        font-size: 24px;
                        height: 38px;
                        line-height: 38px;
                        width: 120px;
                    }
                    > div {
                        align-items: flex-end;
                    }
                }
                .in-history-item {
                    margin: 0;
                    padding: 24px 0;
                    .in-info {
                        h6 {
                            color: #333333;
                            font-size: 26px;
                            font-weight: normal;
                        }
                        span {
                            color: #999999;
                            font-size: 24px;
                        }
                    }
                    .in-ordered-qty {
                        align-items: center;
                        display: flex;
                        .xp-icon-close {
                            color: #999999;
                            font-size: 30px;
                        }
                        span {
                            color: #666666;
                            font-size: 26px;
                            font-style: normal;
                        }
                    }
                }
                .in-rma-number-area {
                    align-items: center;
                    border-bottom: 1px solid #e8e8e8;
                    display: flex;
                    height: 65px;
                    justify-content: space-between;
                    span {
                        color: #bdbdbd;
                        font-size: 22px;
                    }
                }
                span {
                    padding: 0;
                }
                td {
                    border: 0;
                    padding: 0;
                }
                tr {
                    border: 0;
                }
            }
        }
        .in-order-status-list {
            background-color: #fff;
            display: flex;
            margin-bottom: 16px;
            padding: 20px 24px;
            justify-content: space-evenly;
            li {
                color: #999999;
                cursor: pointer;
                font-size: 24px;
                margin-right: 42px;
                &.active,&:hover {
                    color: @main-btn-color;
                    font-family: @font-medium;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            li.active,li:hover {
                color: #000;
            }
        }
        .in-ram-popup {
            .in-ram-popup__content {
                padding: 0;
                .in-rma-base li {
                    display: flex;
                    line-height: 22px;
                    margin-bottom: 12px;
                    &:last-child {
                        margin-bottom: 24px;
                    }
                    .in-val {
                        color: #666;
                        flex-shrink: 1;
                        font-size: 20px;
                    }
                    span {
                        color: #333;
                        flex-shrink: 0;
                        font-size: 22px;
                    }
                }
                > h6 {
                    border-bottom: 0;
                    border-top: 1px solid #e8e8e8;
                    color: #666;
                    font-size: 22px;
                    margin-bottom: 0;
                    margin-top: 0;
                    padding: 24px 0 16px;
                }
            }
            .in-title {
                background-color: #fff;
                display: flex;
                height: 64px;
                justify-content: space-between;
                line-height: 64px;
                h3 {
                    color: #333333;
                    font-size: 24px;
                }
                i {
                    color: #999999;
                    cursor: pointer;
                    font-size: 34px;
                }
            }
            table {
                table-layout: fixed;
                .history-order-header {
                    .qty {
                        width: auto;
                    }
                    th span {
                        height: auto;
                        min-height: 50px;
                        padding: 10px 0;
                    }
                }
                .item td span {
                    color: #999999;
                }
                tbody tr td:first-child {
                    width: auto;
                }
            }
        }
        .in-rma-no-data {
            color: #666666;
            text-align: center;
        }
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
    .subtotal {
        margin-top: 16px;
    }
    .total {
        color: #999999;
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-top: 12px;
        min-width: 300px;
        div:first-child {
            font-style: italic;
            margin-right: 20px;
        }
    }
    .total-area {
        align-items: flex-end;
        color: #666;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
    }
    table {
        margin-bottom: 0;
        .history-order-header {
            background-color: #eee;
            th {
                border: 1px solid #e8e8e8;
                span {
                    align-items: center;
                    color: #666;
                    display: flex;
                    font-size: 22px;
                    height: 64px;
                    justify-content: center;
                    line-height: 1.2em;
                }
            }
        }
        .history-order-title {
            line-height: 52px;
            .in-back {
                padding-right: 0;
                i {
                    color: #999999;
                    font-size: 34px;
                }
            }
            span {
                color: #333;
                display: inline-block;
                font-size: 24px;
                line-height: 1em;
            }
        }
        .item {
            border-bottom: 0;
            td {
                border: 1px solid #e8e8e8;
                padding: 0;
                span {
                    align-items: center;
                    color: #666;
                    display: flex;
                    font-size: 20px;
                    justify-content: center;
                    line-height: 1.2em;
                    min-height: 112px;
                    padding: 20px;
                    word-break: break-word;
                }
            }
        }
    }
}
