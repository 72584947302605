.in-time-d,.in-time-h,.in-time-m,.in-time-s {
    .font-size-rem(66);
}
.in-time-subtitle {
    .font-size-rem(24);  color: #666666;
    text-align: center;
}
.in-timer {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    height: 444px;
    justify-content: center;
}
.in-timer-center {
    justify-content: center;
}
.in-timer-container {
    margin-top: 36px;
    .in-time-d,.in-time-h,.in-time-m,.in-time-s {
        .font-size-rem(66);
    }
    .in-time-subtitle {
        .font-size-rem(24);    color: #666666;    margin-top: 10px;
        text-align: center;
    }
    .in-timer {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: row;
        height: 444px;
        justify-content: center;
    }
    .in-timer-center {
        justify-content: center;
    }
    .in-timer-content {
        display: flex;
        .in-btn-group {
            .margin-top-rem(20);
            .padding-left-rem(20);
            .padding-right-rem(20);
            border-radius: 24px;
            box-shadow: none;
            font-size: 24px;
            height: 48px;
            line-height: 48px;
            padding: 0 50px;
            width: auto;
            i {
                .font-size-rem(30);
            }
            span {
                .font-size-rem(24);
            }
        }
        > div {
            .inline-block();      text-align: left;      max-width: 480px;
        }
        ul {
            display: flex;
            & li {
                .padding-left-rem(0);
                .padding-right-rem(0);
                border: 0;
                display: inline-block;
                .font-montreal {
                    font-family: @font-medium;
                    text-align: center;
                }
                span {
                    display: block;
                }
            }
        }
        .in-time-split {
            font-size: 58px;
            margin: 0 20px;
        }
    }
    .in-timer-content_desc {
        .font-size-rem(14);    .padding-bottom-rem(0);    line-height: 18px;    color: #666666;    word-wrap: break-word;    word-break: normal;
    }
    .in-timer-content_title {
        .font-size-rem(30);    .padding-top-rem(42);    .padding-bottom-rem(0);    word-wrap: break-word;    word-break: normal;    color: #333;    line-height: 1.2em;
    }
    .in-timer-left {
        justify-content: flex-start;
    }
    .in-timer-price {
        .padding-top-rem(8);    .padding-bottom-rem(8);    margin-top: 7px;
        .is-special-price {
            .font-size-rem(20);    color: #999999;
            text-decoration: line-through;
        }
        p {
            .font-size-rem(34);    .inline-block();    color: #333333;
        }
    }
    .in-timer-right {
        justify-content: flex-end;
    }
    .in-timer-special-price {
        font-family: @font-medium;
        padding: 0 10px 0 0;
    }
}
.in-timer-content {
    display: flex;
    .in-btn-group {
        .padding-left-rem(20);
        .padding-right-rem(20);
        border-radius: 24px;
        height: auto;
        line-height: 1em;
        padding: 10px 30px;
        width: auto;
        i {
            .font-size-rem(30);
        }
        span {
            .font-size-rem(24);
        }
    }
    > div {
        .inline-block();    text-align: left;    max-width: 430px;
    }
    ul {
        display: flex;
        & li {
            .padding-left-rem(0);
            .padding-right-rem(0);
            display: inline-block;
            .font-montreal {
                text-align: center;
                width: 60px;
            }
            span {
                display: block;
            }
        }
    }
    .in-time-split {
        font-size: 58px;
        margin: 0 20px;
    }
}
.in-timer-content_desc {
    .font-size-rem(14);  .padding-bottom-rem(0);  line-height: 18px;  color: #666666;  word-wrap: break-word;  word-break: normal;
}
.in-timer-content_title {
    .font-size-rem(30);  .padding-top-rem(25);  .padding-bottom-rem(0);  font-weight: 500;  word-wrap: break-word;  word-break: normal;  color: #333;  line-height: 1.2em;
}
.in-timer-img {
    display: block;
}
.in-timer-left {
    justify-content: flex-start;
}
.in-timer-people {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .in-people {
        .padding-left-rem(30);
        display: flex;
        text-align: center;
        > div {
            border-right: 1px solid #ccc;
            &:first-child {
                .padding-right-rem(30);
            }
            &:last-child {
                .padding-left-rem(30);        border-right: none;
            }
        }
        span {
            .font-size-rem(14);      .padding-bottom-rem(5);      line-height: 20px;
            display: block;
        }
    }
}
.in-timer-price {
    .padding-top-rem(10);  .padding-bottom-rem(10);
    .is-special-price {
        .font-size-rem(20);  color: #999999;
        text-decoration: line-through;
    }
    p {
        .font-size-rem(34);  .inline-block();  color: #333333;
    }
}
.in-timer-right {
    justify-content: flex-end;
}
.in-timer-special-price {
    .padding-right-rem(10);
}
