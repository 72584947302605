.content-container {
    width: 100vw;
    height: 100vh;
    display: flex;
  }

.left-content, .right-content {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.left-content {
    background: black;
    justify-content: center;

    .content-wrapper {
      display: flex;
      flex-direction: column;
    }

    .logo {
      width: 426px;
      height: 35px;
      background-image: url("../images/logo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .title {
      text-transform: uppercase;
      color: white;
      font-size: 36px;
      margin-top: 15px;
      text-align: left;
      line-height: .8;
    }
  }
