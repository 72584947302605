.in-product {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    transition: all .5s;
    &:hover {
        transform: translate(0px, -2px);    box-shadow: 0 15px 30px rgba(0,0,0,0.1);
    }
    .in-ads-product-img {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        & .in-flag-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 10px;
            min-height: 1.1rem;
            .in-new-icon {
                background: #000;
            }
            .in-sale-icon {
                background: #FF3200;
            }
            span {
                color: #fff;
                display: inline-block;
                font-size: 0.7rem;
                line-height: 1.1rem;
                margin-right: 6px;
                padding: 0px 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        a {
            display: inline-block;
            padding: 0;
            width: 100%;
        }
    }
}
.in-product-nav {
    background: #fff;
    width: 100%;
    z-index: 10;
    .active {
        color: #000;
    }
    a {
        .padding-right-rem(40);    .padding-left-rem(40);    border-left: 1px solid #b0b0b0;    .font-size-rem(12);    color: #b0b0b0;    text-transform: uppercase;    .centered-line(14px);
    }
    li {
        .centered-line(60px);
        &:first-child a {
            border: 0;
        }
    }
    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
.in-product-price {
    .margin-top-rem(6);
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .in-origin-price,.in-price {
        .font-size-rem(36);    color: #000;
    }
    .in-product-timer {
        flex-direction: column;
    }
    > div {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        .member-price {
          img {
            margin: 0 10px 10px 0;
          }
        }
    }
    div[have-special='yes'] {
        .font-size-rem(18);    .margin-left-rem(10);
        color: #999999;
        text-decoration: line-through;
    }
}
.in-product-qty {
    .margin-bottom-rem(20);
    .margin-top-rem(20);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    i {
        .centered-line(48px);
        .font-size-rem(14);    cursor: pointer;
        background: #fff;
        display: inline-block;
        text-align: center;
        width: 50px;
    }
    input {
        .padding-left-rem(20);    .padding-right-rem(20);    .font-size-rem(16);    font-style: italic;
        border: none;
        outline: none;
        text-align: center;
        width: 120px;
    }
}
.in-product-sepc .in-container {
    min-height: auto;
}
.in-product-timer {
    > h6 {
        .font-size-rem(12);    display: block;    .padding-top-rem(3);    .padding-bottom-rem(3);    .padding-left-rem(10);    .padding-right-rem(10);    color: #fff;    font-weight: 200;    width: 100%;    border-top-left-radius: 5px;    border-top-right-radius: 5px;    border: 1px solid transparent;
        background: #b3b3b3;
    }
    ul {
        .padding-bottom-rem(10);
        .padding-top-rem(10);
        background: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: none;
        border: 1px solid #e3e4e6;
        display: flex;
        text-align: center;
        & li {
            .padding-left-rem(24);
            .padding-right-rem(24);
            border-right: 1px solid #ccc;
            &:last-child {
                border-right: none;
            }
            span {
                display: block;
            }
            span:first-child {
                .font-size-rem(22);
            }
            span:last-child {
                .font-size-rem(12);      color: @btn-base-color;
            }
        }
    }
    .flat-angle {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    ul {
        background-color: #fdfbfe;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-top: 1px solid #e3e4e6;
    }
}
