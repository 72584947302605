.in-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    a {
        .font-size-rem(12);    color: #8d8d8d;
    }
    i {
        .padding-right-rem(5);
        color: #8d8d8d;
    }
}
.in-header-container {
    display: flex;
    flex-direction: column;
    height: 160px;
    justify-content: center;
    text-align: center;
    width: 100%;
    .in-help {
        background: none;
        padding: 0;
        a {
            .inline-block();      .font-size-rem(14);
            color: @btn-base-color;
            cursor: pointer;
            text-decoration: underline;
        }
        p {
            .inline-block();      .font-size-rem(14);
            color: @base-font-color;
        }
        h3 {
            .font-size-rem(36);    color: @base-font-color;    .padding-top-rem(10);    .padding-bottom-rem(10);    font-weight: 400;
        }
    }
}
