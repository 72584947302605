.coupon__title {
    position: relative;
    text-align: center;
    &::before {
        background: linear-gradient(270deg, rgba(255, 119, 0, 0) 0%, #FF7400 54%, rgba(255, 119, 0, 0) 100%);      opacity: 0.44;      position: absolute;      width: 100%;      top: 3px;      left: 50%;      height: 28px;      transform: translateX(-50%);      max-width: 500px;
        content: '';
    }
    span {
        color: #FFDCC2;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
    }
}
