.in-collocation-wrapper {
    display: block!important;
    .in-co-preview {
        padding: 0 22px;
        .in-swiper-button-next {
            background: #f0f0f0;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 22px;
            z-index: 100;
            & i {
                color: #fff;
                cursor: pointer;
                display: inline-block;
                height: 16px;
                margin-left: 3px;
                margin-top: -8px;
                position: absolute;
                top: 50%;
                transform: rotate(270deg);
                width: 16px;
            }
        }
        .in-swiper-button-prev {
            background: #f0f0f0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 22px;
            z-index: 100;
            & i {
                color: #fff;
                cursor: pointer;
                display: inline-block;
                height: 16px;
                margin-left: 3px;
                margin-top: -8px;
                position: absolute;
                top: 50%;
                transform: rotate(90deg);
                width: 16px;
            }
        }
        .swiper-button-disabled {
            display: none;
        }
    }
    .in-collcaation-l {
        display: table-cell;
        min-width: 324px;
        width: 314px;
    }
    .in-collcation-r {
        display: table-cell;
        overflow: hidden;
        padding-left: 20px;
        text-align: right;
        width: 100%;
        .in-btn {
            border: 1px solid #cccccc;
            display: inline-block;
            line-height: 28px;
            margin: 10px 0;
            text-align: center;
            width: 90px;
            & span {
                .font-size-rem(14);        color: #cccccc;
            }
            &:hover {
                border-color: @btn-base-color;
                & span {
                    color: @btn-base-color;
                }
            }
        }
        .in-col-top {
            border-bottom: 1px solid #e6e6e6;
            padding-bottom: 10px;
            text-align: left;
        }
        .special-price .price {
            color: #cccccc;
        }
        h5 {
            .font-size-rem(16);
            font-weight: 400;
            overflow: hidden;
            text-overflow:ellipsis;
            word-wrap:break-word;
        }
        span {
            color: #000;
            cursor: default;
            font-size: 14px;
        }
    }
    img {
        border: 2px solid transparent;
        height: 80px;
        opacity: 1!important;
        width: 80px;
    }
    img.active {
        border: 2px solid @btn-base-color;
    }
    li {
        align-items: center;
        background: #fff;
        display: flex;
        padding: 20px;
        position: relative;
        transition: all .5s;
        width: 100%;
        & .in-choose-flag {
            border-color: transparent #009944 transparent transparent;
            border-style: solid;
            border-width: 0 30px 30px 0;
            height: 0;
            margin: 0;
            margin: 40px auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            & .icon-choose {
                color: #fff;
                font-size: 20px;
                position: absolute;
                right: -31px;
                top: -2px;
            }
        }
        &:hover {
            box-shadow: 0 2px 30px #b5b3b3;
        }
    }
}
