.xp-country-close {
    color: #666666;
    cursor: pointer;
    font-size: 24px;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
}
.xp-country-container {
    background: rgba(39, 39, 39, 0.5);  display: flex;  align-items: center;  justify-content: center;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}
.xp-country-content {
    background-color: #000;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);  border-radius: 4px;  z-index: 999;  display: flex;  flex-direction: column;  align-items: center;  padding: 36px 24px;  max-width: 704px;  position: relative;
}
.xp-country-item {
    margin-right: 4px;
    margin-top: 24px;
    width: 160px;
    .xp-country-image {
        border-radius: 50%;
        height: 32px;
        margin-right: 12px;
        width: 32px;
    }
    .xp-country-link {
        align-items: center;
        display: flex;
        &.active span {
            color: #333333;
            font-family: @font-medium;
            font-weight: 500;
        }
        span {
            color: #666666;
            font-size: 16px;
            word-break: break-all;
        }
    }
    .xp-country-link.active span {
        color: #fff;
    }
}
.xp-country-list,.xp-search-list {
    display: flex;
    flex-wrap: wrap;
}
.xp-country-logo {
    width: 180px;
}
.xp-country-tips {
    border-bottom: 1px solid #eeeeee;
    color: #fff;
    font-size: 16px;
    margin-top: 10px;
    padding-bottom: 24px;
    text-align: center;
    width: 100%;
}
.xp-hot-keywords {
    background-color: transparent;
    border-bottom: 1px solid #000;
    border-radius: 0;
    color: #000;
    display: inline-block;
    margin-right: 12px;
    margin-top: .3rem;
    padding: 0;
    a {
        color: #000;
        font-family: DINPro-Regular;
        font-size: 14px;
        margin: 0 .15rem .15rem .1rem;
    }
}
.xp-hot-keywords-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}
.xp-hot-keywords-title {
    color: #000;
    font-family: DINPro-Bold;
    font-size: .6rem;
    font-weight: bold;
    margin-bottom: 0;
}
.xp-iconfont {
    font-size: 20px;
}
.xp-search-container {
    background-color: transparent;
    border-radius: 4px;
    flex-grow: 1;
    height: 50px;
    left: 0;
    position: static;
    top: 0;
    width: auto;
    .xp-search-box-area {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        height: 50px;
        line-height: 50px;
        padding: 0;
        width: 100%;
        .home-search {
            width: 15px;
        }
        .search-icon-btn {
            align-items: center;
            cursor: pointer;
            display: flex;
        }
        .xp-icon-close {
            .font-size-rem(42px);      margin-right: .7rem;      color: #666666;      display: none;
        }
        .xp-search-box {
            background: transparent;
            border: 0;
            color: #fff;
            flex-grow: 1;
            font-family: DINPro-Regular;
            font-size: .8rem;
            outline: none;
            width: 11.9rem;
            border-bottom: 1px solid #9e9e9e;
        }
        .xp-search-icon {
            .font-size-rem(38px);      color: @main-btn-color;
            .font-size-rem(42px);      color: #666666;
        }
    }
    .xp-search-content {
        margin-right: 0;
        position: relative;
    }
}
.xp-search-item {
    align-items: center;
    border-radius: 4px;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    display: flex;
    min-height: 100px;
    padding: 8px 10px;
    width: 100%;
    &:hover {
        background-color: #ffebdc;
        border-color: #ff6b00;
    }
    .xp-search-product {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        line-height: 1.3em;
        .xp-search-product-name {
            color: #666666;
            line-height: 1em;
            margin-bottom: 8px;
            width: 100%;
        }
        .xp-search-product-price {
            color: #999999;
            text-decoration: line-through;
            .price {
                color: #999999;
            }
        }
        .xp-search-product-sale {
            align-items: flex-end;
            color: #333333;
            display: flex;
            line-height: 1em;
            margin-right: 10px;
            .price {
                color: #333333;
            }
        }
    }
    a {
        align-items: center;
        display: flex;
    }
    img {
        height: 80px;
        margin-right: 12px;
        width: 80px;
    }
}
.xp-search-modal {
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
    height: auto;
    left: 0;
    line-height: 1em;
    overflow-y: auto;
    padding: 0 0 1rem;
    position: absolute;
    top: 49px;
    width: 100%;
    z-index: 5;
    .history-content {
      max-height: 3.9rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .search-history {
        border-bottom: 1px solid #000;
        display: inline-block;
        margin: .5rem .8rem;
            color: #000;
            font-family: DINPro-Regular;
            font-size: .8rem;
    }
    .search-history-head {
        align-items: center;
        background: #f5f5f5;
        display: flex;
        font-family: DINPro-Regular;
        font-size: .7rem;
        font-weight: bold;
        height: 1.9rem;
        justify-content: space-between;
        padding: 0 .75rem 0 .8rem;
        img {
            float: right;
            width: .6rem;
        }
        span {
            font-family: DINPro-Bold;
            color: #000;
            font-size: .6rem;
        }
    }
    .xp-hot-keywords-area {
        padding: 2rem .75rem 0 1rem;
        img {
            height: .85rem;
            margin-right: .35rem;
            width: .65rem;
        }
    }
}
