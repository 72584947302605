.in-quick-tool {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 20px;
}
.in-quick-wrapper {
    & > ul {
        .clearfix();
        & > li {
            float: left;
            &       > a,span {
                .inline-block();        border-left: 1px solid rgba(255, 255, 255, 0.5);        .centered-line(14px);        .padding-left-rem(20px);        .padding-right-rem(20px);
            }
            &:first-child a {
                border-left: none;
                padding-right: 0;
            }
        }
    }
    ul li {
        .centered-line(40px);
    }
}
