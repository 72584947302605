.in-information-container {
    background: #fff;
    height: auto;
    transition: all .5s;
    width: 75%;
    & .in-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    }
}
.in-information-content {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
    .in-dashboard-container {
        flex-grow: 1;
    }
    .in-menus-container {
        flex-shrink: 0;
        margin-right: 20px;
        padding-right: 0;
        padding-top: 302px;
        width: 360px;
        .active,li:hover {
            background-color: #000;
            span {
                background-color: #000;
                color: #fff;
            }
        }
        > ul {
            background-color: #fff;
        }
        li {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            height: 60px;
            padding: 0 5rem;
            position: relative;
            &:last-child {
                border-bottom: 0;
            }
            .in-triangle-right {
                display: none;
            }
            i {
                color: #666;
                font-size: 42px;
                margin-right: 10px;
            }
            span {
                color: #666;
                font-size: 16px;
            }
        }
    }
}
