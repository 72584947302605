.in-product-info {
  .xpark-shop-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    padding: 14px 0;
    .xpark-logo {
      width: 166px;
      margin-right: 18px;

    }
    .shop-info {
      display: flex;
      min-width: 300px;
      padding: 0 20px;
      border-left: 1px solid #EBEBEB;
      cursor: pointer;


      .logo {
        height: 45px;
        width: 45px;
        margin-right: 15px;

        img {
          width: 100%;
        }
      }

      .detail {
        .title {
          color: #333333;
          font-size: 20px;
          height: 29px;
          text-align: left;
        }
        .description {
          font-size: 16px;
          color:#666666
        }
      }
    }
    .contact {
      height: 45px;
      display: flex;
      align-items: flex-end;
      min-width: 300px;
      font-size: 16px;
      color: #666666;
      padding: 0 20px;
      border-left: 1px solid #EBEBEB;

      .tel {
        margin-right: 50px;
      }

      img {
        vertical-align: bottom;
      }
    }
  }

    .in-add-cart-wrapper {
      border-top: 1px solid #e8e8e8;
        .in-btn {
            align-items: center;
            display: flex;
            justify-content: center;
            i {
                font-size: 40px;
                margin-right: 5px;
                vertical-align: middle;
            }
            img {
              width: 1.4rem;
              margin-right: 7%;
            }
            span {
                font-size: .9rem;
            }
        }
        .in-btn-wishlist {
            background-color: #ddd;
            justify-content: unset;
            padding: 0 6px;
            width: auto;
            i::before {
              color: #000;
            }
            i,span {
                color: #ffffff;
            }
            img {
              margin-right: 0;
            }
        }
    }
    .in-btn-addtocard {
        background-color: #000;
    }
    .in-choose-attrs {
        .in-attrs {
            border-top: 0;
        }
        .in-label {
            font-size: .6rem;
            display: inline-block;
            color: #000;
            width: 3.2rem;
            margin-bottom: 0.4rem;
            margin-top: 0;
        }
        .in-product-qty {
            background-color: #ddd;
            border-radius: 4px;
            display: inline-flex;
            margin-bottom: 1.2rem;
            margin-top: 0;
            padding: 5px 16px;
            i {
                background-color: transparent;
                color: #000;
                font-size: 1rem;
                font-weight: bold;
                height: 28px;
                line-height: 1.5em;
                width: 28px;
            }
            input {
                background-color: transparent;
                color: #333;
                font-size: .8rem;
                font-style: normal;
                padding: 0 10px;
                padding: 0;
                width: 50px;
            }
        }
        div.in-attrs {
            span {
                background-color: #ddd;
                border-radius: 4px;
                border: 1px solid #ddd;
                box-shadow: none;
                color: #333;
                font-size: .8rem;
                height: 38px;
                line-height: 1em;
                padding: 8px 16px;
                width: auto;
            }
            span.active {
                background-color: #fff;
                border-color: #ff3200;
                // border: 1px solid #ff6b00;
                box-shadow: none;
                color: #ff3200;
            }
        }
        div[name$="color"] .in-out-of-stock span {
            color: #999;
        }
        ul li {
            margin-bottom: 1.2rem;
        }
    }
    .in-des {
        color: #666666;
        margin-bottom: 20px;
    }
    .in-item-title {
        color: #000;
        font-family: DINPro-Bold;
        font-size: 1.4rem;
    }
    .in-modal {
        background: #ffffff;
        border-radius: 4px;
        padding: 3.5rem 2.7rem 4rem;
        .in-btn-group-new {
            justify-content: space-between;
            padding: 2rem 0 0;
            .in-btn {
                background-color: #000;
                border-radius: 6px;
                font-size: 1.4rem;
                width: 49%;
                a {
                    background-color: transparent;
                    border-radius: 6px;
                    display: block;
                    font-size: 16px;
                    height: 58px;
                    line-height: 58px;
                    padding: 0;
                }
            }
            .in-continue-btn {
                background-color: #ff3200 !important;
            }
        }
        .xp-icon-close {
            color: #666666;
            font-size: 2.4rem;
            position: absolute;
            right: 1.6rem;
            top: 1.6rem;
        }
        .xp-icon-success {
            color: @main-btn-color;
        }
        i {
            font-size: 5rem;
            margin-bottom: 2rem;
            padding-bottom: 0;
        }
        p {
            font-size: 28px;
        }
    }
    .in-no-review-list {
        height: 355px;
        line-height: 355px;
        padding-bottom: 0;
        span {
            color: #666;
            font-size: 26px;
        }
    }
    .in-overview {
        .in-container {
            margin-bottom: 0;
            margin-top: 0;
            min-height: 0;
        }
        .in-overview-container {
            padding: 2.4rem 0;
        }
    }
    .in-pagination-wrapper {
        display: flex;
        justify-content: center;
        .in-next,.in-previous {
            align-items: center;
            border-radius: 0;
            border: 1px solid #d9d9d9;
            color: #666;
            display: inline-flex;
            font-size: 18px;
            height: 34px;
            justify-content: center;
            line-height: 34px;
            margin-right: 10px;
            min-width: 34px;
            padding: 0;
            text-align: center;
            vertical-align: middle;
            &.active {
                background-color: @main-btn-color;
                border-color: @main-btn-color;
                color: #ffffff;
            }
            &.isDisabled {
                color: #ccc;
            }
            i {
                transform: rotate(0);        font-size: 18px;
            }
        }
        a,span {
            align-items: center;
            border-radius: 0;
            border: 1px solid #d9d9d9;
            color: #666;
            display: inline-flex;
            font-size: 18px;
            height: 34px;
            justify-content: center;
            line-height: 34px;
            margin-right: 10px;
            min-width: 34px;
            padding: 0;
            text-align: center;
            vertical-align: middle;
            &.active {
                background-color: @main-btn-color;
                border-color: @main-btn-color;
                color: #ffffff;
            }
            &.isDisabled {
                color: #ccc;
            }
        }
        li {
            display: flex;
        }
        span.active {
            background-color: #000;
            border-color: #000;
        }
    }
    .in-preview {
        position: initial;
        width: 506px;
        .in-swiper {
            height: auto;
            max-width: 600px;
        }
    }
    .in-product-border-bottom {
        border-bottom: 1px solid #e8e8e8;
    }
    .in-product-nav {
        align-items: center;
        border-top: 1px solid #e8e8e8;
        display: flex;
        height: 88px;
        line-height: 88px;
        .in-container {
            justify-content: flex-start;
        }
        .in-reviews-nav-area {
            align-items: center;
            display: flex;
            justify-content: space-between;
            .in-reviews-edit-area {
                align-items: center;
                display: flex;
                i {
                    cursor: pointer;
                    font-size: 2.4rem;
                }
            }
        }
        li {
            border-left: 1px solid #e8e8e8;
            font-size: 26px;
            height: auto;
            line-height: 1;
            padding: 0 32px;
            &:first-child {
                border-left: 0;
                padding-left: 0;
            }
            a {
                border-left: 0;
                color: #666666;
                display: inline-block;
                font-size: 26px;
                height: auto;
                line-height: initial;
                padding: 0;
            }
            a.active {
                color: #000;
                span {
                    border-bottom-color: #000;
                    // border-bottom: 2px solid @main-btn-color;
                }
            }
            span {
                border-bottom: 2px solid transparent;
                padding-bottom: 4px;
            }
        }
    }
    .in-product-price {
        margin-bottom: 20px;
        margin-top: 12px;
        .in-price {
            color: #ff3200;
            font-size: 1.4rem;
        }
        .in-product-timer {
            > h6 {
                border-radius: 0;
            }
            ul li span:first-child {
                display: block;
            }
        }
        div[have-special="yes"] {
            font-size: 1rem;
            span {
                text-decoration: line-through;
            }
        }
        span {
            display: inline-block;
        }
    }
    .in-product-reviews-wrapper {
        padding: 0;
        .in-reviews {
            padding: 0;
            .in-rating {
                margin-left: 12px;
                margin-top: 0;
                .active {
                    color: @main-btn-color;
                }
                li {
                    margin-right: 8px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            .in-reviews-left {
                align-items: center;
                display: flex;
                float: none;
                padding-left: 0;
                width: 100%;
                h5 {
                    color: #999999;
                    font-size: 26px;
                    font-weight: normal;
                    line-height: 1em;
                }
                span {
                    color: #cccccc;
                    flex-grow: 1;
                    font-size: 24px;
                    text-align: right;
                }
            }
            .in-reviews-right {
                float: none;
                margin-top: 20px;
                width: 100%;
                h5 {
                    color: #333;
                    font-size: 26px;
                    font-weight: normal;
                }
                p {
                    font-size: 24px;
                    line-height: 34px;
                    margin-top: 16px;
                    word-break: break-word;
                }
            }
            > div {
                padding: 34px 0 16px;
            }
        }
    }
    .in-rating-wrapper {
        border-top: 0;
        padding: 0;
        .in-input-form {
            padding: 6px 0 36px;
            position: relative;
            .close-rating-modal-btn {
                color: #666666;
                font-size: 48px;
                position: absolute;
                right: 32px;
                top: 10px;
            }
            .in-btn-col {
                justify-content: center;
                padding-bottom: 0;
                a {
                    background-color: #ff3200;
                    border-radius: 6px;
                    border: 0;
                    color: #ffff;
                    font-size: 28px;
                    height: 58px;
                    line-height: 58px;
                    margin-left: 32px;
                    width: 300px;
                }
                button {
                    border-radius: 6px;
                    color: #ffff;
                    font-size: 28px;
                    height: 58px;
                    line-height: 58px;
                    width: 300px;
                }
            }
            .in-input-list {
                padding: 0 30px;
                > li:first-child {
                    margin-bottom: 24px;
                }
            }
            .in-rating ul li {
                margin-top: 0;
            }
            .in-reviews-text {
                border-radius: 4px;
                border: 0;
                height:170px;
                padding: 16px 24px;
                resize: vertical;
            }
            .in-text {
                border-radius: 4px;
                border: 0;
                height: 58px;
                padding: 0 24px;
            }
            .reviewsImg {
                > div {
                    background-color: #ffffff;
                    color: #bebebe;
                }
                i {
                    align-items: center;
                    background-color: #f3f3f3;
                    border-radius: 4px;
                    color: #bebebe;
                    display: flex;
                    height: 140px;
                    height: auto;
                    justify-content: center;
                    padding: 20px;
                    width: 140px;
                }
                ul.previewImg {
                    padding: 0;
                    li {
                        border-radius: 4px;
                        height: 140px;
                        margin-right: 8px;
                        margin-top: 0;
                        width: 140px;
                    }
                }
            }
            > h5 {
                border-bottom: 1px solid #eeeeee;
                font-size: 30px;
                font-weight: normal;
                margin-bottom: 32px;
                margin-left: 0;
                padding-bottom: 20px;
                text-align: center;
            }
            input:-moz-placeholder,input:-ms-input-placeholder,input::-webkit-input-placeholder,textarea::-moz-placeholder,textarea::-ms-input-placeholder,textarea::-webkit-input-placeholder {
                color: #999;
            }
            ul li {
                margin-top: 16px;
                padding: 0;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .in-rating li {
            color: #999999;
            margin-left: 12px;
            .active {
                color: @main-btn-color;
            }
            i {
                font-size: 2.2rem;
            }
        }
        .in-reviews-list {
            padding: 0;
        }
        label {
            color: #666666;
            font-size: 26px;
            width: auto;
        }
    }
    .in-specs {
        padding: 2.4rem 0;
        ul {
            padding-bottom: 0;
            &:first-child {
                padding-top: 0;
            }
        }
    }
    .in-upsell {
        .margin-top-rem(20);    background: #fff;
    }
    .previewImg li {
        border-radius: 4px;
        border: 0;
        height: 100px;
        width: 100px;
    }
    .v--modal-overlay {
      .in-modal {
        text-align: center;
        img {
          width: 5rem;
        }
      }
    }
}
