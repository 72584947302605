.in-cart-bar {
    background: #fff;
    border-top: 1px solid #cccccc;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 118px;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 100;
    .in-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .in-item-count {
        .font-size-rem(16);    font-style: italic;    color: @base-font-color;
    }
    .in-total {
        .centered-line(48px);
        &       > span:first-child {
            .font-size-rem(18);      color: #333333;      font-style: italic;
        }
        > span:last-child {
            .font-size-rem(30);      color: #333333;      font-weight: 600;
        }
    }
}
