.in-coupon-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .active {
        border: 2px solid @btn-base-color;
        & .in-choose-add {
            opacity: 1;
        }
    }
    .in-coupon {
        .margin-bottom-rem(20);
        .margin-right-rem(20);
        border: 2px solid transparent;
        cursor: pointer;
        position: relative;
        text-align: center;
        width: 270px;
        .in-choose-add {
            align-items: center;
            background: @btn-base-color;
            color: #fff;
            display: flex;
            flex-direction: column;
            height: 18px;
            opacity: 0;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 18px;
            & i {
                .font-size-rem(14);        line-height: 18px;
            }
        }
        .in-coupon-sale {
            background: linear-gradient(to right, #d9c38a , #e1d2a7);
            & h6 {
                background-image: url('../images/couponBg.png');        background-position: left 103%;        background-repeat: repeat-x;        .font-size-rem(36);        .padding-top-rem(20);        .padding-bottom-rem(20);        font-weight: 400;        color: #674e0a;        text-shadow: 0px 1px #fff;
            }
        }
        .in-coupon-time {
            .padding-bottom-rem(10);
            .padding-top-rem(10);
            background: #fff;
            & p {
                .font-size-rem(12);        line-height: 12px;        .padding-left-rem(20);        .padding-right-rem(20);
                color: @base-font-color;
            }
        }
    }
    .in-coupon {
        box-shadow: 0 2px 10px hsla(0, 1%, 71%, 0.8);
    }
}
