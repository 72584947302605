.merchant-payment{
  width: 100vw;
  margin: 0;
  padding: 0;
  height: unset;
  .container {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    background: white;
    padding: 25px 30px 60px;
    box-sizing: border-box;

    .title-page {
      font-size: 50px;
    }

    .subtitle-page {
      margin-top: 20px;
    }

    .line-devider-horizontal {
      width: 100%;
      height: 2px;
      background-color: black;
      margin: 10px 0;
    }

    .form-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .input-form-con {
        width: 100%;
        padding-bottom: 16px;

        .label-form {
          color: #d0d0d0;
          display: inline-block;
          font-size: 16px;
          height: auto;
          line-height: 1em;
          margin-bottom: 8px;
        }

        .input-form {
          background-color: #f7f7f7;
          width: 100%;
          border-radius: 0;
          border: 0;
          font-size: 16px;
          color: #333;
          height: 45px;
          padding-inline: 20px;
          box-sizing: border-box;
          outline: none;
        }


      }

      .button-con {
        width: 100%;
        display: flex;
        justify-content: start;
        text-align: center;

        .save-button {
          background: #000;
          border-radius: 6px;
          font-size: 16px;
          height: 45px;
          width: 400px;
          color: white;

        }

        .save-button:hover {
          cursor: pointer;

        }
      }

    }

    .w-full {
      width: 100%!important;
    }

    .w-mini {
      width: 30%!important;
    }
  }
}
