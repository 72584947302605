.in-item {
    background: #fff;
    border-top: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    transition: all .5s;
    &:hover {
        box-shadow: 0 2px 30px #b5b3b3;
        z-index: 10;
        .in-action i {
            opacity: 1;
        }
    }
}
.in-item-Info {
    box-sizing: content-box;
    flex: 1;
    padding-left: 50px;
    width: 50%;
}
.in-item-deposit {
    align-items: center;
    background: red;
    color: #fff;
    display: flex;
    margin-top: 10px;
    padding: 1.2rem 1.2rem 1rem;
    position: relative;
    div {
        display: inline-block;
    }
}
.in-item-name {
    .font-size-rem(18);   color: #333333;
}
.in-item-outline {
    .padding-left-rem(10);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 60%;
    &   > div {
        .padding-right-rem(80);
    }
    > img {
        .size(140px);
    }
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.in-item-params {
    .font-size-rem(14);  font-style:italic;  color: @btn-base-color;  line-height: 22px;
}
.in-item-qty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15%;
    .in-decrease {
        border-radius: 100px;
        left: -20px;
        position: absolute;
        width: 40px;
    }
    .in-increase {
        border-radius: 100px;
        left: 60px;
        position: absolute;
        width: 40px;
    }
    > div {
        .inline-block();    position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    i {
        .centered-line(40px);    background: #eeeeee;    text-align: center;    color: #000;    .inline-block();    cursor: pointer;
    }
    input {
        .centered-line(40px);    background: #eeeeee;    text-align: center;    color: #000;    .inline-block();    cursor: pointer;
        border: none;
        font-style: italic;
        outline: none;
        width: 80px;
    }
}
.in-item-short-des p {
    .font-size-rem(14);  color: #666666;
}
.in-item-subtotal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15%;
    span {
        .font-size-rem(18);
        color: #333333;
    }
}
.in-item-title {
    .font-size-rem(50);  color: #333333;
}
.in-item-wrapper {
    order: 1;
    width: 70%;
    .in-group-title {
        background: #f7f7f7;
        color: @base-font-color;
        font-size: 14px;
        line-height: 40px;
        padding-left: 50px;
    }
    .in-groups-item {
        .margin-bottom-rem(20);
    }
    h2 {
        .font-size-rem(36);    .margin-top-rem(60);    .margin-bottom-rem(40);    color: @base-font-color;    font-weight: 400;
    }
}
.in-items-ordered {
    background: #fff;
    padding: 0 40px;
    transition: all .5s;
}
.in-items-ordered.in-ram-popup:hover {
    box-shadow: none;
}
.in-items-ordered:hover,.in-order-information:hover {
    box-shadow: 0 0 30px #b5b3b3;
}
