.in-order {
    background: #fff;
    min-height: 900px;
    order: 1;
    width: 30%;
    .in-btn-col {
        text-align: right;
        & button {
            width: 120px;
        }
    }
    .in-esttimate-shipping-content {
        text-align: right;
    }
    > h3 {
        .font-size-rem(18);    .padding-top-rem(70);    .padding-bottom-rem(30);    color: @base-font-color;    font-weight: 500;
    }
}
.in-order-history-content.in-order-view-content {
    .in-history-container .in-history-item .in-info {
        padding-left: 0;
    }
    .in-info {
        text-align: left;
    }
    .in-rma-base li {
        .font-size-rem(16);    line-height: 2;
    }
    .in-val {
        color: #999999;
    }
    table {
        table-layout: auto;
        .item td {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}
.in-order-information {
    transition: all .5s;
}
