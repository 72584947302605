.in-ads-product {
    .padding-bottom-rem(0);
    overflow: hidden;
    text-align: center;
    .in-ads-subtitle {
        .in-ads-subtitle-content {
            position: relative;
        }
        .in-ads-subtitle-content.active {
            &::after {
                background-color: @main-btn-color;
                width: 50%;
            }
            > span {
                color: @main-btn-color;
            }
        }
    }
    .in-ads-title,.in-ads-title.font-helNenu67 {
        font-family: DINPro-Regular;
        padding-top: 16px;
        .in-ads-hr {
            background-color: #e8e8e8;
            height: 4px;
            margin: 0;
            width: 100%;
        }
        > h2 {
            font-size: 1.05rem;
            height: auto;
            line-height: 1.05rem;
            margin-top: 0;
        }
    }
    .in-product {
        border-radius: unset;
        height: auto;
        &:hover {
            box-shadow: 0px 1px 18px 0px rgba(213, 213, 213, 1);
        }
        .in-ads-product-img {
            flex-direction: column;
            height: auto;
            margin-top: 0;
            min-height: auto;
            .in-flag-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 10px;
                min-height: 1.1rem;
                position: static;
                .in-new-icon {
                    background-color: #5db383;
                }
                .in-sale-icon {
                    background-color: #ffad70;
                }
                span {
                    color: #fff;
                    display: inline-block;
                    font-size: 14px;
                    height: 22px;
                    line-height: 22px;
                    margin-right: 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            a {
                display: inline-block;
                padding: 0;
                width: 100%;
            }
        }
        .in-ads-product-info {
            height: auto;
            padding: 0 17px;
            .in-ads-product-name {
                margin-top: 1.4rem;
                span {
                    display: inline-block;
                    font-size: .65rem;
                    line-height: 1em;
                }
            }
            .in-ads-product-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 1.9rem;
                margin-top: 0;
                .final {
                    margin-bottom: 0px;
                    margin-left: 0;
                    text-decoration: line-through;
                    span {
                        color: #999;
                        font-family: @font-regular;
                        font-size: 16px;
                    }
                }
                .in-price-final,.in-price-max {
                    margin-top: .25rem;
                    span {
                        color: #000;
                        display: block;
                        font-family: DINPro-Medium;
                        font-size: .75rem;
                        line-height: 1em;
                    }
                }
                .in-price-max {
                  span {
                    font-family: DINPro-Regular;
                    font-size: .65rem;
                  }
                }
                span {
                    line-height: 1em;
                    padding: 0;
                }
            }
        }
    }
    .in-swiper {
        font-family: DINPro-Regular;
        padding: 24px 0 38px;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
        padding-bottom: 20px;
        .swiper-pagination-bullet {
            background-color: #e4e4e4;
            border-radius: .25rem;
            height: 10px;
            width: 10px;
        }
        .swiper-pagination-bullet-active {
            background-color: #ff3200;
            width: 2rem;
        }
    }
    .swiper-pagination-custom,.swiper-pagination-fraction {
        bottom: 0;
    }
}
.in-ads-product-content {
    text-align: initial;
    .in-swiper {
        .padding-top-rem(25);    .padding-left-rem(0);    .padding-right-rem(0);    .padding-bottom-rem(70);
    }
    .swiper-pagination {
        .swiper-pagination-bullet {
            background: #E4E4E4;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            background: #CBCBCB;
        }
    }
    .in-ads-product-img {
        margin: 0 auto;
        height: 11.6rem !important;
        width: 11.6rem !important;
        a {
          width: auto !important;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
    }
}
.in-ads-product-info {
    height: auto;
    .in-ads-discount {
        background: #FF3200;
        border-radius: 11.5px 0px 0px 11.5px;
        bottom: 53px;
        display: flex;
        position: absolute;
        right: 1px;
        span {
            color: #fff;
            font-size: .6rem;
            font-weight: 500;
            margin: .3rem 0.25rem 0.25rem .45rem;
        }
    }
    .in-ads-product-name span {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box !important;
        height: 1.7rem !important;
        line-height: .85rem !important;
        margin-bottom: .95rem;
        overflow: hidden;
        text-overflow: initial !important;
        white-space: normal !important;
    }
}
.in-ads-product-name {
    color: #000;
    display: flex;
    font-size: 24px;
    margin-top: 16px;
    padding: 0;
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.in-ads-product-price {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-top: 0;
    .in-price-final,.in-price-max {
        color: #000;
        font-size: 30px;
        margin-top: 8px;
    }
    .in-price-max.final {
        font-size: 16px;
        margin-bottom: 4px;
        margin-left: 8px;
        text-decoration: line-through;
    }
}
