.in-help {
    .padding-bottom-rem(40);
    .padding-top-rem(40);
    background: #000;
    // border-top: 1px solid #fff;
    .in-container {
        .make-row();    display: flex;    flex-direction: row;
        display: flex;
        flex-direction: row;
        div {
            background: linear-gradient(180deg, #24FBFF, #FFFFFF, #FF4B20);
            display: inline-block;
            height: 1.15rem;
            width: .1rem;
        }
    }
    dd {
        color: #fff;
        font-size: .65rem !important;
        line-height: 24px;
        text-align: unset;
        & a {
          font-family: DINPro-Light;
            color: #fff;
        }
    }
    dt {
        align-items: center;
        display: flex;
        text-align: unset;
        span {
            .font-size-rem(28);    border-left: 0 solid #ff6b00;    .padding-left-rem(10);
            color: #ffffff;
            font-size: .9rem !important;
        }
    }
    dd {
        .centered-line(26px);
    }
    dt span {
      font-family: DINPro-Bold;
        font-weight: bold;
        border-left-color: #999;
    }
}
.in-help-about {
    border-left: 0;
    border-right: 0;
    width: auto !important;
    margin: 0 auto;
    & .text {
        border-left: 1px solid rgba(255, 255, 255, 0.5);    border-right: 1px solid rgba(255, 255, 255, 0.5);
        border: none;
        p {
            font-family: DINPro-Light;
            width: 20.5rem;
            .force-wrap();    .padding-left-rem(0);    padding-right: 10rem;
            .force-wrap();    .padding-left-rem(0);    .padding-right-rem(0);
        }
    }
}
.in-help-product {
  width: auto;
    :nth-child(2) {
      :first-child {
        font-family: DINPro-Bold;
        color: #fff;
        font-weight: bold;
        border-bottom: 1px solid;
      }
    }
}
.in-help-service-center {
    width: auto !important;
    dd {
      font-family: DINPro-Light;
    }
}
