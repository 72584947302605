.swiper-button-next.swiper-button-gray {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-image: none;
    border-radius: 0px 4px 4px 0px;
    color: #d8d8d8;
    display: none;
    font-family: "xp-iconfont" !important;
    font-size: 46px;
    font-style: normal;
    height: auto;
    line-height: 1em;
    padding: 17px 7px;
    right: 0;
    width: auto;
}
.swiper-button-next.swiper-button-gray:before {
    content: "\e6dc";
}
.swiper-button-prev.swiper-button-gray {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-image: none;
    border-radius: 4px 0px 0px 4px;
    color: #d8d8d8;
    display: none;
    font-family: "xp-iconfont" !important;
    font-size: 46px;
    font-style: normal;
    height: auto;
    left: 0;
    line-height: 1em;
    padding: 17px 7px;
    width: auto;
}
.swiper-button-prev.swiper-button-gray:before {
    content: "\e6cd";
}
.swiper-container-horizontal > .swiper-pagination-bullets {
    .swiper-pagination-bullet {
        background: #000;
        border-radius: 50%;
        height: 11px;
        opacity: 1;
        width: 11px;
    }
    .swiper-pagination-bullet-active {
        background: #ff7b1c;
    }
}
.swiper-container-horizontal.pc > .swiper-pagination-bullets {
    bottom: 40px;
    .swiper-pagination-bullet {
        background: #000;
        border-radius: 50%;
        height: 14px;
        opacity: 1;
        width: 14px;
    }
    .swiper-pagination-bullet-active {
        background: red;
        border-radius: 12px;
        height: 12px;
        transform: translateY(-1px);  width: 38px;
    }
}
.swiper-container-rtl {
    .swiper-button-next.swiper-button-gray {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        background-image: none;
        border-radius: 4px 0px 0px 4px;
        color: #d8d8d8;
        display: none;
        font-family: "xp-iconfont" !important;
        font-size: 46px;
        font-style: normal;
        height: auto;
        left: 0;
        line-height: 1em;
        padding: 17px 7px;
        width: auto;
    }
    .swiper-button-next.swiper-button-gray:before {
        content: "\e6cd";
    }
    .swiper-button-prev.swiper-button-gray {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        background-image: none;
        border-radius: 0px 4px 4px 0px;
        color: #d8d8d8;
        display: none;
        font-family: "xp-iconfont" !important;
        font-size: 46px;
        font-style: normal;
        height: auto;
        line-height: 1em;
        padding: 17px 7px;
        right: 0;
        width: auto;
    }
    .swiper-button-prev.swiper-button-gray:before {
        content: "\e6dc";
    }
}
.swiper-pagination-custom,.swiper-pagination-fraction {
    bottom: 40px;
}
.swiper-slide a {
    .inline-block();
}
