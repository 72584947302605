.in-add-address-container {
  .col td {
    .active & label {
      .font-size-rem(12);
      top: 5px;
    }

    .input-box {
      .centered-line(70px);
      position: relative;
    }

    input {
      margin-top: 30px;
    }

    label {
      bottom: 0;
      left: 0;
      line-height: normal;
      margin: auto;
      position: absolute;
      right: 0;
      top: 20px;
      transition: all .35s;
    }
  }

  .in-address {
    .padding-bottom-rem(30);
    .padding-left-rem(40);
    .padding-right-rem(40);
    .padding-top-rem(30);

    .in-account-error {
      .font-size-rem(14);
      color: red;
    }

    .in-btn {
      margin: 0;

      & a {
        width: 180px;
      }
    }
  }

  .in-btn {
    .margin-bottom-rem(30);
    text-align: center;
  }

  .in-title {
    .centered-line(60px);
    background: #000000;
    position: relative;
    text-align: center;

    .in-icon-close {
      .font-size-rem(30);
      color: #fff;
      cursor: pointer;
      font-style: normal;
      position: absolute;
      right: 30px;
      top: 0;
    }

    > h3 {
      .font-size-rem(18);
      color: #fff;
      font-weight: 700;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  td {
    border: 1px solid #e5e5e5;
    height: 70px;

    input {
      .padding-left-rem(10);
      .padding-right-rem(10);
      border: none;
      outline: none;
      width: 100%;
    }

    label {
      .font-size-rem(14);
      color: #a6a6a6;
      .padding-left-rem(10);
      .padding-right-rem(10);
    }
  }

  tr.country {
    label {
      height: auto;
      line-height: normal;
      padding: 0;
    }

    td > div {
      .padding-left-rem(10);
      .padding-right-rem(10);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .in-address {
    max-height: 60vh;
    overflow-y: auto;
    padding: 0 0 16px;
  }

  .in-address-btn {
    margin-bottom: 0;
    margin-top: 64px;

    .in-cancel-btn {
      color: #000;
      background-color: #a4a4a4;
      margin-right: 16px;
    }

    button {
      background-color: #000;
      border-radius: 4px;
      height: 58px;
      line-height: 58px;
      width: 188px;
      font-size: 17px;
    }
  }

  .in-address-select {
    bottom: 0px;
    top: auto;

    .in-swiper {
      background-color: #ffffff;
      box-shadow: 0px 1px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 4px;
    }

    .swiper-container {
      height: 320px;
      margin: 0 0 0 auto;
      width: 100%;
    }

    .swiper-slide li {
      color: #666;
      height: 45px;
      line-height: 45px;

      &.active, &:hover {
        background-color: #ffffff;
        color: #000;
      }

      span {
        font-size: 15px;
        padding: 0 1.2rem;
      }
    }
  }

  .in-form {
    padding: 86px 32px 80px;
    position: relative;
  }

  .in-input-group {
    margin-top: 16px;
    position: relative;

    .in-choose-area, .in-choose-district, .in-choose-city, .in-choose-country, .in-choose-state {
      align-items: center;
      background-color: #fff;
      border: 1px solid #a4a4a4;
      border-radius: 4px;
      color: #ccc;
      font-size: 15px;
      height: 62px;
      padding: 0 18px;
      width: 100%;

      &.active span {
        color: #333;
      }

      I {
        color: #979797;
        font-size: 28px;
      }
    }

    input {
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #a4a4a4;
      font-size: 15px;
      height: 62px;
      outline: none;
      padding: 0 18px;
      width: 100%;
    }

    input.postal_code {
      background-color: #e1e1e1;

      input.read_only {
        background-color: #e1e1e1;
        pointer-events: none;
      }

      span.is-danger {
        display: block;
        margin-top: 6px;
      }
    }

    .in-password-box {
      align-items: center;
      display: flex;

      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      a {
        // background-color: @main-btn-color;
        font-size: 17px;
        font-weight: 700;
        background-color: #ff3200;
        color: #fff;
        flex-shrink: 0;
        height: 62px;
        line-height: 62px;
        padding: 0 50px;
      }
    }

    .in-title {
      background-color: #ffffff;
      height: auto;
      line-height: 1;
      position: static;

      > h3 {
        border-bottom: 1px solid #eeeeee;
        color: #000;
        font-size: 18px;
        line-height: 1em;
        padding-bottom: 32px;
      }

      > i {
        font-size: 48px;
        position: absolute;
        right: 32px;
        top: 32px;
      }
    }

    .v--modal-box {
      top: 45px !important;
    }

    .input-box {
      textarea.input {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #a4a4a4;
        font-size: 15px;
        outline: none;
        padding: 12px 18px;
        width: 100%;
      }
    }
  }
}
