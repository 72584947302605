.in-search-container {
    .in-search-box {
        display: inline-flex;
        flex-direction: column;
        height: 90%;
        .in-ads-title {
            margin-bottom: 40px;
            margin-top: 120px;
            opacity: 1;
            transition: all .5s;
        }
        .in-search-input {
            display: inline-block;
            position: relative;
            .icon-close {
                color: #ccc;
                cursor: pointer;
                right: 0;
                top: 0;
            }
            .icon-search {
                left: 0;
                top: 0;
            }
            i {
                .centered-line(100px);        .font-size-rem(40);        width: 100px;        text-align: center;
                position: absolute;
            }
        }
        input {
            .padding-top-rem(20);      .padding-bottom-rem(20);      .padding-left-rem(100);      .padding-right-rem(100);      .font-size-rem(18);      color: #ccc;
            border: 1px solid #ccc;
            height: 100px;
            outline: none;
            width: 1000px;
        }
    }
    .in-search-logo {
        height: 10%;
        & a {
            display: inline-block;
            width: 120px;
        }
    }
    .in-search-pre-wrapper {
        left: 0;
        position: absolute;
        top: 100px;
        width: 100%;
        .in-hot-keywords {
            .padding-bottom-rem(20);
            .padding-top-rem(20);
            display: flex;
            flex-wrap: wrap;
            a {
                .font-size-rem(18);        line-height: 34px;
            }
            label {
                .font-size-rem(18);        line-height: 34px;
                width: 14%;
            }
            li a {
                .margin-left-rem(20);        .padding-left-rem(20);        .padding-right-rem(20);        border: 1px solid #e6e6e6;        border-radius: 100px;        background: #fff;        text-transform: capitalize;        color: #000;
                display: inline-block;
            }
            ul {
                width: 84%;
                & li {
                    .margin-bottom-rem(10);
                    float: left;
                }
            }
        }
        > ul {
            background: #fff;
            border-top: none;
            border: 1px solid #e6e6e6;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            & li {
                .padding-bottom-rem(40);
                .padding-left-rem(40);
                .padding-top-rem(40);
                display: flex;
                flex-direction: row;
                width: 50%;
                .in-info {
                    .padding-left-rem(20);          color: #000;
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                h6 {
                    .font-size-rem(16);          color: #000;          font-weight: 500;          .padding-bottom-rem(10);
                }
                img {
                    height: 100px;
                    width: 100px;
                }
            }
        }
    }
    .in-search-wrapper {
        height: 100%;
        text-align: center;
    }
    > .in-search-panel {
        background: #eee;
        height: 100%;
        left: 0;
        min-height: 500px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }
}
