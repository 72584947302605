.in-wishlist-container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    flex: 1;
    height: auto;
    text-align: center;
    width: 75%;
    .in-product {
        .make-column(1/3, 2%);
        .margin-bottom-rem(20);
        position: relative;
        & .icon-guanbi {
            .font-size-rem(30);      z-index: 10;      color: @base-font-color;      opacity: 0;      cursor: pointer;
            position: absolute;
            right: 5px;
            top: 5px;
        }
        &:hover .icon-guanbi {
            opacity: 1;
        }
        &:nth-child(4n) {
            margin-left: 0;
        }
    }
    > p {
        text-align: left;
    }
    .in-ads-product-img {
        flex-direction: column;
    }
    .in-product {
        float: none;
        width: calc(~"(100% - 40px) / 3");
        .in-ads-product-name {
          justify-content: center;
          span {
            font-size: .8rem;
          }
        }
    }
}
