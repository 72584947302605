
.in-information-content {
  .in-information-container.in-net-goods-container.my-card-bag{
    .subTitle {
        border-bottom: 1px solid #e3e3e2;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 10px;
        padding: 0 0 16px;
        span {
          position: relative;
        }
        span::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #333;
          bottom: -14px;
        }
    }
  }
}
.card {
    margin-bottom: 20px;
    padding: 20px 50px;
    .card-title {
        font-size: 15px;
        margin-bottom: 20px;
    }
}
.card-bag-entry {
    color: #000;
    cursor: pointer;
    font-size: 16px;
}
.card__block {
    & + & {
        border-top: 1px solid #e8e8e8;
    }
    &__title {
        font-size: 16px;
        font-weight: normal;
        font-family: DINPro-Regular;
        padding: 20px 0;
    }
}
.card__notes {
    color: #B8977F;
    font-size: 13px;
    line-height: 18px;
    margin: 0 auto;
    margin: 30px 0;
    max-width: 300px;
}
.card_heading {
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    h2 {
        border: none !important;
        padding: 0 !important;
    }
}
