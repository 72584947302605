.term-modal-container {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  opacity: 1;
  display: none;
  justify-content: center;
  align-items: center;

  .title-box {
    font-weight: 700;
    font-size: 1.3em;
    margin-top: 1.5em;
    margin-bottom: 0.6em;
  }

  .merchant-terms .content-wrapper {
    padding-top: unset;
  }

  .term-modal-box {
    border-radius: 10px;
    width: 860px;
    height: 860px;
    background-color: white;
  }
}
