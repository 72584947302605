[data-modal="addToFaceBookModal"] {
  .in-btn {
    padding: 0 10px;
  }
  .in-modal {
    padding: 96px 32px 42px;
  }
}
[data-modal="createPassword"] .in-btn-group-new {
  justify-content: center;
}
[data-modal="resetPassword"] a {
  color: #ff6b00;
}
[data-status='Pending'],[data-status='Processing'] {
  color: #f0a412;
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
a:focus {
  outline: none;
}
body {
  background: #f7f7f7;
  min-width: 1260px;
  overflow-x: auto;
  padding: 0;
}
button,input,textarea {
  font-family: RobotoRegular;
}
dd,dl,dt,h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  padding: 0;
}
div.in-attrs {
    .in-out-of-stock {
      & span {
        background: #e6e6e6;
        border: 2px solid @base-font-color;
        box-shadow: 0 0 0 2px #fff inset;
        color: @base-font-color;
      }
      &:before {
        background: @base-font-color;
        content: '';
        display: inline-block;
        height: 24px;
        transform: rotate(-45deg) translateX(13px);      transform-origin: 14px 12px;
        width: 2px;
      }
    }
    li {
      .margin-right-rem(10);    position: relative;
    }
    span {
      .inline-block();    .size(24px);    border-radius: 50%;    border: 2px solid transparent;
      .inline-block();    width: auto;    .centered-line(48px);    background: #fff;    .padding-left-rem(48);    .padding-right-rem(48);    color: #000;    border: 2px solid transparent;    border-radius: 3px;
    }
    span.active {
      border: 2px solid @btn-base-color;
      box-shadow: 0 0 0 2px #fff inset;
    }
  .in-out-of-stock:before {
    display: none;
  }
}
header {
  position: relative;
  z-index: 100;
}
html {
  color: #333333;
  font-family: DINPro-Regular;
  font-size: 20px;
  line-height: 1;
}
html.lb-disable-scrolling {
  height:100vh;
  overflow:hidden;
  position:fixed;
  width:100vw;
}
iframe#launcher {
  bottom: 5rem !important;
}
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
img:not([src]),img[src=""] {
  opacity:0;
}
img[lazy=loading] {
  height: 50px;
  width: 50px;
}
input:-moz-placeholder,input:-ms-input-placeholder,input::-moz-placeholder,input::-webkit-input-placeholder,textarea::-moz-placeholder,textarea::-ms-input-placeholder,textarea::-webkit-input-placeholder {
  color: #ccc;
}
input:-webkit-autofill,select:-webkit-autofill,textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #666666 inset;
  background-image: none;
  color: #999999;
}
input[name="reviewsImg"] {
  visibility: hidden;
}
ol,ul {
  .reset-list-style();  margin: 0;  padding: 0;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  .font-size-rem(14);
  appearance: none;
  background: #fff;
  border: none;
  cursor: pointer;
  font-family: RobotoRegular;
  width: 100%;
  &:active,&:focus {
    border: none;
    outline: 0;
  }
}
