.order-ship-info {
    .accordion {
        border: none;
        margin: 0;
    }
    .accordion-item {
        border-bottom: none;
        + .accordion-item {
            border-top: 1px solid #e0e0e0;
        }
    }
    .card {
        margin-bottom: 0;
    }
    ul {
        display: block;
    }
}
