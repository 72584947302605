.in-order-success-wrapper {
    .padding-bottom-rem(100);
    .padding-top-rem(100);
    text-align: center;
    .in-btn {
        margin: 20px 0;
        text-align: center;
        & a {
            .font-size-rem(14);      width: 200px;      .centered-line(40px);
        }
    }
    .in-order-success-note & a {
        color: @btn-base-color;
    }
    h5 {
        .font-size-rem(32);    font-weight: 400;    padding: 20px 0;
    }
    i {
        .font-size-rem(100);    color: @btn-base-color;
    }
    p {
        .centered-line(30px);
        .font-size-rem(16);
        color: @base-font-color;
        & a {
            color: #FF9012;
            cursor: pointer;
        }
    }
}
