.in-header-quick {
    align-items: center;
    background-color: #ff9017;
    background: url(../images/home-head-bg.png);
    display: flex;
    height: auto;
    .in-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    a,span {
        color: rgba(255, 255, 255, 0.8);    .font-size-rem(14);
    }
    .current-country {
        .padding-left-rem(0);    .padding-right-rem(15px);    box-sizing: content-box;    cursor: pointer;
        .padding-left-rem(8px);    .padding-right-rem(12px);    box-sizing: content-box;    cursor: pointer;
        height: 24px;
        width: 24px;
    }
    .in-container {
        align-items: center;
        height: 105px;
        justify-content: space-between;
    }
    .in-language-drop-menu {
        background-color: #fff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        left: 0;
        margin-top: 0;
        top: 38px;
        width: 100px;
        &::before {
            border-bottom-color: #fff;
            border: 8px solid transparent;
            content: " ";
            height: 0;
            left: 50%;
            line-height: 0;
            margin-left: -8px;
            position: absolute;
            top: -16px;
            width: 0;
        }
        a {
            color: #999;
            padding-left: 8px;
            text-align: left;
        }
        li {
            height: 32px;
            line-height: 32px;
        }
        li:hover a {
            color: #000;
        }
    }
    .in-logo {
        flex-grow: 0;
        flex-shrink: 0;
        height: auto;
        line-height: 1em;
        margin-right: 20px;
        margin-right: 40px;
        text-align: left;
        width: 28%;
        a {
            align-items: center;
            display: flex;
            height: 100%;
            width: 100%;
        }
    }
    .in-mini-cart {
        .padding-left-rem(0);
        position: relative;
        & .in-cart-qty {
            .font-size-rem(12);      background: #ff3200;      color: #fff;      border-radius: 15px;      .padding-left-rem(5);      .padding-right-rem(5);      .padding-top-rem(2);      .padding-bottom-rem(2);      line-height: 1em;
            position: absolute;
            right: -0.35rem;
            top: -0.15rem;
        }
        .home-cart {
            width: 24px;
        }
        i {
            .font-size-rem(42px);      color: @white-color;
        }
    }
    .in-quick-tool {
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 22px;
        margin-right: 1.5rem;
    }
    .in-quick-tool-area {
        align-items: center;
        display: flex;
        flex-grow: 1;
    }
    .in-quick-wrapper {
        flex-grow: 0;
        flex-shrink: 0;
        > ul {
            .in-switch-language {
                align-items: center;
                display: flex;
                position: relative;
                &:hover .icon-arrow-down {
                    transform: rotate(180deg);
                }
                .in-language-drop-menu {
                    border-radius: 4px;
                    box-shadow: 0px 12px 42px 0px rgba(38, 38, 38, 0.24);
                    & li a {
                        padding-left: 0;
                        text-align: center;
                    }
                }
                > span {
                    align-items: center;
                    display: flex;
                    border-left: 0;
                    margin-top: 6px;
                    padding: 0;
                }
            }
            > li {
                &:first-child > a {
                    padding-left: 0;
                }
                > a {
                    border-color: @white-color;
                    color: @white-color;
                    font-size: .65rem;
                    padding: 0 10px;
                }
                span {
                    border-color: @white-color;
                    color: @white-color;
                    font-size: .65rem;
                    padding: 0 10px;
                }
            }
            i {
                font-size: 10px;
            }
        }
    }
    .in-dropdown .in-dropdown-name {
        border: none;
        background: inherit;
    }
}
