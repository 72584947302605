.in-preOrder-content {
    .in-group-content {
        width: 48%;
        .final {
            .font-size-rem(14);
        }
        .in-price-final {
            .font-size-rem(24);
        }
        .in-price-max {
            .margin-left-rem(6);
        }
        .in-product-timer {
            background: none;
            border: none;
            & h6 {
                .font-size-rem(12);
            }
        }
    }
    .in-group-item & > a {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 360px;
        justify-content: center;
        width: 52%;
        & img {
            height: 360px;
        }
    }
    .in-tab {
        .margin-bottom-rem(30);
        display: flex;
        .active {
            color: @btn-base-color;
        }
        span {
            .padding-left-rem(30);      .padding-right-rem(30);      .font-size-rem(14);      border-right: 1px solid #eee;      cursor: pointer;
            background: #fff;
            color: #000;
            display: block;
            line-height: 36px;
        }
    }
}
