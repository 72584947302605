.in-service-list {
    background-color: #ffffff;
    margin-top: 50px;
    .in-service-item {
        align-items: center;
        color: #666666;
        display: flex;
        & a {
            color: #666666;
        }
        &:hover {
            color: #000;
            a {
                color: #000;
                font-family: @font-medium;
                font-weight: 500;
            }
            i {
                color: #000;
            }
        }
        i {
            color: #666666;
        }
    }
    a {
        .centered-line(130px);    .inline-block();
        .centered-line(90px);    .inline-block();
        font-weight: 500;
    }
    i {
        .centered-line(130px);    .inline-block();
        .centered-line(90px);    .inline-block();
        .font-size-rem(60);    margin-right: 10px;
    }
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .in-service-item i &:hover {
        color: #000;
        a,i {
            color: #000;
        }
    }
    .in-service-item:hover i {
        color: #000;
    }
}
