.in-billing-merchant-order-list {

  font-family: 'DINPro-Regular';
  width: 80%;
  background-color: white;
  padding: 1rem;
  font-size: .8rem;

  .top-header {
    display: flex;
    width: 100%;
    min-height: 5rem;
    align-items: center;
    justify-content: space-between;

  }

  .top-header .container {
    text-align: center;
    border: 2px solid lightgray;
    border-radius: 5px;
    flex: 1;
    height: 100%;
    max-width: 30rem;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }

  .top-header .container:hover {
    background-color: lightgray;
  }

  .top-header .container .title {
    font-size: 1.8rem;
  }

  /* Nav Content */

  .nav-content {
    width: 100%;
    height: 5vh;
    border: 2px solid lightgray;
    border-radius: 5px;
    border-top: 4px solid black;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

  }

  .nav-content-right {
    display: flex;
    margin-left: 1rem;
  }

  .nav-content-status {
    text-align: center;
  }

  .nav-content-status-data {
    display: flex;
    border-radius: 20px;
    margin-left: 1rem;
    cursor: pointer;
    border: 2px solid lightgray;
    width: 4.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .nav-content-status-data:hover {
    background-color: #00F661;
    border-color: #00F661;
  }

  .nav-content-active {
    background-color: #00F661;
    border-color: #00F661;
  }

  .circle-nav-content-status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: .5rem;
  }

  .circle-open {
    background-color: #666666;
  }

  .circle-unpaid {
    background-color: #eb5202;
  }

  .circle-paid {
    background-color: #ff3200;
  }

  /* Content */

  .main-content {
    margin-top: 1rem;
    height: 65vh;
    display: flex;
    justify-content: space-between;
    /*border: 2px solid lightgray;*/
    /*box-shadow: #666666;*/
  }

  .content-list {
    position: relative;
    height: 100%;
    width: 40%;
    border: 2px solid #ededed;
    border-radius: 5px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

  }

  .data-list-container {
    height: 92%;
    overflow-y: scroll;
  }


  .content-detail {
    height: 100%;
    width: 58%;
    border: 2px solid #ededed;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }

  .content-list-header {
    width: 100%;
    background-color: #ededed;
    height: 8%;
    z-index: 1;
    display: flex;
    align-items: center;
    color: #8D8D8D;
    justify-content: space-between;
  }

  .content-list-header .text {
    margin-left: 1rem;
    margin-right: 1rem;
  }


  /*.content-list div:nth-child(2) {*/
  /*    margin-top: 10%;*/
  /*}*/

  .data-list {
    height: 5rem;
    border-bottom: 2px solid #ededed;
    padding-left: 1rem;
    justify-content: space-between;
    display: flex;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .data-list-detail-container {
    display: flex;
    align-items: center;
  }

  .data-list-detail-container img {
    width: 2rem;
    height: 1rem;
    /*margin-left: 1rem;*/
    margin-right: 1rem;
  }

  .data-list-detail-account {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }


  .data-list-detail-container .text {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  .data-list-detail-container .id {
    color: #8D8D8D;
  }

  .data-list-payout {
    display: flex;
    align-items: center;
  }

  .text-container {
    border-radius: 5px;
    border: 2px solid #ededed;
    padding: .2rem .5rem;
    color: #8D8D8D;
    margin-right: 1rem;
  }

  .details {
    display: flex;
    padding: 1rem;
    border-bottom: 2px solid #ededed;
    display: flex;
    justify-content: space-between;
  }

  .down-position {
    margin-top: auto;
    border-top: 2px solid #ededed;
  }

  .details-group {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
  }

  .details-group .details {
    border-bottom: none;
    padding-right: unset;
    padding-left: unset;
  }

  .color-main {
    background-color: black;
  }

  .color-secondary {
    background-color: #ffdc73;
    border-color: #ffdc73;
  }

  .color-select {
    background-color: #00F661;
    border-color: #00F661;
  }

  .details-list {
    display: flex;
    justify-content: space-between;
  }

  .order-list .sub-total {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  .sub-total-content {
    text-align: end;
  }

  .export-btn {
    margin-top: 1rem;
    width: 10rem;
    height: 3rem;
    background-color: #00F661;
    border-radius: 5px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
  }

  .transaction-exports {

    border-radius: 5px;
    border: 2px solid #ededed;
    padding: 1rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

  }

  .transaction-exports .title {
    font-size: 1.3em;
    margin-bottom: 1rem;
  }

  .table {
    width: 100%;
    text-align: start;
    margin-bottom: 1rem;
  }

  .table th {
    text-align: start;
    height: 2rem;
  }

  .table td {
    border-bottom: 2px solid #ededed;
    height: 2rem;
  }

  .btn-download {
    height: 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    width: 5rem;
  }

  .btn-download .bg-grey {
    border-color: #ededed;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .w-md {
    width: 4rem;
  }

  .mr-1 {
    margin-right: 1rem;
  }

  .align-right {
    margin-left: auto;
  }

  .color-red {
    background-color: lightcoral;
    border-color: lightcoral;
  }

  .order-details {
    width: 50rem;
    border: 2px solid #dedede;
    padding: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .action-btn {
    cursor: pointer;
  }

  .detail-info {
    display: flex;
    justify-content: space-between;
    width: 18rem;
  }

  .table-order {
    margin-top: 1rem;
    color: #666;
    margin-bottom: 1rem;
  }


  .table-order th {
    background-color: #e8e8e8;
    padding: 1rem;
    max-width: 14rem;
  }

  .table-order td {
    /*background-color: #e8e8e8;*/
    padding: 1rem;
    max-width: 14rem;
    line-height: 1rem;
  }

  .payment-info {
    justify-content: space-between;
    width: 12rem;
    display: flex;
    gap: 1rem;
    margin-left: auto;
    margin-bottom: .4rem;
  }

  .details-info-value {
    display: flex;
    width: 10rem;
    text-align: start;
    margin-bottom: .4rem;
    line-height: 1rem;
  }

  .order-details .title {
    font-size: 1.5em;
    margin-bottom: 1rem;
  }

  .color-link {
    cursor: pointer;
    color: #1890ff;
  }

  .bg-grey {
    background-color: #ededed;
  }

  .page-sizes-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: .1rem;

    .page-sizes-content {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 4rem;

      select {
        background-color: #f7f7f7;
        padding-inline: 0.5rem 1rem;
        padding-block: 0.5rem;
        color: #333;
        font-size: 14px;
        border-radius: 6px;
        margin-right: 10px;
      }

      i.icon-arrow-down {
        position: absolute;
        right: 0.6rem;
        font-size: 24px;
        color: #ccc;
        cursor: pointer;
        pointer-events: none;
      }
    }
  }

  .export-container {
    display: flex;
    justify-content: end;
  }

  .in-btn {
    background-color: #000;
    font-size: 16px;
    height: 45px;
    color: white;
    padding-inline: 20px;
    border-radius: 45px;
    margin-bottom: 20px;
    border: none;
  }
}

