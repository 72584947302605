.deposit-icon {
    color: #fff;
    display: inline-block;
    font-size: 1.8rem;
    transform: rotate(180deg);
}
.deposit-page-content {
    .deposit-countdown {
        color: #666;
        display: inline-block;
        font-size: 1.4rem;
        left: 0;
        margin-top: 1rem;
        position: absolute;
        sup {
            font-size: 0.4rem;
        }
    }
    .in-info span.price {
        color: red;
    }
    .in-order-info {
        position: relative;
        .in-history-action .in-btn.unavaliable a {
            background: #ccc;
            border:1px solid #666;
            color: #666;
        }
    }
}
.deposit-count-down {
    font-weight: bold;
    position: absolute;
    right: 1rem;
    span {
        font-size: 1.6rem;
    }
    sup {
        font-size: 40%;
        top: -1rem;
    }
}
