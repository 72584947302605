.account-address-index {
    .in-add-address-container .in-address-btn button {
        width: 400px;
    }
    .in-address-container {
        .in-address {
            border-radius: 4px;
            display: flex;
            height: auto;
            justify-content: flex-start;
            padding: 24px;
            width: 100%;
            &.active {
                // background-color: #ffebdc;
                border: 2px solid #ff6b00;
                .in-choose-area .in-choose-add {
                    background: #ff6b00;
                    border: 1px solid #ff6b00;
                }
            }
            &:hover {
                box-shadow: none;
            }
            .in-address-info {
                flex-grow: 1;
                .in-address-street {
                    margin: 16px 0;
                }
                .in-name {
                    font-size: 16px;
                    padding: 0;
                }
                .in-tel {
                    font-style: normal;
                    padding: 0;
                }
                p {
                    color: #000;
                    font-size: 16px;
                    line-height: 1em;
                    .in-address-title {
                        color: #000;
                    }
                }
            }
            .in-choose-area {
                align-items: center;
                display: flex;
                flex-shrink: 0;
                padding: 0 16px !important;
                .in-choose-add {
                    background: #fff;
                    border-radius: 50%;
                    border: 1px solid #666666;
                    color: #fff;
                    height: 15px;
                    opacity: 1;
                    position: static;
                    width: 15px;
                    i {
                        font-size: 15px;
                        line-height: 13px;
                        font-weight: bold;
                        margin-right: 2px;
                        transform: rotate(6deg);
                    }
                }
            }
            .in-edit {
                align-items: center;
                display: flex;
                flex-shrink: 0;
                line-height: 1em;
                i {
                    color: #999;
                    font-size: 50px;
                }
            }
        }
        .in-address.active {
            border-color: #000;
            .in-choose-area .in-choose-add {
                background: #000;
                border-color: #000;
            }
        }
    }
    .in-addrss-page-title {
        align-items: center;
        background-color: #fff;
        display: flex;
        height: 74px;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 24px;
        width: 100%;
        h2 {
            color: #000;
            font-size: 16px;
            font-weight: normal;
        }
        i {
            color: #000;
            cursor: pointer;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
}
