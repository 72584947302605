.in-productList-container {
    text-align: center;
    .in-ads-title {
        .padding-top-rem(34);
    }
    .in-not-found-page {
        .margin-top-rem(-50);
    }
    .in-product {
        .margin-bottom-rem(20);
    }
    .in-product-list {
        .padding-left-rem(0);
        .padding-right-rem(0);
        font-size: 0;
        overflow: hidden;
        & .in-product {
            .margin-right-rem(20);
            width: calc(~"(100% - 80px) / 5");
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
        .in-product-list__content {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .in-ads-title,.in-ads-title.font-helNenu67 {
        font-family: DINPro-Regular;
        padding-top: 36px;
        .in-ads-hr {
            background-color: #e8e8e8;
            height: 4px;
            margin: 0;
            width: 100%;
        }
        > h2 {
            font-size: 1.05rem;
            height: auto;
            line-height: 1.05rem;
            margin-top: 0;
        }
    }
    .in-product {
        border-radius: unset;
        height: auto;
        &:hover {
            box-shadow: 0px 1px 18px 0px rgba(213, 213, 213, 1);
        }
        .in-ads-product-img {
            flex-direction: column;
            height: auto;
            margin-top: 0;
            min-height: auto;
            .in-flag-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 10px;
                min-height: 1.1rem;
                position: static;
                .in-new-icon {
                    background-color: #000;
                }
                .in-sale-icon {
                    background-color: #ff3200;
                }
                span {
                    color: #fff;
                    display: inline-block;
                    font-size: 14px;
                    height: 22px;
                    line-height: 22px;
                    margin-right: 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            a {
                display: inline-block;
                padding: 0;
                width: 100%;
            }
        }
        .in-ads-product-info {
            height: auto;
            padding: 0 17px;
            .in-ads-product-name {
                margin-top: 1.4rem;
                span {
                    display: inline-block;
                    font-size: .9rem;
                    line-height: 1em;
                    margin: 0 auto 0;
                }
            }
            .in-ads-product-price {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 1.9rem;
                margin-top: 0;
                padding-top: 20px;
                .final {
                    margin-bottom: 0px;
                    margin-left: 0;
                    text-decoration: line-through;
                    span {
                        color: #999;
                        font-family: @font-regular;
                        font-size: 16px;
                    }
                }
                .in-price-final,.in-price-max {
                    margin-top: .25rem;
                    span {
                        display: block;
                        font-family: @font-medium;
                        font-size: 20px;
                        line-height: 1em;
                    }
                }
                span {
                    line-height: 1em;
                    padding: 0;
                }
                img {
                  position: absolute;
                  width: 50%;
                  top: 0;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                }
            }
        }
    }
    .in-sort-title {
        .font-size-rem(24);    text-transform: uppercase;    text-align: left;    margin-bottom: 8px;
        color: #cccccc;
    }
}
