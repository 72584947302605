.in-rating-wrapper {
    .padding-bottom-rem(30);
    .padding-top-rem(10);
    border-top: 1px solid #e6e6e6;
    .in-btn-col {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 800px;
        & a[name="recover"] {
            background: none;
            border: 1px solid @base-font-color;
            color: @base-font-color;
        }
    }
    .in-input-form {
        display: flex;
        flex-direction: column;
        .in-form-control {
            .padding-left-rem(10);      .padding-right-rem(10);      border-radius: 5px;
            background: #f7f7f7;
            border: 1px solid #ebebeb;
            outline: none;
        }
        .in-text {
            height: 36px;
            width: 500px;
        }
        > h5 {
            .font-size-rem(24);      .padding-top-rem(20);      .padding-bottom-rem(20);
            margin-left: 110px;
        }
        > ul > li {
            .padding-bottom-rem(15);
            .padding-top-rem(15);
            align-items: center;
            display: flex;
            flex-direction: row;
            .help {
                .padding-left-rem(20);
            }
            > div {
                align-items: center;
                display: flex;
                flex-direction: row;
                ul {
                  display: flex;
                }
            }
        }
    }
    .in-rating & li {
        display: inline-block;
        margin-left: -10px;
        .active {
            color: #feb31b;
        }
        i {
            .font-size-rem(40);        cursor: pointer;        color: @base-font-color;
        }
    }
    .in-reviews-text {
        width: 800px;
    }
    .in-reviews-text-label {
        align-self: flex-start;
    }
    label {
        .font-size-rem(18);
        color: #000;
        width: 120px;
        & b {
            color: red;
        }
    }
    .in-container {
        margin: 0 auto;
    }
}
