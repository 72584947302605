.btn--primary {
    background: #000;
    border-radius: 3px;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    padding: 10px 20px;
}
.btn-box button {
    background: #ff3200;
    border-radius: 6px;
    text-transform: uppercase;
}
.btn-center {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: 80%;
    button {
        width: 210px;
    }
    .in-btn {
      button {
        width: 10.5rem !important;
      }
    }
}
.btn-disabled {
    background: #777 !important;
    cursor: no-drop !important;
}
.btn-group-left {
    display: flex !important;
    flex-direction: row !important;
    width: auto !important;
    .in-btn + .in-btn {
        margin-left: 30px;
    }
}
.btn-reset button {
    border-radius: 6px;
    font-size: 14px;
    height: auto !important;
    line-height: 1 !important;
    padding: 10px 50px;
    width: auto !important;
}
.btn-small {
    background-color: #ff6b00;
    border-radius: 24px;
    border: 0;
    color: #fff;
    height: 45px;
    padding: 0 24px;
}
.in-btn {
    outline: none;
    a,button {
        .inline-block();  color: #fff;  background: @btn-base-color;  width: 310px;  border-radius: 100px;  text-align: center;  .centered-line(48px);  border: none;  outline: none;
        background: #000;
    }
}
.in-btn-col {
    .padding-bottom-rem(20);
    .padding-top-rem(20);
    a,button {
        .centered-line(40px);    color: #fff;    .font-size-rem(14);    border-radius: 100px;    outline: none;    text-align: center;    cursor: pointer;
        background: @btn-base-color;
        border: none;
        display: inline-block;
        width: 180px;
    }
    button {
        background: #000;
    }
}
.in-btn-col.btn-xl button {
    background-color: #000;
    border-radius: 6px;
    font-size: 28px;
    height: 58px;
    width: 300px;
}
.in-btn-group {
    .centered-line(36px);
    .inline-block();
    background: #ff3200;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    width: 140px;
    i {
        .font-size-rem(14);    vertical-align: middle;
        color: #fff;
    }
    span {
        .font-size-rem(14);    vertical-align: middle;
        color: #fff;
        font-weight: 200;
    }
}
.in-btn-group-new {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & .in-btn {
        .margin-left-rem(20);
        .margin-right-rem(20);
        & a {
            .font-size-rem(14);      width: auto;      .centered-line(40px);      .padding-left-rem(40);      .padding-right-rem(40);
        }
    }
}
.in-btn-register {
    .centered-line(60px);  border-radius: 50px;  .margin-top-rem(30);  .margin-bottom-rem(20);  outline: none;  .font-size-rem(20);
    background: #000;
    border: none;
    color: #fff;
    display: block;
    width: 100%;
}
.in-btn.in-address-btn button {
    .font-size-rem(28px);
}
.in-btn.small button {
    height: auto;
    line-height: 1.2;
    padding: 10px 20px;
    width: auto;
}
