div.login-gateway
{
    padding: 50px;
    max-width: 400px;
    margin: 0 auto;
    > img {
        display: block;
        width: 50px;
        margin: 100px auto;
    }
    div.login-error {
        p {
            font-weight: bold;
            color: red;
        }
        a.error-button {
            background: #000;
            color: white;
            font-weight: 900;
            display: block;
            padding: 20px;
            margin: 0 auto;
            text-align: center;
            margin-top: 30px;
        }
    }
}
