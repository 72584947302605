.lightbox {
    font-weight: 400;
    left: 0;
    line-height: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 10000;
    .lb-image {
        border-radius:3px;
        border:4px solid #fff;
        display:block;
        height:auto;
        max-height:none;
        max-width:inherit;
    }
    a img {
        border:none;
    }
}
.lightboxOverlay {
    background-color:#000;
    filter:alpha(Opacity=80);opacity:.8;display:none;
    left:0;
    position:absolute;
    top:0;
    z-index:9999;
}
