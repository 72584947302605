.in-address-content,.in-order-history-content,.in-wishlist-content,.in-order-view-content {
    .padding-bottom-rem(140);
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 3rem;
    .in-dashboard-container {
        flex-grow: 1;
    }
    .in-menus-container {
        flex-shrink: 0;
        margin-right: 20px;
        padding-right: 0;
        padding-top: 302px;
        width: 360px;
        .active, li:hover {
            background-color: #000;
            span {
              background: #000;
              color: #fff;
            }
        }
        > ul {
            background-color: #fff;
        }
        li {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            height: 60px;
            padding: 0 5rem;
            position: relative;
            &:last-child {
                border-bottom: 0;
            }
            .in-triangle-right {
                display: none;
            }
            i {
                color: #666;
                font-size: 42px;
                margin-right: 10px;
            }
            span {
                color: #5d5e5e;
                font-size: 16px;
            }
        }
    }
}
.in-netGoods-form,.in-register-form {
    .padding-left-rem(50);
    .padding-right-rem(50);
    bottom: 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    .title {
        .margin-bottom-rem(20);
        text-align: left;
    }
    h2 {
        .font-size-rem(36);
        color: #fff;
    }
    h2:first-child {
        .font-size-rem(48);
    }
    .in-to-login {
        align-items: center;
        display: flex;
        flex-direction: column;
        > a {
            line-height: 25px;
        }
        a {
            color: @btn-base-color;
        }
        div {
            display: flex;
            & a {
                .padding-left-rem(5);
            }
        }
        p {
            .padding-left-rem(0);    .padding-right-rem(0);
        }
    }
}
.in-no-login,.in-no-review-list {
    .padding-bottom-rem(50);
    text-align: center;
    a {
        .font-size-rem(14);
        color: @btn-base-color;
    }
    span {
        .font-size-rem(14);
    }
}
