.in-empty-cart {
    .padding-bottom-rem(120);
    .padding-top-rem(20);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -20px;
    & > .in-btn & > a {
        .centered-line(40px);      .font-size-rem(14);
        width: 180px;
    }
    .in-btn a {
        background-color: #000;
    }
}
