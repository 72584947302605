.in-product-reviews-wrapper {
    .padding-left-rem(20);
    .padding-right-rem(20);
    .padding-top-rem(20);
    .in-no-reviews {
        .padding-bottom-rem(50);
    }
    .in-review-title {
        .font-size-rem(21);    .padding-top-rem(20);    .padding-bottom-rem(100);    text-align: center;    font-weight: 400;
        font-family: DINPro-Regular;
    }
    .in-reviews {
        .padding-left-rem(20);
        .padding-right-rem(20);
        width: 100%;
        .in-rating {
            .margin-top-rem(-10px);
            .active {
                color: #feb31b;
            }
            li i {
                .font-size-rem(28);
                color: @base-font-color;
            }
            li:first-child {
                .margin-left-rem(-4px);
            }
            ul {
                display: flex;
                flex-direction: row;
            }
        }
        .in-reviews-left {
            float: left;
            width: 20%;
            h5 {
                .font-size-rem(14);        line-height: 16px;
                line-height: 32px;
            }
            p {
                .font-size-rem(14);        line-height: 16px;
                color: @base-font-color;
            }
            span {
                .font-size-rem(14);        line-height: 16px;
                color: @base-font-color;
                vertical-align: top;
            }
        }
        .in-reviews-right {
            float: left;
            width: 80%;
            h5 {
                .font-size-rem(14);        line-height: 16px;
                line-height: 32px;
            }
            p {
                .font-size-rem(14);        line-height: 16px;
                color: @base-font-color;
            }
            span {
                .font-size-rem(14);        line-height: 16px;
                color: @base-font-color;
                vertical-align: top;
            }
        }
        .previewImg {
            .padding-top-rem(20);
            & a {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
        > div {
            .padding-top-rem(40);      .padding-bottom-rem(20);      .clearfix();
            border-bottom: 1px solid #eee;
        }
    }
    .in-reviews-list {
        .margin-top-rem(0);    .margin-bottom-rem(108);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    > .in-add-rating {
        background: #fff;
    }
    .in-reviews .previewImg {
        flex-wrap: wrap;
        padding-top: 0;
        & > li {
            margin-top: 1rem;
        }
    }
}
