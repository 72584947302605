.in-header-menu {
    .box-shadow(0 2px 10px rgba(181, 179, 179, 0.8));
    background: #fff;
    min-width: 1218px;
    & > .in-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .in-container {
        .clearfix();
    }
    .in-menu {
        .in-submenu {
            background: #fff;
            box-shadow: unset;
            display: none;
            font-weight: 300;
            height: auto;
            // left: 15px;
            min-width: 100%;
            position: absolute;
            top: 100%;
            // transform: translate(-50%, 0);
            > li {
                box-sizing: border-box;
                padding: 0;
                &:hover {
                    background: #000;
                    a {
                        color: #fff;
                    }
                }
                a {
                  font-family: DINPro-Light;
                    .font-size-rem(22px);          .centered-line(40px);          transition: all 0.25s ease;          white-space: nowrap;          border: 0;
                    color: #999999;
                    display: block;
                    padding: 0 40px 0 8px;
                    text-align: left;
                }
            }
            li + li {
                border-top: 1px solid #eee;
            }
        }
        a {
            border-bottom: 2px solid #ffffff;
            color: #000;
            font-size: 51px;
            height: auto;
            line-height: 28px;
            padding: 0;
            text-align: center;
            &:hover {
              border-bottom: 2px solid #000;
              color: #000;
              font-weight: bold;
            }
            // &::before {
            //   display: block;
            //   content: attr(title);
            //   font-weight: bold;
            //   height: 0;
            //   overflow: hidden;
            //   visibility: hidden;
            // }
        }

    }
    .in-menu-wrapper {
        height: auto;
        line-height: 22px;
        a {
            font-size: .8rem !important;
        }
        ul {
            align-items: stretch;
            height: 100%;
            li {
                padding: 0px 18px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                a {
                  font-family: DINPro-Light;
                  height: 100%;
                  line-height: 2rem;
                }
            }
        }
    }
}
