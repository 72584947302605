.gallery {
    background: #f3f3f3;
    height: 108px;
    overflow: hidden;
    position: relative;
    width: 108px;
    &__container {
        display: flex;
        .upload__btn {
            margin: 0;
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        margin: 5px 0;
    }
    &__item {
        margin-right: 10px;
        width: 108px;
    }
    .xp-icon-close {
        color: #000;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
    }
    img {
        left: 0;
        position: absolute;
        top: 0;
    }
}
