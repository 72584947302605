.merchant-questionnaire {
  .content-wrapper {
    padding-top: 288px;
    padding-left: 108px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .pre-action-text {
      color: #737373;
      font-size: 20px;
    }

    .title {
      font-size: 32px;
      margin-block: 14px 24px;
    }

    .form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .input-control {
        width: 100%;
        max-width: 512px;
        padding: 13px 16px;
        font-size: 15px;
        border-radius: 4px;
        border: 1px solid black;
      }

      label {
        font-size: 15px;
        line-height: 30px;
      }

      select, .btn {
        cursor: pointer;
      }

      select {
        background: white;
      }

      .btn, .btn-disable {
        background: black;
        color: white;
        width: 100%;
        max-width: 512px;
        border: none;
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 16px;
        line-height: 1.8;
        cursor: pointer;
      }

      .btn-disable {
        background-color: #6B6B6B;
        cursor: not-allowed;
      }

      .input-select {
        display: flex;
        position: relative;
        align-items: center;
      }

      i.icon-arrow-down {
        position: relative;
        left: -36px;
        font-size: 26px;
        color: #ccc;
        cursor: pointer;
        pointer-events: none;
      }
    }
  }

  @media screen and (max-width: 720px) {
    .content-wrapper {
      padding-block: 32px;
      padding-inline: 24px;
    }
  }
}
