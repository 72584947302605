.in-index-content {
    .padding-bottom-rem(140);
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 3rem;
    .in-service {
        .make-column(1/4, 1%);
        background: #fff;
        cursor: pointer;
        text-align: center;
        transition: all .5s;
        > div {
            display: flex;
            flex-direction: column;
            height: 260px;
            align-items: center;
            justify-content: center;
        }
        img {
          width: 2rem;
        }
        span {
          font-size: .8rem;
          font-family: bold;
          margin-top: 20px;
        }
        i {
            .font-size-rem(100);      .padding-top-rem(20);      .padding-bottom-rem(20);
        }
        &:hover {
            box-shadow: 0 10px 15px #b5b3b3;
            & span {
                color: #000;
            }
        }
    }
    .in-services-list {
        .make-row();
    }
    .in-dashboard-container {
        flex-grow: 1;
    }
    .in-menus-container {
        flex-shrink: 0;
        margin-right: 20px;
        padding-right: 0;
        padding-top: 302px;
        width: 360px;
        .active,li:hover {
          background-color: #000;
            span{
                background-color: #000;
                color: #fff;
            }
        }
        > ul {
            background-color: #fff;
        }
        li {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            height: 60px;
            padding: 0 5rem;
            position: relative;
            &:last-child {
                border-bottom: 0;
            }
            .in-triangle-right {
                display: none;
            }
            i {
                color: #666;
                font-size: 42px;
                margin-right: 10px;
            }
            span {
                color: #666;
                font-size: 16px;
            }
        }
    }
}
