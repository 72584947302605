.merchant-register-success {
  .content-wrapper {
    padding-top: 272px;
    padding-left: 128px;
    width: 100%;
    display: flex;
    flex-direction: column;

    //.icon {
    //  width: 64px;
    //  height: 64px;
    //  background-image: url("../images/check-circle.svg");
    //  background-repeat: no-repeat;
    //  background-size: contain;
    //  background-position: center;
    //}

    .icon.check-circle {
      width: 64px;
      height: 64px;
      background-image: url("../images/check-circle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .icon.reject-icon {
      width: 64px;
      height: 64px;
      background-image: url("../images/cross.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }


    .pre-action-text {
      color: #737373;
      font-size: 20px;
      margin-block: 8px 16px;
    }

    .title {
      font-size: 32px;
      line-height: 1;
    }

    .paragraph {
      font-size: 15px;
      margin-block: 24px;
      line-height: 2;
      max-width: 432px;
    }
    .reason {
      font-size: 15px;
      margin-block: 24px;
      line-height: 2;
      max-width: 432px;
    }

    //.reason-con {
    //  display: flex;
    //  justify-content: start;
    //  align-items: center;
    //  font-size: 15px;
    //  font-weight: bold;
    //  margin-block: 24px;
    //  line-height: 2;
    //  max-width: 432px;
    //
    //}

    .btn {
      background: black;
      color: white;
      width: 100%;
      max-width: 432px;
      border: none;
      padding: 8px 16px;
      border-radius: 6px;
      font-size: 16px;
      line-height: 1.8;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 720px) {
    .content-wrapper {
      padding-block: 32px;
      padding-inline: 24px;
    }
  }
}
