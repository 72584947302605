.in-static-page-content {
    .padding-bottom-rem(50);
    .padding-top-rem(20);
    .staticBox {
        .padding-left-rem(40);
        float: left;
        width: 80%;
    }
    .staticCon {
        .clearfix();
    }
    .staticTop {
        background: #fff;
        border: 1px solid #e6e6e6;
        float: left;
        vertical-align: middle;
        width: 20%;
        .active {
            border-left: 5px solid @btn-base-color;
        }
        li {
            .centered-line(70px);
            border-bottom: 1px solid #e6e6e6;
            & a {
                .font-size-rem(20);        display: block;        .padding-left-rem(40);
            }
        }
    }
    .stc-item {
        background: #fff;
        border: 1px solid #e6e6e6;
        padding: 2.5rem;
        color: #666666;
        .itemTxt,p {
            .font-size-rem(16);      line-height: 26px;
            color: #666666;
        }
        .title {
            .font-size-rem(18);      .padding-top-rem(20);      .padding-bottom-rem(20);      color: #333333;
        }
    }
    a,p,span {
        color: #333333;
    }
    .staticTop li {
        height: auto;
    }
}
.in-static-page-content.pc {
    padding: 0;
    .staticTop {
        -webkit-box-shadow: 0 3px 5px rgba(0,0,0,.24);  box-shadow: 0 3px 5px rgba(0,0,0,.24);  position: inherit;  z-index: 6;  top: 4.5rem;  border: none;
        background: #fff;
        height: 3.6em;
        line-height: 3.6em;
        width: 100%;
        .active {
            border-left: none;
        }
        li {
            border: none;
            display: inline-block;
        }
        ul {
            margin: 0;
            overflow: hidden;
            padding: 0;
            white-space: nowrap;
            li::after {
                width: 0;
            }
        }
    }
}
