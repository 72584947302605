.account-login {
    background-color: #fff;
    overflow: hidden;
    padding-top: 0;
    & .main-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-between;
        width: 100%;
    }
    .in-account-login-container {
        background-color: #ffffff;
        border-radius: 8px;
        height: auto;
        width: 378px;
        .in-form-group {
          position: relative;
          img:nth-child(2) {
            position: absolute;
            left: 16px;
            top: 13px;
          }
        }
        .in-form-group:first-child {
          img:nth-child(2) {
            top: 15px;
          }
        }
    }
    .in-account-register-container {
        background-color: #f7f7f7;
        border-radius: 8px;
        height: auto;
        padding: 32px 0;
        width: 100%;
        .in-register-form-title {
          margin-bottom: 28px;
          span {
            font-size: 18px;
          }
        }
    }
    .in-brand-area {
        align-items: center;
        background-color: #000;
        display: flex;
        flex-shrink: 0;
        height: 110px;
        img {
            width: 240px;
        }
    }
    .in-form-group {
        margin: 0 auto 16px;
        width: 66%;
        .in-btn-register {
            border-radius: 4px;
            font-size: 18px;
            height: 45px;
            line-height: 1em;
            margin-bottom: 0;
            margin-top: 16px;
            padding: 17px 0;
        }
        .in-form-control {
            background-color: #ffffff;
            border-radius: 4px;
            border: 1px solid #e8e8e8;
            color: #333333;
            font-size: 16px;
            height: 45px;
            padding: 0 38px;
        }
        .in-msg {
            color: #ff3600;
            margin-top: 2px;
            font-size: 16px;
        }
        .input-placeholder {
            position: relative;
            & .in-form-control {
                align-items: center;
                display: flex;
                justify-content: space-between;
                .icon-arrow-down {
                    color: #979797;
                    font-size: 28px;
                    line-height: 23px;
                }
            }
        }
    }
    .in-login-content {
        background: url(../images/xpark.png);    background-position: center;    background-repeat: no-repeat;    background-size: cover;
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        position: relative;
    }
    .in-login-form-area .in-form-group .in-form-control.is-danger {
        border: 1px solid @main-btn-color;
    }
    .in-login-form-area {
      .in-form-group {
        width: 100%;
      }
    }
    .in-login-welcome {
      padding-bottom: 30px !important;
      .login-welcome-panel {
          border-bottom: 1px solid #ccc;
          h3 {
            text-align: left;
            margin-bottom: 30px;
            font-weight: bold;
          }
          padding: 10px 10px 30px;
      
      }
      .login-welcome-others {
          margin-top: 30px;
          font-size: 0.8rem;
          cursor: pointer;
          span.go {
              display: inline-block;
              border: 1px solid #000;
              border-bottom-color: rgb(0, 0, 0);
              border-bottom-style: solid;
              border-bottom-width: 1px;
              border-left-color: rgb(0, 0, 0);
              border-left-style: solid;
              border-left-width: 1px;
              width: 6px;
              height: 6px;
              position: relative;
              top: 0;
              rotate: 45deg;
              border-bottom: none;
              border-left: none;
              left: 10px;
              top: -1px;
          }
      }
    }
    .in-login-register {
        align-items: center;
        background: transparent;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 46%;
        .in-register-form {
            position: static;
        }
    }
    .in-register-content {
        background-color: #f7f7f7;
        flex-grow: 1;
        overflow-y: auto;
    }
    .in-register-form {
        padding: 32px 30px 64px;
        width: 100%;
        .title {
            display: flex;
            margin-bottom: 24px;
            padding: 17px 0;
            > h2 {
                color: #666666;
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                width: 50%;
                &.active {
                    // color: @main-btn-color;
                    color: #000;
                }
                &:first-child {
                    position: relative;
                    margin-left: 60px;
                    &::before {
                        background-color: #dddddd;
                        content: " ";
                        display: inline-block;
                        height: 16px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 1px;
                    }
                }
                &:last-child {
                  margin-right: 60px;
                }
                span {
                    cursor: pointer;
                }
            }
        }
    }
    .in-register-form-area {
        background-color: #fff;
        padding: 48px 32px 64px;
        position: relative;
        width: 1320px;
        .in-btn-register {
            border-radius: 4px;
            height: 60px;
            margin: 32px auto 0;
            padding: 15px 0;
            width: 100%;
        }
        .in-btn-register-area {
            margin-bottom: 0;
        }
        .in-form {
            margin-top: 46px;
            margin: 0 auto;
            width: 710px;
        }
        .in-input-box {
            align-items: center;
            border-radius: 4px;
            border: 1px solid #e8e8e8;
            display: flex;
            padding: 0 20px;
            &.is-danger {
                border: 1px solid #ff3600;
            }
            .in-form-control {
                background-color: transparent;
                border: 0;
            }
            .in-input-label {
                border-right: 1px solid #ddd;
                color: #000;
                flex-shrink: 0;
                min-width: 111px;
                padding-right: 20px;
                font-size: 16px;
            }
        }
        .in-to-login-btn {
            color: #5d5e5e;
            font-size: 18px;
            border-right: 1px solid #5d5e5e;
            padding: 0 20px;
            margin-right: 20px;
        }
        .issubscrib {
            margin-bottom: 0;
            .in-checkbox-input {
                align-items: center;
                border: 1px solid #e8e8e8;
                display: inline-flex;
                height: 20px;
                justify-content: center;
                line-height: 20px;
                margin-right: 10px;
                width: 20px;
                border-radius: 50%;
                i {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            input {
                display: none;
                &:checked + .in-checkbox-input {
                    background-color: @main-btn-color;
                    border: 1px solid @main-btn-color;
                }
            }
            input:checked + .in-checkbox-input {
                background: #000;
                border-color: #000;
            }
            label {
                color: #5d5e5e;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                font-size: 14px;
                padding-right: 0;
            }
        }
        > h2 {
            color: #000;
            font-size: 18px;
        }
    }
    .in-to-login {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 16px 0 0;
        a {
            color: #999999;
            font-size: 16px;
        }
    }
    .main-container {
        flex-direction: column;
    }
}
