.in-reset-password.page-container {
    padding-top: 0;
    .in-reset-password-content {
        flex-direction: column;
        margin-bottom: 0;
    }
    .in-reset-password-form {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        margin: 32px auto 0;
        padding: 48px 0 64px;
        width: 1320px;
        .in-btn-col {
            margin-top: 8px;
            padding: 0;
            button {
                font-size: 18px;
                height: 47px;
                width: 100%;
                border-radius: 4px;
            }
        }
        .in-input-col {
            padding-bottom: 0;
            .in-input-control {
                display: flex;
                justify-content: center;
                margin: 0 0 25px;
            }
            input {
                border: 1px solid #e8e8e8;
                height: 48px;
                padding: 0 20px;
                width: 100%;
                border-radius: 4px;
                font-size: 16px;
                &.is-danger {
                    color: #333333;
                }
            }
            span.is-danger {
                color: #000;
                display: inline-block;
                font-size: 18px;
                height: auto;
                line-height: 1em;
                margin-top: 10px;
                padding-left: 0;
            }
        }
        .reset-password-help {
            color: #5d5e5e;
            font-size: 16px;
            .reset-password-phone {
                color: @main-btn-color;
            }
        }
        .reset-password-msg {
            color: #000;
            font-size: 16px;
            margin-bottom: 24px;
            padding: 0;
        }
        .reset-password-title {
            color: #333333;
            font-size: 18px;
            margin-bottom: 21px;
        }
        form {
            padding: 0;
            max-width: 36%;
        }
    }
}
.in-reset-password .in-brand-area {
    align-items: center;
    background-color: #000;
    display: flex;
    flex-shrink: 0;
    height: 110px;
    img {
        width: 240px;
    }
}
.in-reset-password-content {
    .margin-bottom-rem(160);
    display: flex;
    justify-content: center;
    .in-btn-col {
        .padding-top-rem(35);    .padding-bottom-rem(55);
    }
    .in-input-col .in-input-control input {
        width: 500px;
    }
    .in-input-control {
        display: flex;
        flex-direction: column !important;
        & .help {
            text-align: left;
        }
    }
    form {
        .padding-left-rem(155);    .padding-right-rem(155);    text-align: center;
        background: #fff;
        border-radius: 5px;
        display: inline-block;
        > p {
            .font-size-rem(18);    .padding-top-rem(50);    .padding-bottom-rem(30);
        }
    }
    .successButton {
        color: #fff !important;
    }
    .v--modal {
      text-align: center;
      a {
        color: @btn-base-color;
      }
      img {
        width: 5rem;
      }
    }

}
