.checkbox {
    cursor: pointer;
    .in-checkbox-input {
        align-items: center;
        background-color: #f3f3f3;
        border-radius: 2px;
        color: #f3f3f3;
        display: inline-flex;
        height: 22px;
        justify-content: center;
        width: 22px;
        i {
            font-size: 22px;
        }
    }
    input {
        display: none;
    }
    input:checked & + .in-checkbox-input {
        background-color: #ff6b00;
    }
}
.checkbox--small {
    .checkbox__input {
        height: 16px;
        line-height: 16px;
        width: 16px;
        .xp-iconfont {
            font-size: 16px;
        }
    }
    .checkbox__value {
        font-size: 14px;
    }
}
.checkbox-group {
    margin: 20px 0;
    overflow: hidden;
}
.checkbox__content {
    display: flex;
    flex-wrap: wrap;
    .checkbox__item {
        margin: 10px 0;
        width: 25%;
    }
}
.checkbox__el {
    cursor: pointer;
    .checkbox__input {
        background: #f3f3f3;
        border-radius: 2px;
        display: inline-block;
        height: 22px;
        line-height: 22px;
        margin-right: 8px;
        text-align: center;
        width: 22px;
        .xp-iconfont {
            color: #f3f3f3;
            font-size: 22px;
        }
    }
    .checkbox__value {
        color: #333;
        font-size: 20px;
    }
    [type="checkbox"] {
        display: none;
    }
    [type="checkbox"]:checked + .checkbox__input {
        // background: #ff6b00;
        background: #000;
    }
}
.checkbox__head,.config-image-head {
    margin: 10px 0;
}
.checkout-points .points__item {
    cursor: pointer;
    overflow: initial;
}
.checkout-success .in-order-success-wrapper {
    padding: 100px 0;
    img {
      width: 3rem;
    }
    h5 {
      font-weight: bold;
      padding: 20px 0 0;
    }
    p {
      color: #000;
      font-size: .6rem;
    }
    .cursor-default {
        cursor: default;
    }
    .in-btn a {
      font-size: .9rem;
      border-radius: 4px;
    }
}
