.icon-facebook {
    vertical-align: middle;
}
.icon-star:before,.icon-success:before,.icon-tishi1:before,.xp-icon-account-edit:before,.xp-icon-comment-rating:before,.xp-icon-search:before,.xp-icon-success:before {
    color: #000;
}
.icon-xclub {
    background-image: url("../images/xclub_icon.png");
}
.iconfont.icon-error,.iconfont.icon-success,.iconfont.icon-tishi1 {
    color: #000 !important;
}

.in-icon-empty-cart {
    background-size: cover;
    background: url('../images/emptyCart.png') no-repeat center;
    display: block;
    height: 120px;
    width: 120px;
    margin-right: 15px;
}
.in-icon-empty-results {
    background-size: cover;
    background: url('../images/noResults.svg') no-repeat center;
    display: block;
    height: 300px;
    width: 300px;
}
.in-icon-logout {
    background-size: cover;
    background: url('../images/logout.svg') no-repeat center;
    display: block;
    height: 300px;
    width: 300px;
}
.in-icon-notFoundPage {
    background-size: cover;
    background: url('../images/404.svg') no-repeat center;
    display: block;
    height: 300px;
    width: 300px;
}
