.in-blog-view-container {
    .margin-bottom-rem(30);
    .margin-top-rem(30);
    .padding-bottom-rem(60);
    .padding-left-rem(150);
    .padding-right-rem(150);
    .padding-top-rem(60);
    background: #fff;
    .in-blog-content {
        .padding-top-rem(40);    .padding-bottom-rem(40);
    }
    .in-title {
        border-bottom: 1px solid #eeeeee;
        text-align: left;
        h5 {
            .font-size-rem(40);
        }
        p {
            .padding-bottom-rem(20);
            .padding-top-rem(20);
            color: @base-font-color;
            & span {
                .font-size-rem(14);
            }
        }
    }
}
