.in-ads-base-content {
    text-align: center;
    .in-ads-base-title {
        .inline-block();
        .padding-bottom-rem(20);
        .padding-top-rem(30);
        h2 {
            .font-size-rem(36);      .centered-line(45px);      .margin-top-rem(10);      .margin-bottom-rem(10);
            font-weight: 400;
            letter-spacing: 4px;
            text-transform: uppercase;
        }
        hr {
            background: #094;
            border: none;
            height: 3px;
            width: 50%;
        }
    }
    > a {
        display: block;
        > img {
          max-width: 1520px;
        }
    }
    .in-ads-base-title {
        padding: 36px 0 20px;
        > h2 {
            font-family: @font-medium;
            height: 1em;
            line-height: 1em;
            margin-top: 0;
        }
        > hr {
            background-color: #e8e8e8;
            width: 100%;
        }
    }
}
.in-ads-subtitle {
    display: flex;
    justify-content: center;
    .in-ads-subtitle-content {
        cursor: pointer;
        margin: 0 10px;
    }
}
.in-ads-subtitle-content > span {
    border-right: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8rem;
    font-size: 16px;
    height: 16px;
    line-height: 16px;
    margin-bottom: 0.5rem;
    margin-bottom: 10px;
    margin-top: 0.5rem;
    margin-top: 10px;
    padding-left: 1rem;
    padding-left: 20px;
    padding-right: 1rem;
    padding-right: 20px;
    text-transform: uppercase;
}
.in-ads-subtitle-content:after {
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto;
    width: 30%;
}
.in-ads-subtitle-content:last-child > span {
    border-right: none;
}
.in-ads-title {
    .inline-block();
    .padding-top-rem(36);
    &   > h2 {
        .font-size-rem(36px);
        .margin-bottom-rem(0);
        .margin-top-rem(0);
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 1em;
        text-transform: uppercase;
        div {
            border: 1px solid #000;
            display: inline-block;
            padding: .5rem 1.25rem;
        }
    }
    > hr {
        background: #E8E8E8;
        border: none;
        height: 4px;
        margin: 0;
        width: 100%;
    }
}
