.in-xn-login {
  height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 0 !important;

  .in-xn-login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    position: relative;

    p {
      text-align: center;
      .margin-top-rem(50);
      line-height: 28px;
    }

    .in-pwd-msg {
      width: 400px;
      height: 120px;
      color: #fff;
      font-size: 20px;
      padding: 30px;
      line-height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .head {
      width: 100%;
      height: 200px;
      background: url('../images/home-head-bg.png');
      background-size: cover;
      align-items: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .in-xn-login-form {
      width: 70%;
      padding: 15px 10%;
      padding-bottom: 50px;
      background-color: rgb(41, 39, 39);

      a {
        color: #fff;
        font-weight: bolder;
      }

      .input-pwd {
        width: 100%;
        background-color: #fff;
        color: #000;
        margin-top: 20px;
        padding: 20px;

        div {
          margin-bottom: 10px;
        }

        .input-col {
          display: flex;
          justify-content: space-between;

          input {
            width: 80%;
            padding: 5px;
            outline: none;
          }

          button {
            padding: 5px 15px;
            outline: none;
            border: none;
            border-radius: 5px;
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }

}
