.in-home-newsletter {
    position: relative;
    z-index: 99;
    background-color: #242424;
    background: url(../images/BG.png);
    height: 210px;
    .in-container {
        .padding-left-rem(0);
        align-items: unset;
        border-bottom: 1px solid #666;
        border-image: linear-gradient(270deg, #24FBFF, #FFFFFF, #FF4B20) 20 20;
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-between;
        padding-top: 2.25rem;
        #qr-code {
            margin-left: .4rem;
            vertical-align: unset;
            width: 3.6rem;
        }
        .footer-phone {
            margin-top: -4.4rem;
            width: 179px;
        }
    }
    .in-newsletter {
        border-bottom: 1px solid #fff;
        display: inline-block;
        font-size: 0;
        padding-bottom: .3rem;
        padding-top: 1.9rem;
    }
    button {
        .font-size-rem(24);    height: 56px;    border: none;    vertical-align: middle;    outline: none;    padding: 0 10px;    border-radius: 0 4px 4px 0;
        background: url(../images/btn-bg.png) !important;    color: #000;    .font-size-rem(12);    height: 1.1rem;    border: none;    border-radius: 0;    vertical-align: middle;    outline: none;    padding: 0 10px;
    }
    form     > h6,p {
        color: #fff;
    }
    h6 {
        .font-size-rem(30);
    }
    input {
        .font-size-rem(12);    padding: 0;    width: 9.8rem;    vertical-align: middle;    border-radius: 4px 0 0 4px;
        .font-size-rem(22);    .padding-rem(0);    width: 192px;    vertical-align: middle;    border-radius: 4px 0 0 4px;
        background: transparent;
        border: none;
        color: #939393;
        font-size: .7rem;
        height: 22px;
        outline: none;
    }
    .download-app {
        color: #fff;
        margin-right: -5.2rem;
        .content {
            color: #fff;
            display: inline-block;
            width: 11.55rem;
            .content-bottom {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: .9rem;
                text-align: center;
                img {
                    height: .35rem;
                    width: .15rem;
                }
                ul {
                    display: inline-block;
                    font-size: .55rem;
                }
            }
            .content-top {
                border: solid .05rem #fff;
                display: flex;
                margin-top: .5rem;
                .content-search {
                    align-items: center;
                    display: flex;
                }
                .google-play {
                    border-right: .05rem solid #fff;
                    height: 1.45rem;
                    padding: .3rem .5rem;
                    width: 5.5rem;
                }
                div {
                    img {
                        border: none;
                        width: .85rem;
                    }
                    span {
                        font-size: .7rem;
                        padding-left: .95rem;
                        padding-right: .75rem;
                    }
                }
            }
        }
        h6 {
            font-family: DINPro-Bold;
            font-size: 1.1rem;
            padding: 0 0 .5rem 0;
            font-weight: bold;
        }
        li {
            font-size: .8rem;
            list-style: disc;
            margin-left: .9rem;
            padding-bottom: .15rem;
        }
    }
    .newsletter-form {
        width: 50%;
        h6 {
            font-family: DINPro-Bold;
            font-size: 1.1rem;
            font-weight: bold;
        }
        p {
            margin-top: .65rem;
            font-size: .8rem;
        }
    }
    .newsletter-image {
        height: 100%;
        width: auto;
    }
    .newsletter-image-area {
        text-align: right;
        width: 50%;
    }
    button {
        background: #000;
    }
}
