.accordion {
    border-top: 1px solid #E0E0E0;
    margin-top: 40px;
    overflow: auto;
}
.accordion-action {
    color: #000;
    display: inline-block;
    font-size: 14px;
}
.accordion-active .accordion-header .xp-iconfont {
    transform: rotate(0);
}
.accordion-body {
    height: 0;
    overflow: hidden;
    transition: all .25s ease-in-out;
}
.accordion-content {
    overflow: hidden;
    padding-bottom: 20px;
    .in-table li {
        width: 100%;
    }
    li {
        color: #666666;
        font-size: 14px;
        padding: 10px 0;
        width: 100% / 6;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
.accordion-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    line-height: 60px;
    .xp-iconfont {
        display: inline-block;
        transform: rotate(-90deg);    color: #8C8C8C;    font-size: 12px;    transition: all .25s ease-in-out;
    }
    .expand {
      font-size: 16px;
      text-decoration: underline;
    }
}
.accordion-header-div {
    color: #333333;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
}
.accordion-item {
    border-bottom: 1px solid #E0E0E0;
}
.accordion-open .accordion-body {
    height: auto;
}
.accordion.ship-information {
    flex: 1;
}
