.in-add-cart-wrapper {
    .padding-top-rem(20);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .in-btn {
        .centered-line(60px);
        .inline-block();
        // background: @btn-base-color;
        background: #666;
        border-radius: 3px;
        text-align: center;
        width: 49%;
        .in-wishlist-red {
            color: #000;
        }
        i,span {
            .font-size-rem(18);
            color: #fff;
        }
        .icon-cart {
            .margin-right-rem(12);    .font-size-rem(20);
        }
    }
    .in-btn-wishlist {
        background: #fff;
        i,span {
            color: #000;
        }
    }
    .in-btn.outof {
        background: @base-font-color;
        color: #fff;
    }
    .in-join-now-btn {
        background: #eb6237;
    }
    .in-pre-order {
        background: #ccc;
        cursor: default;
    }
    .in-btn {
        height: 38px;
        background: #000;
        line-height: 1;
        border-radius: 4px;
        width: 32%;
        margin-left: .8rem;
        &.active {
            background: #ff3200;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
