.in-estimate-shipping {
    border-top: 1px solid #eee;
    .in-estimate {
        .margin-top-rem(20);
        border-radius: 100px;
        border: 1px solid #ccc;
        cursor: pointer;
        position: relative;
        .icon-xiala1 {
            .centered-line(40px);      .padding-right-rem(20);      .font-size-rem(20);      color: @base-font-color;
            position: absolute;
            right: 0;
            top: 0;
        }
        .in-address-select {
            left: 0;
            position: absolute;
            top: -260px;
            z-index: 3;
            .swiper-container {
                background: #eee;
                border-radius: 5px;
                box-shadow: 0 0 30px #b5b3b3;
                height: 260px;
                width: 100%;
            }
            .swiper-slide {
                height: auto;
                & li {
                    cursor: pointer;
                    height: 26px;
                    & span {
                        .font-size-rem(14);            display: inline-block;            .centered-line(26px);
                    }
                }
            }
        }
        p {
            .centered-line(40px);      text-align: left;      .font-size-rem(16px);      .padding-left-rem(20);      color: @base-font-color;
        }
    }
}
