.in-totals-wrapper {
    .padding-bottom-rem(20);
    .padding-top-rem(20);
    .padding-top-rem(20);  .padding-bottom-rem(20);  .padding-left-rem(10);  .padding-right-rem(10);  background: #f7f7f7;
    border-top: 1px solid @btn-base-color;
    .in-label {
        font-style:italic;
    }
    li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & span:last-child {
            text-align: right;
        }
    }
    span {
        .font-size-rem(16);    line-height: 32px;
        color: #333333;
        width: 50%;
    }
}
