.in-sort-by-wrapper {
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    & .in-sort-by {
        background: #fff;
        padding: 1.5rem 0;
        & > ul {
            display: flex;
            justify-content: space-evenly;
            li {
                border-right: 1px solid #eee;
            }
            li:last-child {
                border-right: 0;
            }
            span {
                .font-size-rem(16);
                .padding-left-rem(20);
                .padding-right-rem(20);
                color: #666;
                cursor: pointer;
                display: inline-block;
                &:hover {
                    color: @btn-base-color;
                }
            }
            span.active {
                color: @btn-base-color;
            }
        }
    }
}
