@media screen and (max-width: 1024px) {
  .in-time-d {
    .font-size-rem(36);
  }
  .in-time-h {
    .font-size-rem(36);
  }
  .in-time-m {
    .font-size-rem(36);
  }
  .in-time-s {
    .font-size-rem(36);
  }
  .in-timer-content {
    .in-btn-group {
      span {
        .font-size-rem(18);
      }
    }
    .in-time-split {
      .font-size-rem(28);    margin: 0 14px;
    }
  }
  .in-timer-content_title {
    .font-size-rem(24);
  }
  .in-timer-price {
    .is-special-price {
      .font-size-rem(14);
    }
    p {
      .font-size-rem(28);
    }
  }
}
@media screen and (max-width: 1024px) {
  .in-history-container {
    & .in-history-item & .in-ordered-qty {
      .padding-right-rem(10);
    }
  }
}
@media screen and (max-width: 1024px) {
  .in-timer-container {
    .in-time-d,.in-time-h,.in-time-m,.in-time-s {
      .font-size-rem(36);
    }
    .in-timer-content {
      .in-btn-group span {
        .font-size-rem(18);
      }
      .in-time-split {
        .font-size-rem(28);      margin: 0 14px;
      }
    }
    .in-timer-content_title {
      .font-size-rem(24);
    }
    .in-timer-price {
      .is-special-price {
        .font-size-rem(14);
      }
      p {
        .font-size-rem(28);
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .in-ads-product-name {
    font-size: 15px;
  }
  .in-ads-product-price {
    .in-price-final,.in-price-max {
      font-size: 20px;
    }
    .in-price-max.final {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .in-ads-product {
    .in-product .in-ads-product-info {
      .in-ads-product-name span {
        font-size: 15px;
      }
      .in-ads-product-price {
        .in-price-final,.in-price-max span {
          font-size: 20px;
        }
        .in-price-max.final span {
          font-size: 12px;
        }
      }
    }
  }
  .in-productList-container {
    .in-product .in-ads-product-info {
      .in-ads-product-name span {
        font-size: 15px;
      }
      .in-ads-product-price {
        .in-price-final,.in-price-max span {
          font-size: 20px;
        }
        .in-price-max.final span {
          font-size: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .in-home-newsletter {
    .in-container {
      padding-left: 20px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .in-productList-container {
    .in-product-list {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
@media screen and (max-width: 1300px) {
  .copyright {
    & p {
      .font-size-rem(14);
    }
  }
  .in-help {
      dd {
        .font-size-rem(14);
      }
      dt span {
        .font-size-rem(26);
      }
  }
}
@media screen and (max-width: 1300px) {
  .in-time-d {
    .font-size-rem(46);
  }
  .in-time-h {
    .font-size-rem(46);
  }
  .in-time-m {
    .font-size-rem(46);
  }
  .in-time-s {
    .font-size-rem(46);
  }
  .in-timer-content {
    .in-btn-group {
      span {
        .font-size-rem(20);
      }
    }
    .in-time-split {
      .font-size-rem(38);    margin: 0 16px;
    }
  }
  .in-timer-content_title {
    .font-size-rem(26);
  }
  .in-timer-price {
    .is-special-price {
      .font-size-rem(16);
    }
    p {
      .font-size-rem(30);
    }
  }
}
@media screen and (max-width: 1300px) {
  .in-timer-container {
    .in-time-d,.in-time-h,.in-time-m,.in-time-s {
      .font-size-rem(46);
    }
    .in-timer-content {
      .in-btn-group span {
        .font-size-rem(20);
      }
      .in-time-split {
        .font-size-rem(38);      margin: 0 16px;
      }
    }
    .in-timer-content_title {
      .font-size-rem(26);
    }
    .in-timer-price {
      .is-special-price {
        .font-size-rem(16);
      }
      p {
        .font-size-rem(30);
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .copyright {
    & p {
      .font-size-rem(14);
    }
  }
  .in-help {
      dd {
        .font-size-rem(14);
      }
      dt span {
        .font-size-rem(26);
      }
  }
}
@media screen and (max-width: 1300px) {
  .account-sales-order-view {
    .in-rma-container .in-order-status-list li {
      font-size: 16px;
      margin-right: 15px;
    }
  }
}
@media screen and (max-width: 1400px) {
  .in-product-info {
    .in-add-cart-wrapper {
      padding-right: 10px;
      .in-btn {
        i {
          font-size: 40px;
        }
        span {
          font-size: 22px;
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .in-checkout-index {
    .in-order .in-group-order .in-order-item .in-item-info h6 {
      font-size: 20px;
    }
  }
  .in-checkout-payment {
    .in-order {
      .in-group-order .in-order-item .in-item-info h6 {
        font-size: 26px;
      }
    }
    .in-payments-container .in-payment {
      .payment-icon {
        width: 140px;
      }
      .payment-info .payment-desc {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .in-item-Info {
    width: auto;
  }
  .in-product-info {
    .in-preview {
      width: 45%;
    }
  }
}
@media screen and (max-width: 1500px) {
  .copyright {
    & p {
      .font-size-rem(16);
    }
  }
  .in-help {
      dd {
        .font-size-rem(16);
      }
      dt span {
        .font-size-rem(26);
      }
  }
}
@media screen and (max-width: 1500px) {
  .xp-search-modal {
    .font-size-rem(16px);
  }
  .xp-search-product-name {
    .font-size-rem(16px);
  }
  .xp-search-product-price {
    .font-size-rem(14px);
  }
  .xp-search-product-sale {
    .font-size-rem(18px);
  }
}
@media screen and (max-width: 1500px) {
  .xp-search-item {
    .xp-search-product {
      .xp-search-product-name {
        .font-size-rem(16px);
      }
      .xp-search-product-price .price {
        .font-size-rem(14px);
      }
      .xp-search-product-sale .price {
        .font-size-rem(18px);
      }
    }
  }
  .xp-search-modal {
    .font-size-rem(16px);
  }
}
@media screen and (max-width: 1500px) {
  .in-header-quick {
    .in-logo {
      margin-right: 30px;
    }
    .in-quick-tool {
      margin-right: 50px;
    }
    .in-quick-wrapper > ul > li {
      > a {
        // font-size: 16px;
      }
      span {
        // font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .copyright {
    & p {
      .font-size-rem(16);
    }
  }
  .in-help {
      dd {
        .font-size-rem(16);
      }
      dt span {
        .font-size-rem(26);
      }
  }
}
@media screen and (max-width: 1500px) {
  .account-sales-order-view {
    table {
      .history-order-header th span {
        font-size: 20px;
      }
      .item td span {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .account-sales-order-view {
    .in-rma-container .in-order-status-list li {
      font-size: 18px;
      margin-right: 25px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .catalog-category {
    .in-productList-container .in-sort-by-wrapper .in-sort-by > ul span {
      .font-size-rem(16px);
    }
  }
}
@media screen and (max-width: 1550px) {
  .in-ads-product-name {
    font-size: 16px;
  }
  .in-ads-product-price {
    .in-price-final,.in-price-max {
      font-size: 22px;
    }
    .in-price-max.final {
      font-size: 13px;
    }
  }
}
@media screen and (max-width: 1550px) {
  .in-ads-product {
    .in-product .in-ads-product-info {
      .in-ads-product-name span {
        font-size: 16px;
      }
      .in-ads-product-price {
        .in-price-final,.in-price-max span {
          font-size: 22px;
        }
        .in-price-max.final span {
          font-size: 13px;
        }
      }
    }
  }
  .in-productList-container {
    .in-product .in-ads-product-info {
      .in-ads-product-name span {
        font-size: 16px;
      }
      .in-ads-product-price {
        .in-price-final,.in-price-max span {
          font-size: 22px;
        }
        .in-price-max.final span {
          font-size: 13px;
        }
      }
    }
  }
}
@media screen and (max-width: 1550px) {
  .cms-index {
    .in-product-reviews-wrapper {
      .in-reviews-left,.in-reviews-right {
        h5,span {
          font-size: .6rem !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .in-time-d {
    .font-size-rem(56);
  }
  .in-time-h {
    .font-size-rem(56);
  }
  .in-time-m {
    .font-size-rem(56);
  }
  .in-time-s {
    .font-size-rem(56);
  }
  .in-timer-content {
    .in-btn-group {
      span {
        .font-size-rem(22);
      }
    }
    .in-time-split {
      .font-size-rem(48);    margin: 0 18px;
    }
  }
  .in-timer-content_title {
    .font-size-rem(28);
  }
  .in-timer-price {
    .is-special-price {
      .font-size-rem(18);
    }
    p {
      .font-size-rem(32);
    }
  }
}
@media screen and (max-width: 1600px) {
  .in-timer-container {
    .in-time-d,.in-time-h,.in-time-m,.in-time-s {
      .font-size-rem(56);
    }
    .in-timer-content {
      .in-btn-group span {
        .font-size-rem(22);
      }
      .in-time-split {
        .font-size-rem(48);      margin: 0 18px;
      }
    }
    .in-timer-content_title {
      .font-size-rem(28);
    }
    .in-timer-price {
      .is-special-price {
        .font-size-rem(18);
      }
      p {
        .font-size-rem(32);
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .copyright {
    & p {
      .font-size-rem(18);
    }
  }
  .in-help {
      dd {
        .font-size-rem(18);
      }
      dt span {
        .font-size-rem(26);
      }
  }
}
@media screen and (max-width: 1700px) {
  .in-header-quick {
    .in-quick-tool {
      margin-right: 50px;
    }
  }
}
@media screen and (max-width: 1700px) {
  .copyright {
    & p {
      .font-size-rem(18);
    }
  }
  .in-help {
      dd {
        .font-size-rem(18);
      }
      dt span {
        .font-size-rem(26);
      }
  }
}
@media screen and (max-width: 1700px) {
  .in-product-info {
    .in-rating-wrapper .in-input-form {
      padding: 6px 0 30px;
      .close-rating-modal-btn {
        font-size: 40px;
        right: 20px;
        top: 20px;
      }
      .in-btn-col {
        padding-top: 0;
        a,button {
          font-size: 24px;
          height: 48px;
          line-height: 48px;
        }
      }
      .in-input-list > li:first-child {
        margin-bottom: 0;
      }
      .in-rating li i {
        font-size: 1.8rem;
      }
      .in-reviews-text {
        height: 150px;
      }
      .in-text {
        height: 48px;
      }
      .reviewsImg {
        i {
          height: 108px;
          width: 108px;
        }
        ul.previewImg li {
          height: 108px;
          width: 108px;
        }
      }
      > h5 {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
      label {
        font-size: 24px;
      }
      ul li {
        margin-top: 12px;
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .in-checkout-index {
    .in-cart-bar {
      height: 120px;
      min-width: 1280px;
      .in-btn-checkout a {
        font-size: 18px;
        height: 50px;
        border-radius: 4px;
        padding: 14px 24px;
      }
      .in-item-count {
        margin-bottom: 6px;
        font-size: 16px;
        color:#000;
        height: 18px;
      }
      .in-total {
        height: auto;
        line-height: 1;
        span {
          &:first-child {
            font-size: 16px;
            color: #000;
          }
          &:last-child {
            font-size: 22px;
            color: #ff3200;
            font-family: 'DINPro-Bold';
          }
        }
      }
    }
    > .in-container {
      margin-bottom: 8rem;
    }
  }
  .in-checkout-payment {
    .in-cart-bar {
      height: 120px;
      min-width: 1280px;
      .in-btn-checkout a {
        font-size: 18px;
        height: 50px;
        // padding: 12px 24px;
      }
      .in-item-count {
        font-size: 16px;
        height: 18px;
      }
      .in-total {
        height: auto;
        line-height: 1;
        span {
          &:first-child {
            font-size: 16px;
          }
          &:last-child {
            font-size: 22px;
          }
        }
      }
    }
    > .in-container {
      margin-bottom: 8rem;
    }
  }
  .in-shopping-cart-container {
    .in-cart-bar {
      height: 120px;
      min-width: 1280px;
      .in-btn-checkout a {
        font-size: 18px;
        border-radius: 4px;
        height: 50px;
        padding: 14px 24px;
      }
      .in-item-count {
        font-size: 16px;
        height: 18px;
      }
      .in-total {
        height: auto;
        line-height: 1;
        span {
          &:first-child {
            font-size: 16px;
          }
          &:last-child {
            font-size: 22px;
          }
        }
      }
    }
    .in-item-wrapper {
      .in-action {
        width: 100px;
      }
      .in-item-outline {
        .in-item-name {
          font-size: 16px;
        }
        .in-item-subtotal span {
          font-size: 16px;
        }
        img {
          height: 120px;
          width: 120px;
        }
      }
      .in-item-qty > div {
        i {
          font-size: 16px;
        }
        input {
          font-size: 16px;
        }
      }
    }
    > .in-container {
      margin-bottom: 8rem;
    }
  }
}
@media screen and (max-width: 1700px) {
  .in-checkout-payment {
    .in-coupon-container .in-input-container .in-submit {
      width: 260px;
    }
  }
}
@media screen and (max-width: 1700px) {
  .in-add-address-container {
    .in-address-btn {
      margin-top: 20px;
      button {
        height: 45px;
        line-height: 45px;
      }
    }
    .in-form {
      padding: 60px 120px;
    }
    .in-input-group {
      .in-choose-area,.in-choose-district,.in-choose-city,.in-choose-country,.in-choose-state,input {
        height: 45px;
      }
    }
    .in-password-box a {
      height: 45px;
      line-height: 45px;
    }
    .in-title > i {
      font-size: 32px;
      top: 16px;
    }
  }
}
@media screen and (max-width: 1700px) {
  .account-register {
    .in-register-form-area {
      max-width: 1000px;
      min-width: 600px;
      padding: 32px;
      width: 70%;
      .in-form {
        min-width: 500px;
        width: 70%;
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .in-reset-password.page-container {
    .in-reset-password-form {
      min-width: 800px;
      width: 70%;
    }
  }
}
@media screen and (max-width: 1700px) {
  .account-sales-order-view {
    .in-rma-container .in-order-status-list li {
      font-size: 16px;
      margin-right: 35px;
    }
  }
  .in-productList-container {
    .in-product .in-ads-product-info {
      .in-ads-product-name span {
        font-size: .65rem;
      }
      .in-ads-product-price {
        .in-price-final span {
          font-size: 15px;
        }
        .in-price-max span {
          font-size: .65rem;
          // text-decoration-line: line-through;
        }
        .in-price-max.final span {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 1850px) {
  .in-ads-product-name {
    font-size: 20px;
  }
  .in-ads-product-price {
    .in-price-final,.in-price-max {
      font-size: 16px;
    }
    .in-price-max.final {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1850px) {
  .in-ads-product {
    .in-product .in-ads-product-info {
      .in-ads-product-name span {
        font-size: .65rem;
      }
      .in-ads-product-price {
        .in-price-final span {
          font-size: 15px;
        }
        .in-price-max span {
          font-size: .65rem;
          // text-decoration-line: line-through;
        }
        .in-price-max.final span {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 1850px) {
  .cms-index {
    .in-product-reviews-wrapper {
      .in-reviews-left,.in-reviews-right {
        h5,span {
          font-size: .6rem !important;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .in-menu {
    > a {
      .font-size-rem(16px);
    }
  }
  .in-submenu {
    > li a {
      .font-size-rem(14px);
    }
  }
}
@media screen and (min-width: 1024px) {
  .in-orderinfo-wrapper {
      .in-orderItems-wrapper,.in-shipping-wrapper {
        .padding-left-rem(20);      .padding-right-rem(20);
      }
    }
}
@media screen and (min-width: 1201px) {
  .in-home-newsletter {
    & .in-container {
      .padding-left-rem(0);
    }
  }
  .in-home-newsletter {
    .download-app {
      margin-right: -5.2rem;
    }
  }
}
@media screen and (min-width: 1280px) {
  .in-container {
    .est-layout-page(90%);    padding: 0;
  }
}
@media screen and (min-width: 1280px) {
  .in-container {
    .est-layout-page(90%);    padding: 0;
  }
  .in-home-newsletter {
    .download-app {
      margin-right: -3rem;
    }
  }
}
@media screen and (min-width: 1300px) {
  .in-menu {
    > a {
      .font-size-rem(19px);
    }
  }
  .in-submenu {
    > li a {
      .font-size-rem(17px);
    }
  }
}
@media screen and (min-width: 1300px) {
  .in-header-menu {
    .in-menu {
      .in-submenu > li a {
        .font-size-rem(16px);
      }
      > a {
        .font-size-rem(18px);
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .in-sort-by-wrapper {
    & .in-sort-by > ul span {
      .padding-left-rem(24);      .padding-right-rem(24);      .font-size-rem(22);
    }
  }
}
@media screen and (min-width: 1440px) {
  .in-netGoods-form {
    .padding-left-rem(100);    .padding-right-rem(100);
  }
  .in-register-form {
    .padding-left-rem(100);    .padding-right-rem(100);
  }
}
@media screen and (min-width: 1440px) {
  .in-address-container {
    flex: 1;
    width: auto;
    height: auto;
  }
  .in-dashboard-container {
    flex: 1;
    width: auto;
    height: auto;
  }
  .in-history-container {
    flex: 1;
    width: auto;
    height: auto;
  }
  .in-information-container {
    flex: 1;
    width: auto;
    height: auto;
  }
  .in-menus-container {
    .padding-right-rem(80);
    width: 22%;
  }
  .in-view-container {
    flex: 1;
    width: auto;
    height: auto;
  }
  .in-wishlist-container {
    flex: 1;
    width: auto;
    height: auto;
  }
}
@media screen and (min-width: 1440px) {
  .in-orderinfo-wrapper {
      .in-orderItems-wrapper {
        .padding-left-rem(100);      .padding-right-rem(100);
      }
      .in-shipping-wrapper {
        .padding-left-rem(100);      .padding-right-rem(100);
        & .in-total-wrapper {
          width: 35%;
        }
      }
  }
}
@media screen and (min-width: 1440px) {
  .in-wishlist-container {
    & .in-product {
      .make-column(1/4, 2%);
      &:nth-child(4n) {
        margin-left: 2%;
      }
      &:nth-child(5n) {
        margin-left: 0;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .in-productList-container {
    .in-sort-by-wrapper & .in-sort-by > ul span {
      .padding-left-rem(24);      .padding-right-rem(24);      .font-size-rem(22);
    }
  }
}
@media screen and (min-width: 1500px) {
  .in-header-menu {
    .in-logo {
      margin-right: 40px;
    }
    .in-quick-tool {
      margin-left: 40px;
    }
  }
  .in-menu {
    > a {
      .font-size-rem(22px);
    }
  }
  .in-submenu {
    > li a {
      .font-size-rem(20px);
    }
  }
}
@media screen and (min-width: 1500px) {
  .xp-search-modal {
    .font-size-rem(18px);
  }
  .xp-search-product-name {
    .font-size-rem(18px);
  }
  .xp-search-product-price {
    .font-size-rem(16px);
  }
  .xp-search-product-sale {
    .font-size-rem(20px);
  }
}
@media screen and (min-width: 1500px) {
  .in-container {
    .est-layout-page(1240px);
  }
}
@media screen and (min-width: 1500px) {
  .in-container {
    .est-layout-page(1240px);
  }
  .in-home-newsletter {
    .download-app {
      margin-right: -5rem;
    }
  }
}
@media screen and (min-width: 1500px) {
  .xp-search-item {
    .xp-search-product {
      .xp-search-product-name {
        .font-size-rem(18px);
      }
      .xp-search-product-price .price {
        .font-size-rem(16px);
      }
      .xp-search-product-sale .price {
        .font-size-rem(20px);
      }
    }
  }
  .xp-search-modal {
    .font-size-rem(18px);
  }
}
@media screen and (min-width: 1500px) {
  .in-header-menu {
    .in-menu {
      .in-submenu > li a {
        .font-size-rem(19px);
      }
      > a {
        .font-size-rem(21px);
      }
    }
  }
}
@media screen and (min-width: 1700px) {
  .in-menu {
    > a {
      .font-size-rem(24px);
    }
  }
  .in-submenu {
    > li a {
      .font-size-rem(22px);
    }
  }
  .in-home-newsletter {
    .download-app {
      margin-right: -12rem;
    }
  }
}
@media screen and (min-width: 1700px) {
  .in-container {
    .est-layout-page(1520px);
  }
  .xp-search-content {
    margin-left: 5rem;
  }
}
@media screen and (min-width: 1700px) {
  .in-container {
    .est-layout-page(1520px);
  }
}
@media screen and (min-width: 1700px) {
  .xp-search-item {
    .xp-search-product {
      .xp-search-product-name {
        .font-size-rem(22px);
      }
      .xp-search-product-price .price {
        .font-size-rem(18px);
      }
      .xp-search-product-sale .price {
        .font-size-rem(24px);
      }
    }
  }
  .xp-search-modal {
    .font-size-rem(22px);
  }
}
@media screen and (min-width: 1700px) {
  .in-header-quick, .in-home-newsletter, .in-help {
    .in-container {
      max-width: 1520px;
    }
  }
}
@media screen and (min-width: 1700px) {
  .in-header-menu {
    .in-container {
      width: 1520px;
    }
    .in-menu {
      .in-submenu > li a {
        .font-size-rem(22px);
      }
      > a {
        .font-size-rem(24px);
      }
    }
  }
  .in-ads-product-content {
    .in-ads-product-img {
      img {
        width: 11.85rem;
      }
    }
  }
}
@media screen and (min-width: 1720px) {
  .in-sort-by-wrapper {
    & .in-sort-by > ul span {
      .font-size-rem(26);      .padding-left-rem(24);      .padding-right-rem(24);
    }
  }
}
@media screen and (min-width: 1720px) {
  .in-productList-container {
    .in-sort-by-wrapper .in-sort-by > ul span {
      .font-size-rem(26);      .padding-left-rem(24);      .padding-right-rem(24);
    }
  }
}
@media screen and (min-width: 1920px) {
  .in-netGoods-form {
    .padding-left-rem(200);    .padding-right-rem(200);
  }
  .in-register-form {
    .padding-left-rem(200);    .padding-right-rem(200);
  }
}
@media screen and (min-width: 1px) {
  .in-header-menu {
    .in-menu {
      .in-submenu > li a {
        .font-size-rem(14px);
      }
      > a {
        .font-size-rem(16px);
      }
    }
  }
}
