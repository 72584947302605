.order-search-detail {
    .in-not-found-page {
        padding-bottom: 0;
        width: 100%;
    }
    .in-order-view-content {
        flex-direction: column;
    }
    .in-view-container {
        width: 100%;
    }
    .in-order-view-content {
        margin-top: 32px;
    }
}
.order-search-panel {
    background-color: #fff;
    margin: 32px auto 80px;
    max-width: 1320px;
    min-width: 750px;
    width: 65%;
    .in-btn-col {
        .padding-bottom-rem(10);
        padding-top: 0;
    }
    .in-input-control {
        .help {
            display: block!important;
        }
        input {
            margin: 0 auto;
        }
        span {
            margin: 0 auto;
            width: 500px;
        }
    }
    .in-toogle {
        .padding-bottom-rem(30);
        & a {
            .font-size-rem(12);
            color: @btn-base-color;
        }
    }
    .in-toogle a {
        color: #000;
    }
    form {
        padding: 48px 24px 64px !important;
        .in-btn-col {
            padding: 0 0 24px;
            margin-top: 0px;
            button {
                font-size: .9rem;
                height: 47px;
                font-family: DINPro-Regula;
                border-radius: 4px;
                width: 400px;
            }
        }
        .in-input-col {
            margin-top: 32px;
            padding-bottom: 0;
            .has-error input {
                border: 1px solid #ff3600;
                margin-bottom: 10px;
            }
            .in-input-control {
                input {
                    border: 1px solid #e8e8e8;
                    font-size: .9rem;
                    border-radius: 4px;
                    font-family: DINPro-Regula;
                    height: 47px;
                    width: 400px;
                }
                span {
                    color: #ff3600;
                    font-size: 18px;
                    height: auto;
                    line-height: 1em;
                    margin-bottom: 16px;
                    padding: 0;
                    width: 400px;
                }
            }
        }
        .in-toogle {
            padding-bottom: 0;
            a {
                font-size: .8rem;
            }
        }
        .search-help {
            color: #999999;
            font-size: .8rem;
            margin-top: 12px;
            padding: 0;
        }
        .search-help-tel {
            color: @main-btn-color;
        }
        > h2 {
            color: #000;
            font-size: 1rem;
            font-family: DINPro-Regular;
            font-weight: normal;
        }
        > .please-note {
            color: #000;
            font-size: .8rem;
            line-height: 36px;
            padding: 10px 160px 0;
        }
    }
}
