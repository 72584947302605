.account-pp-order-details {
    .continue-pay-wrapper .in-btn .in-bg-orange {
        background-color: @main-btn-color;
        cursor: pointer;
    }
    .grand_total {
        color: #666666;
        font-size: 18px;
    }
    .grp-txt {
        border-radius: 20px;
        border: 1px solid #ff3200;
        box-sizing: border-box;
        height: auto;
        line-height: 1.2;
        margin-right: 10px;
        padding: 10px 20px;
        width: auto;
        &:-moz-placeholder,&:-ms-input-placeholder,&::-moz-placeholder,&::-webkit-input-placeholder {
            color: black;
            font-weight: bold;
        }
        &:focus,&:hover {
            outline: 1px solid #ff6b00;
        }
    }
    .in-items-ordered {
        padding: 0 24px 24px;
        &:hover {
            box-shadow: none;
        }
    }
    .in-order-information {
        margin-top: 20px;
        padding: 0 24px 24px;
        &:hover {
            box-shadow: none;
        }
        .in-information-wrapper {
            border: 0;
            padding: 29px 0;
            .history-info {
                box-sizing: border-box;
                margin-left: 0;
                padding-right: 20px;
                width: 50%;
                > i {
                    color: #999999;
                    font-size: 54px;
                }
            }
            .info {
                margin-left: 24px;
                .tel {
                    color: #999999;
                    font-size: 14px;
                    line-height: 1em;
                    margin-top: 8px;
                }
                h6 {
                    color: #333333;
                    font-size: 16px;
                    line-height: 1em;
                    margin-bottom: 8px;
                }
                p {
                    color: #666666;
                    font-size: 16px;
                }
            }
        }
        .in-payment-info {
            align-items: center;
            display: flex;
            padding: 11px 0;
            &::before {
                display: none;
            }
            .info {
                margin-left: 24px;
                h6 {
                    color: #333333;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 1em;
                }
                p {
                    color: #666666;
                    font-size: 16px;
                }
            }
            > i {
                color: #999999;
                font-size: 54px;
            }
        }
        > h6 {
            border-bottom: 1px solid #e8e8e8;
            font-size: 16px;
            height: 54px;
            padding-left: 0;
        }
    }
    .in-ram-popup {
        .in-ram-popup__content .subTitle {
            border-bottom: 0;
            font-size: 18px;
            margin-bottom: 0;
        }
        .in-title {
            background-color: #fff;
            h3 {
                color: #333;
                font-size: 28px;
                padding-top: 0px;
            }
            i {
                color: #333;
            }
        }
        table .item td {
            padding: 0 10px;
        }
        th.total {
            margin-top: 0;
        }
    }
    .logistic-label i {
        color: #ff3200;
        float: right;
        font-style: normal;
    }
    .main-container {
        margin-bottom: 108px;
        margin-top: 24px;
    }
    .subtotal {
        margin-top: 16px;
    }
    .total {
        color: #999999;
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-top: 12px;
        min-width: 300px;
        div:first-child {
            font-style: italic;
            margin-right: 20px;
        }
    }
    .total-area {
        align-items: flex-end;
        color: #666;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
    }
    .v--modal-box {
        .in-items-ordered {
            padding: 0;
        }
        .in-select__wrapper {
            padding: 5px 5px 5px 10px;
        }
        table {
            .history-order-header th span {
                font-size: 14px;
                height: 30px;
            }
            .item td {
                padding: 10px;
            }
        }
        © table .item td {
            padding: 10px;
        }
    }
    input {
        font-size: 14px;
        width: 100%;
    }
    table {
        margin-bottom: 1rem;
        table-layout: fixed;
        width: 100%;
        .history-order-header {
            background-color: #eee;
            th {
                border: 1px solid #e8e8e8;
                > span {
                    height: 50px;
                }
                span {
                    align-items: center;
                    color: #666;
                    display: flex;
                    font-size: 16px;
                    height: 54px;
                    justify-content: center;
                    line-height: 1.2em;
                }
            }
        }
        .history-order-title {
            line-height: 52px;
            .in-back {
                padding-right: 0;
                i {
                    color: #999999;
                    font-size: 34px;
                }
            }
            span {
                color: #333;
                display: inline-block;
                font-size: 24px;
                line-height: 1em;
            }
        }
        .item {
            border-bottom: 0;
            td {
                border: 1px solid #e8e8e8;
                padding: 20px;
                span {
                    align-items: center;
                    color: #666;
                    display: flex;
                    font-size: 14px;
                    justify-content: center;
                    line-height: 1.2em;
                    min-height: auto;
                    padding: 0;
                    word-break: break-word;
                }
            }
            td.name span {
                justify-content: flex-start;
                margin-bottom: 10px;
                + span {
                    margin-top: 5px;
                }
            }
        }
    }
}
