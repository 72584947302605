.in-form-group {
    .margin-bottom-rem(20);
    .margin-top-rem(20);
    text-align: left;
    width: 100%;
    .in-form-control {
        .padding-top-rem(6);    .padding-bottom-rem(6);    .padding-left-rem(20);    .padding-right-rem(20);    border-radius: 50px;    outline: none;    border: 1px solid #666666;    .font-size-rem(14);
        background: #666666;
        color: #999999;
        display: block;
        height: 44px;
        width: 100%;
    }
    .in-form-control.is-danger {
        background: #664444;
        border: 1px solid #ff0000;
        color: #fff;
    }
}
.in-form__content + .in-form__content {
    border-top: 1px solid #000;
    margin-top: 30px;
    padding-top: 30px;
}
