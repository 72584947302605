.pre-order {
    .in-dashboard-container {
        background-color: #fff;
        min-height: 515px;
        padding: 0;
        .in-group-item {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            margin-bottom: 0;
            padding: 40px 0;
            &:hover {
                box-shadow: none;
            }
            &:last-child {
                border-bottom: 0;
            }
            .in-group-content {
                padding: 0;
                width: 50%;
            }
            > a {
                width: 50%;
            }
        }
        .in-preOrder-wrapper {
            padding: 0 24px;
        }
        .in-tab {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            height: 50px;
            margin-bottom: 0;
            margin: 0 24px;
            .active {
                color: #000;
            }
            span {
                border: 0;
                color: #999;
                font-size: 16px;
                margin-right: 42px;
                padding: 0;
            }
        }
    }
    .in-group-info {
        margin-bottom: 20px;
        .in-group-des {
            color: #666;
            font-size: 20px;
            margin-top: 8px;
            padding: 0;
        }
        > h6 {
            font-size: 30px;
            font-weight: normal;
        }
    }
    .in-group-price {
        margin-bottom: 20px;
        .in-price-final,.in-price-max {
            color: #ff6b00;
            font-size: 30px;
            margin-left: 0;
        }
        .in-price-max.final {
            color: #999;
            font-size: 18px;
            margin-left: 10px;
        }
    }
    .in-operation .in-btn-group {
        background-color: #ffebdc;
        border-radius: 24px;
        border: 1px solid #ff6b00;
        height: 44px;
        line-height: 44px;
        span {
            color: #ff6b00;
            font-size: 22px;
        }
    }
    .in-product-timer {
        > h6 {
            background-color: @muted-btn-color;
            border-radius: 0;
            font-size: 16px;
            font-size: 18px !important;
            padding: 6px;
        }
        ul li {
            span:first-child {
                color: @main-btn-color;
                font-size: 38px;
            }
            span:last-child {
                color: #333;
            }
        }
    }
    .in-timer-people {
        margin-bottom: 30px;
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
}

.btn-loading {
  opacity: 0.7;
  cursor: no-drop;
}

.export-container {
  display: flex;

  div + div {
    margin-left: 30px;
  }
}
