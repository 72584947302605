.in-mini-cart {
    position: relative;
    & .in-cart-qty {
        .font-size-rem(12);    background: #FF4D56;    color: #fff;    border-radius: 15px;    .padding-left-rem(5);    .padding-right-rem(5);    .padding-top-rem(2);    .padding-bottom-rem(2);    line-height: 1em;
        position: absolute;
        right: 0;
        top: 3px;
    }
    i {
        .font-size-rem(42);  color: #000;
    }
}
