.in-payment {
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    border: 2px solid transparent;
    box-sizing: border-box;
    color: #333333;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    padding: 30px;
    transition: all 0.25s ease;
    .payment-desc {
        .font-size-rem(12);    color: #a1a1a1;    line-height: 1.6;
        padding-top: 10px;
    }
    .payment-icon {
        padding: 0 40px 0 30px;
    }
    .payment-title {
        .font-size-rem(16);    font-weight: 600;    line-height: 1.4;
    }
}
.in-payments-container .in-payment.active {
    border: 2px solid @btn-base-color;
    .check-icon__label {
        background: @btn-base-color;
        &::before {
            .font-size-rem(26);        color: #fff;        line-height: 28px;        text-align: center;
            content: "\e608";
            display: inline-block;
        }
    }
}
