.continue-pay-wrapper {
    background: #fff;
    margin-bottom: 20px;
    padding: 0 30px;
    text-align: right;
    .in-btn {
        display: inline-block;
        margin-left: 40px;
        .in-bg-orange {
            background: #eb6237;
        }
        a {
            .centered-line(30px);      .font-size-rem(14);
            width: 130px;
        }
    }
    p {
        display: inline-block;
        line-height: 60px;
        & span {
            vertical-align: middle;
        }
    }
}
