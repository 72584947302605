.in-shipAdd {
    .margin-right-rem(20);
    .padding-bottom-rem(20);
    .padding-left-rem(20);
    .padding-right-rem(20);
    .padding-top-rem(20);
    background: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 180px;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    transition: all .5s;
    width: 270px;
    .in-choose-add {
        align-items: center;
        background: @btn-base-color;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 18px;
        opacity: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 18px;
        & i {
            .font-size-rem(14);      line-height: 18px;
        }
    }
    > .in-edit {
        .font-size-rem(14);
        align-self: flex-end;
        color: @base-font-color;
    }
    > .in-name {
        .font-size-rem(18);    .padding-top-rem(10);    .padding-bottom-rem(20);
    }
    > .in-tel {
        font-style: italic;
    }
    > p {
        .font-size-rem(12);    color: #000;
        line-height: 20px;
    }
    &:hover {
        box-shadow: 0 0 30px #b5b3b3;
    }
}
.in-shipAdd-container {
    padding: 0 17px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.in-shipAdd.active {
    border: 1px solid @btn-base-color;
    & .in-choose-add {
        opacity: 1;
    }
}
.in-shipAdd.disabled {
    cursor: no-drop;
}
