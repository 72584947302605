.in-account-info-area {
    background: #fff;
    border-radius: 4px;
    display: block;
    height: 302px !important;
    position: absolute;
    width: 360px;
    .account-email {
        color: #000;
        font-size: 16px;
    }
    .account-name {
        color: #000;
        font-size: 16px;
        margin-bottom: 9px;
    }
    .my-code {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      color: #000;
      font-size: 20px;
      margin-bottom: 9px;

      .text-to-copy {
        font-weight: bold;
      }

    }
    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 94px;
        width: 94px;
        border-radius: 50%;
        background: #e3e3e2;
        left: 50%;
        margin-bottom: 25px;
        margin-top: 60px;
        overflow: hidden;
        position: relative;
        transform: translateX(-50%);
        &.load::before {
            background: #f7f7f7;
            border-radius: 50%;
            border: 5px solid #fff;
            box-sizing: border-box;
            content: "";
            display: inline-block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .hide {
            display: none;
        }
        img {
            margin: 0;
        }
    }
    img {
        margin-bottom: 25px;
        margin-top: 60px;
    }
    .account-name {
      img {
        width: 11px;
        height: 14px;
        border: 0;
        border-radius: 0;
        margin: 0;
      }
    }
    .my-code {
      img {
        margin: 0;
        margin-inline: 5px;
        cursor: pointer;
        width: 15px;
        height: 20px;
        border: 0;
        border-radius: 0;
      }

      img.copy-button {
        margin: 0;
        margin-inline: 5px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 0;
        border-radius: 0;
        opacity: 1;
      }

      img.copy-button:active {
       opacity: .2;
      }

    }
}
.in-account-login-container,.in-account-register-container {
    height: 100%;
    position: relative;
    text-align: center;
}
.account-login {
  .in-account-login-container.changewidth{
    width: 400px;
  }
}
.in-account-netGoods-container {
    height: 100%;
    position: relative;
    text-align: center;
    .in-address-select .swiper-container {
        box-shadow: none;
    }
  .in-state, .in-city, .in-district, .in-area {
        position: relative;
    }
}
.in-accout-coupon-container & .in-coupon {
    cursor: initial;
}
.content-con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 40px;
  padding-inline: 95px;

  .header-form {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 20px;
    color: #000;
    font-size: 26px;
    margin-bottom: 9px;
    width: 100%;

    #text-to-copy {
      font-weight: bold;
    }

    .line {
      margin-top: 40px;
      width: 100%;
      height: 1px;
      background: darkgrey;
    }

  }

  .form-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .context {
      font-size: 18px;
      font-weight: bold;
      margin-block: 30px;
    }

    .input-form-con {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .label-form {
        //color: #d0d0d0;
        display: inline-block;
        font-size: 16px;
        height: auto;
        line-height: 1em;
        text-wrap: nowrap;
      }

      .input-code{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;

        .input-form {
          background-color: #f7f7f7;
          width: 100%;
          border-radius: 0;
          border: 0;
          font-size: 20px;
          color: #333;
          height: 70px;
          padding: 20px;
          margin-block: 10px;
          box-sizing: border-box;
          //outline: none;
        }

        .empty-data {
          font-size: 16px;
          color: red;
        }

      }


      button {
        background: #000;
        border-radius: 6px;
        font-size: 32px;
        //height: 45px;
        //width: 400px;
        padding-inline: 30px;
        padding-block: 20px;
        color: white;
      }
    }

  }

}
