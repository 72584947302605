.full-table {
    border-bottom: 1px solid #ccc;
    li {
        height: auto!important;
        min-height: 50px;
        & > span {
            flex: 3!important;
        }
        .small {
            flex: 1!important;
        }
    }
    span {
        display: block;
        & span {
            line-height: 1.5;
            text-transform: capitalize;
        }
    }
}
.table-action {
    color: #666;
    display: inline-block;
    padding: 2px 5px;
}
.table-border-line {
    border-bottom: 1px solid #D0D0D0;
    width: 100%;
    .name {
        font-size: 14px;
        width: 30%;
    }
    .value {
        font-weight:500;
    }
    td {
        color: #333333;
        font-size: 16px;
        line-height: 1.4;
        padding: 20px 0;
    }
    tr {
        border-top: 1px solid #D0D0D0;
    }
}
.in-table {
    .action {
        color: #333 !important;
        cursor: pointer;
        a {
            color: #333 !important;
            cursor: pointer;
        }
    }
    .in-action {
        color:  @btn-base-color;
        cursor: pointer;
    }
    .talbe-heade {
        background: #ccc;
        color: #fff;
        padding: 14px 20px;
        span {
            font-size: 18px;
        }
    }
    li {
        display: flex;
        flex-direction: row;
        padding: 10px 20px;
        & span {
            flex: 1;
            font-size: 14px;
            text-align: center;
        }
        + li {
            border-top: 1px solid #ccc;
        }
    }
}
