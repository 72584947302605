.pick-up {
    margin: 0 0 24px 0;
    &__search {
        margin: 10px;
        position: relative;
        .xp-search-icon {
            color: #ff6b00;
            font-size: 30px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
        input[type='text'] {
            background: #fff;
            border-radius: 4px;
            border: 1px solid #DBDBDB;
            color: #999;
            font-size: 14px;
            outline: none;
            padding: 10px 30px 10px 10px;
            width: 100%;
        }
    }
    &__select {
        overflow: hidden;
    }
    .modal-footer {
        border-top: 1px solid #F5F5F5;
        padding: 10px 20px;
    }
}

