.in-address-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    height: auto;
    justify-content: space-between;
    width: 75%;
    .active {
        border: 2px solid @btn-base-color;
        & .in-choose-add {
            opacity: 1;
        }
    }
    .in-add-shipAdd {
        display: flex;
        flex-direction: row;
        justify-content: center;
        line-height: 180px;
    }
    .in-address {
        .margin-bottom-rem(20);
        background: #fff;
        border: 2px solid transparent;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 180px;
        justify-content: space-between;
        overflow: hidden;
        position: relative;
        transition: all .5s;
        width: 49%;
        .in-choose-add {
            align-items: center;
            background: @btn-base-color;
            color: #fff;
            display: flex;
            flex-direction: column;
            height: 18px;
            opacity: 0;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 18px;
            & i {
                .font-size-rem(14);        line-height: 18px;
            }
        }
        .in-edit {
            line-height: 180px;
            span {
                .padding-right-rem(20);        cursor: pointer;        .font-size-rem(14);        color: #999999;
            }
            span:hover {
                color: @btn-base-color;
            }
        }
        .in-name {
            .padding-top-rem(10);      .padding-bottom-rem(10);
        }
        .in-tel {
            .padding-top-rem(10);      font-style: italic;
        }
        > div:first-child {
            .padding-rem(20);
        }
        p {
            .font-size-rem(14);
            color: #000;
            line-height: 24px;
            & i {
                .padding-right-rem(5);
            }
        }
        &:hover {
            box-shadow: 0 0 30px #b5b3b3;
        }
        .in-city-region {
            .padding-left-rem(25);
        }
    }
}
