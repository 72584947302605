.cms-index {
    font-family: DINPro-Regular;
    & .in-product-reviews-wrapper {
        .in-preview-img {
            display: table-cell;
            & > a {
                align-items: center;
                background: #f7f7f7;
                // border-radius: 2px;
                // border: 1px solid #e6e6e6;
                display: flex;
                height: 174px;
                justify-content: center;
                width: 174px;
            }
        }
        .in-review-title {
            .padding-bottom-rem(50);
        }
        .in-reviews > div {
            display: flex;
        }
        .in-reviews-left {
            .padding-right-rem(40);      .padding-left-rem(40);      float: none;
            display: table-cell;
        }
        .in-reviews-list {
            .padding-bottom-rem(60);
        }
        .in-reviews-right {
            display: table-cell;
            float: none;
        }
    }
    .in-home-banner {
        &:hover {
            .swiper-button-next.swiper-button-gray,.swiper-button-prev.swiper-button-gray {
                display: block;
                &:hover {
                    background-color: rgba(216, 216, 216, 0.42);          color: #eeeeee;
                }
            }
            .swiper-container-rtl {
                .swiper-button-next.swiper-button-gray,.swiper-button-prev.swiper-button-gray {
                    display: block;
                    &:hover {
                        background-color: rgba(216, 216, 216, 0.42);          color: #eeeeee;
                    }
                }
            }
        }
        .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: 37px;
        }
        .swiper-pagination-custom,.swiper-pagination-fraction {
            bottom: 37px;
        }
    }
    .in-product-reviews-wrapper {
        padding-top: 36px;
        padding: 0;
        .in-add-rating {
            background: transparent;
            .in-review-title-box {
                border: 1px solid #000;
                display: inline-block;
                padding: .5rem 1.25rem;
            }
        }
        .in-preview-img > a {
              height: 5.3rem;
              width: 5.3rem;
            img {
              height: 100%;
              width: 100%;
              border-radius: .25rem;
            }
        }
        .in-reviews {
            color: #000;
            padding: 0;
            &:last-child > div {
                border-bottom: 0;
            }
            .in-preview-img {
                flex-shrink: 0;
                margin-left: .4rem;
            }
            .in-rating {
                margin-left: 0;
                margin-top: 0;
                .active {
                    color: @main-btn-color;
                }
                li {
                    margin-right: .15rem;
                    &:first-child {
                        margin-left: 0;
                    }
                    i {
                        font-size: 1.2rem;
                    }
                }
            }
            .in-reviews-area {
                flex-grow: 1;
                padding: 0 32px;
            }
            .in-reviews-left {
                align-items: center;
                display: flex;
                float: none;
                padding-left: 0;
                padding: 0;
                width: 100%;
                h5 {
                    color: #999999;
                    font-size: 26px;
                    font-weight: normal;
                    line-height: 1em;
                }
                span {
                    color: #a4a4a4;
                    flex-grow: 1;
                    float: right;
                    font-size: 24px;
                    text-align: right;
                }
            }
            .in-reviews-right {
                display: block;
                float: none;
                margin-top: 0;
                width: 100%;
                h5 {
                    color: #333;
                    font-size: 26px;
                    font-weight: normal;
                }
                p {
                    color: #000;
                    font-size: 24px;
                    line-height: .8rem;
                    margin-top: .7rem;
                    word-break: break-word;
                }
            }
            .previewImg li {
                border-radius: 4px;
                border: 0;
                height: 100px;
                width: 100px;
            }
            > div {
                align-items: flex-start;
                display: flex;
                padding: .4rem 0;
                width: 100%;
            }
        }
        .in-reviews-left {
            h5,span {
                font-size: .6rem !important;
            }
        }
        .in-reviews-list {
            padding-bottom: 0;
            .in-reviews {
                background: #fff;
                display: inline-block;
                margin: 0 .45rem .55rem;
                width: 48.4%;
            }
            .in-reviews-content {
                height: 4rem;
                padding-right: 20px;
                width: 100%;
            }
            .in-reviews-name {
                height: 4rem;
                display: flex;
                align-items: center;
                text-align: center;
                h5 {
                    font-size: .6rem;
                    line-height: 1rem;
                    width: 4.6rem;
                    font-family: DINPro-Medium;
                }
            }
        }
        .in-reviews-right {
            h5 {
                font-size: .6rem !important;
            }
            p {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                display: -webkit-box;
                font-size: .6rem !important;
                overflow: hidden;
            }
        }
    }
}
