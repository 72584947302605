.in-menu > a {
    .centered-line(32px);
    .font-size-rem(14px);
    .inline-block();
    .padding-left-rem(0);
    .padding-right-rem(0);
    color: #666666;
    &:hover {
        border-bottom: 2px solid #FF6B00;
        color: #FF6B00;
        font-family: @font-medium;
        font-weight: 500;
    }
}
.in-menu-dropdown.pc {
    -webkit-box-shadow: 0 3px 5px rgba(0,0,0,.24);  box-shadow: 0 3px 5px rgba(0,0,0,.24);  position: absolute;  z-index: 1;  top: 0;  left: 0;
    background: #fff;
    display: none;
    padding: 0;
    width: 100%;
}
.in-menu-wrapper {
    flex-grow: 4;
    position: relative;
    & > ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        & > li {
            &:first-child {
                padding-left: 0;
            }
            &:hover .in-submenu {
                animation: fadeIn 0.35s ease-in-out;
                display: block;
                z-index: 11;
            }
        }
    }
    .in-menu {
        margin-right: 10px;
        position: relative;
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
    }
    ul {
        justify-content: flex-start;
        .in-menu {
            align-items: center;
            display: flex;
            margin-right: 0;
            padding: 0 25px;
            &:last-child {
                padding-right: 0;
            }
        }
    }
}
.in-menu-wrapper.pc {
    height: auto;
    line-height: inherit;
    .in-menu-icon-wrapper {
        height: 3.5rem;
        line-height: 3.5rem;
        overflow: hidden;
        padding-left: 20px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
    }
}
.in-menus-container {
    height: auto;
    width: 25%;
    .active {
        b {
            display: block;
        }
        span {
            background: @btn-base-color;
            color: #fff;
        }
    }
    li {
        .padding-right-rem(16);
        cursor: pointer;
        position: relative;
        & b {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
        }
        &:hover {
            b {
                display: block;
            }
            span {
                background: @btn-base-color;
                color: #fff;
            }
        }
        span.badge {
                width: 10px;
                height: 10px;
                position: absolute;
                background: red;
                padding: 0;
                border-radius: 5px;
        }
    }
    span {
        .font-size-rem(14);    line-height: 16px;    .padding-left-rem(24);
        color: #000;
        display: block;
    }
    li .xp-icon-account-phone + span {
        .font-size-rem(26px) !important;  word-break: break-word;
    }
}
.in-submenu {
    background: #fff;
    display: none;
    // left: 15px;
    min-width: 100%;
    position: absolute;
    top: 100%;
    // transform: translate(-50%, 0);
    > li {
        box-sizing: border-box;
        &:hover {
            background: #FF6B00;
            a {
                color: #fff;
            }
        }
        a {
            .font-size-rem(12px);      .centered-line(40px);      transition: all 0.25s ease;      white-space: nowrap;
            color: #999999;
            display: block;
            padding: 0 1.6rem 0 1rem;
            text-align: left;
        }
    }
    li + li {
        border-top: 1px solid #eee;
    }
}
