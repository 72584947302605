.in-topics {
  width: 100%;
  .nomore {
    margin-top: 206px;
    margin-bottom: 314px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
  }
  .product-topics {
    .topics-content {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      padding: 10px 24px 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      .species {
        display: inline-block;
        width: 20px;
        margin: 0 4px;
      }

      .gift {
        display: inline-block;
        width: 24px;
        margin: 0 4px;
      }

      .protrude {
        color: #FFAE35;
      }
    }

    .series-list li:first-child {
      padding-left: 28px !important;
    }
    .series-list {
      // overflow: hidden;
      // overflow-x: auto;
      // &::-webkit-scrollbar {
      //   display: none; /*隐藏滚轮*/
      // }
      // display: flex;

      .product-series {
        padding-right: 28px;
        padding-left: 28px;
        height: 50px !important;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 50px !important;
        display: flex;
        cursor: pointer;
        align-items: center;
        .series-title {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          white-space: nowrap;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .activeSeries {
        border: 1px solid #ff3200;
        p {
          color: #000 !important;
        }
      }
    }

    .product-selection {
      display: flex;
      margin: 20px 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      justify-content: flex-end;
      color: #5D5E5E;

      .Ranking {
        margin-right: 49px;
        cursor: pointer;
      }
      .Latest {
        cursor: pointer;
      }
      .activeSelection {
        color: #ff3200;
        font-weight: bold;
      }
    }

    .product-details {
      margin-top: 20px;
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(2,minmax(0,1fr));

      .detail-left {
        height: 158px;
        background: #f3f3f3;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 22px;

        .left-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-right: 30px;
          .detail-content {
            width: 100%;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: #000;
            font-weight: 500;
            a{
              color: #000;
            }
          }
          .product-user {
            display: flex;
            align-items: center;
            .user-avatar {
              display: flex;
              align-items: center;
              margin-right: 117px;

              img {
                width: 38px;
                height: 38px;
                border-radius: 50%;
              }
            }
            .username {
              font-size: 14px;
              font-weight: 700;
              margin-left: 8px;
              line-height: 17px;
            }
            .like-num {
              font-size: 12px;
              font-weight: bold;
              margin-right: 4px;
              display: inline-block;
            }
            .likes {
              font-size: 12px;
              font-weight: 500;
            }
            .like-wrapper {
              display: flex;
            }
          }
        }
        .leftBox {
          width: 90% !important;
        }

        .product-video {
          flex: none;
          max-width: 151px;
          height: 114px;
          position: relative;

          &.has-video {
            cursor: pointer;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }

          .time {
            position: absolute;
            right: 5px;
            bottom: 2px;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }

        &.no-cover {
          .leftBox {
            width: 100%!important;
            padding: 0;
          }

          .product-user {
            justify-content: space-between;
          }
        }
      }
    }
  }

  .topic_icon {
    display: inline-block;
    width: 17px;
    margin-right: 6px;
  }


  .series-list {
    .swiper-button-prev.swiper-button-white:before,
    .swiper-container-rtl .swiper-button-next.swiper-button-white:before,
    .swiper-button-next.swiper-button-white:before,
    .swiper-container-rtl .swiper-button-prev.swiper-button-white:before {
      color: #6D6D6D !important;
      font-size: 1.2rem !important;
    }

    .swiper-slide {
      width: auto !important;
      display: inline-block;
    }
  }

  .swiper-button-prev.swiper-button-white,
  .swiper-container-rtl .swiper-button-next.swiper-button-white,
  .swiper-button-next.swiper-button-white,
  cc {
    width: 13px;
    height: 21px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: linear-gradient(90deg, #fff 20%, transparent 100%) !important;
    height: 50px !important;
    width: 36px !important;
    padding-right: 20px !important;
    opacity: 1;
    transform: translateY(-50%);
    margin: 0;
  }

  .swiper-button-next {
    background: linear-gradient(-90deg, #fff 45%, transparent 100%) !important;
    padding-right: 0 !important;
    padding-left: 20px !important;
  }

  .swiper-button-disabled {
    display: none;
  }
}

[data-modal="xfans-modal"] {
  video {
    max-width: 100%;
  }
}
