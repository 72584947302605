.in-group-content {
    .padding-rem(40);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    .final {
        text-decoration: line-through;
    }
    .in-group-des {
        .font-size-rem(14);    line-height: 16px;    .padding-top-rem(20);    .padding-bottom-rem(20);
    }
    .in-price-final {
        .font-size-rem(36);    color: #000;
    }
    .in-price-max {
        .font-size-rem(18);    color: @base-font-color;    .margin-left-rem(10);
    }
    .in-product-timer {
        background: #fdfbfb;
        border: 1px solid #ccc;
        display: inline-block;
    }
    h6 {
        .font-size-rem(26);    overflow: hidden;    text-overflow: ellipsis;    white-space: nowrap;
    }
}
.in-group-item {
    .margin-bottom-rem(40);
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: all .5s;
    .in-group-content {
        width: 45.714285%;
    }
    > a {
        display: block;
        height: 360px;
        width: 54.285714%;
        & img {
            height: 360px;
        }
    }
    &:hover {
        box-shadow: 0 0 30px #b5b3b3;
    }
}
