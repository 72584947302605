.account-wishlist {
    .in-product {
        &:hover {
            box-shadow: 0px 1px 18px 0px rgba(213, 213, 213, 1);
            .xp-icon-detail-delete {
                color: #666;
                display: block;
                font-size: 32px;
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 1;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .in-ads-product-img {
            .in-flag-wrapper {
                margin-top: 30px;
            }
            a {
                padding: 0;
            }
        }
        .in-ads-product-name {
            color: #666666;
        }
        .xp-icon-detail-delete {
            display: none;
        }
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
}
