.in-switch-language {
    position: relative;
    .icon-arrow-down {
        .inline-block();    transition: all .5s;    line-height: 16px;    vertical-align: text-top;    color: #fff;
    }
    > span {
        cursor: pointer;
        text-transform: uppercase;
    }
    i.active {
        transform-origin: center;
        transform: rotate(180deg);
    }
    &:hover .in-language-drop-menu {
        display: block;
    }
}
