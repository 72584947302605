.in-shipmation-container {
    .padding-rem(20);
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .in-address,.in-street {
        .font-size-rem(14);    .centered-line(20px);
    }
    .in-name {
        .font-size-rem(18);    .centered-line(30px);
    }
    .in-tel {
        .font-size-rem(14);    .centered-line(20px);
        color: @btn-base-color;
    }
    .in-time {
        .font-size-rem(14);
    }
}
