.in-language-drop-menu {
    background: #333;
    display: none;
    margin-top: -1px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 10;
    li {
        border-bottom: 1px solid #b0b0b0;
        text-transform: uppercase;
        & a {
            display: block;
        }
        &:last-child {
            border-bottom: none;
        }
        .centered-line(40px);
    }
    li:hover & a {
        color: #666;
    }
}
