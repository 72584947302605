.in-dropdown {
    position: relative;
    &-border {
        display: inline-block;
    }
    &-name {
        border-radius:4px;
        background: #ddd;
        border: 1px solid #DBDBDB;
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 36px;
        padding: 0 17px;
        justify-content: center;
        .xp-iconfont {
            margin-left: 12px;
        }
        span {
            color: #000;
            display: inline-block;
            font-size: 14px;
            line-height: 100%;
        }
        &,.in-pp-order {
          background: #fff;
          line-height: 43px;
        }
    }
    &-child {
        .icon-arrow-down {
            display: inline-block;
            transform: rotate(-90deg);      position: absolute;      right: 15px;      top: 0;      font-size: 12px;
        }
        .in-dropdown-item {
            padding-right: 30px;
            position: relative;
            text-align: left;
        }
    }
    &-content {
        border-top: 10px solid transparent;
        position: absolute;
        top: 90%;
        width: 100%;
        z-index: 100;
        &::before {
            border: 10px solid;
            border-color: transparent transparent #fff;
            content: '';
            display: inline-block;
            left: 50%;
            position: absolute;
            top: -20px;
            transform: translateX(-50%);
            width: 0;
            z-index: 100;
        }
    }
    &-item {
        height: auto!important;
        line-height: 40px!important;
        text-align: center;
    }
    &-text {
        color: #999!important;
        cursor: pointer;
        display: block;
        font-size: 12px!important;
        padding: 0 10px!important;
        word-wrap: break-word;
    }
    &-item.active,&-item:hover &-text {
        color: #000 !important;
    }
    &-items + &-items {
        left: 100%;
        position:absolute;
        top: 0;
        width: 100%;
    }
    &-wrapper {
        min-width: 160px;
        position: relative;
    }
    .swiper-container {
        background: #fff;
        border-radius: 4px;
        box-shadow:0px 12px 42px 0px rgba(38,38,38,0.24);
        height: 240px;
    }
    .swiper-slide {
        height: auto!important;
    }
}
.dropdown-actions {
    background: #fff;
    bottom: 0;
    left: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    z-index: 100;
    &.right {
        text-align: right;
    }
}
