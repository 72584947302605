.account-coupon-page {
    .in-accout-coupon-container {
        .in-coupon-list {
            align-content: flex-start;
            background-color: #fff;
            min-height: 600px;
            padding: 24px;
            .in-coupon .in-coupon-time p {
                font-size: 16px;
                line-height: 18px;
            }
        }
        .no-coupon-area {
            background-color: #ffffff;
            border-radius: 4px;
            color: #666666;
            font-size: 24px;
            height: 600px;
            line-height: 600px;
            text-align: center;
        }
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
}
