.account-sales-order-history {
    .in-history {
        background-color: #ffffff;
        margin-bottom: 16px;
        padding: 0 24px;
        &:hover {
            box-shadow: none;
        }
    }
    .in-history-item {
        align-items: center;
        display: flex;
        margin: 0;
        min-height: 192px;
        padding: 16px 0;
        .in-img {
            flex-shrink: 0;
            img {
                background-color: #f2f2f2;
                height: 140px;
                width: 140px;
            }
        }
        .in-info {
            flex-grow: 1;
            padding-left: 16px;
            h6 {
                color: #666666;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.2em;
                margin-bottom: 10px;
                padding: 0;
            }
            span {
                color: #333333;
                font-size: 16px;
            }
        }
        .in-ordered-qty {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            margin-left: 20px;
            padding-right: 0;
            .in-order-qty-icon {
                color: #999999;
                font-size: 22px;
                margin-right: 10px;
                margin-top: 1px;
            }
            span {
                color: #666666;
                font-size: 22px;
                font-style: normal;
            }
        }
    }
    .in-order-info {
        align-items: center;
        border-top: 1px solid #e8e8e8;
        display: flex;
        height: 73px;
        justify-content: flex-end;
        .in-history-action {
            margin-left: 32px;
            .in-btn a {
                background-color: #fff;
                border-color: #ff3200;
                border-radius: 4px;
                // border: 1px solid @main-btn-color;
                color: #ff3200;
                font-size: 20px;
                height: 40px;
                line-height: 40px;
                min-width: 160px;
            }
        }
        .in-order-total,.in-ship-to {
            margin-left: 16px;
            width: auto;
            span {
                color: #666666;
                font-size: 16px;
            }
        }
    }
    .in-order-number-area {
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        color: #bdbdbd;
        display: flex;
        font-size: 16px;
        height: 55px;
        justify-content: space-between;
    }
    .in-order-status-list {
        background-color: #fff;
        display: flex;
        margin-bottom: 16px;
        padding: 20px 24px;
        li {
            color: #999999;
            cursor: pointer;
            font-size: 16px;
            margin-right: 42px;
            &.active,&:hover {
                color: @main-btn-color;
                font-family: @font-medium;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        li.active,li:hover {
            color: #000;
        }
    }
    .in-order-no-data {
      text-align: center;
      background: #fff;
      padding: 200px 0 300px;
      img {
        width: 8rem;
      }
      p {
        font-size: .6rem;
        color: #000;
      }
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
    .no-data-area {
        text-align: center;
    }
}
