.in-delivery-tiem {
    background: #fff;
    > div {
        .make-row();
        > .in-five {
            .make-column(1/5, 0%);
        }
        > .in-foure {
            .make-column(1/4, 0%);
        }
        > .in-three {
            .make-column(1/3, 0%);
            position: relative;
            & .in-choose-time {
                align-items: center;
                background: @btn-base-color;
                border: none;
                color: #fff;
                display: flex;
                flex-direction: column;
                height: 18px;
                opacity: 0;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                width: 18px;
                & i {
                    .font-size-rem(14);          line-height: 18px;
                }
            }
        }
        > .in-two {
            .make-column(1/2, 0%);
        }
        > div {
            text-align: center;
        }
        div.active & .in-choose-time {
            opacity: 1;
        }
        span {
            .centered-line(55px);      color: #000;      .font-size-rem(14);      display: block;      border: 1px solid #e5e5e5;
        }
        > div:first-child {
            background: #f5f5f5;
        }
    }
    > div:first-child {
        background: #f5f5f5;
        & span {
            .centered-line(44px);
        }
    }
    span.active {
        border: 1px solid @btn-base-color;
    }
    .in-available {
        cursor: pointer;
        transition: all .5s;
        &:hover {
            box-shadow: 0 5px 10px #b5b3b3;
        }
    }
    .in-unavailable {
        background: #f5f5f5;
    }
}
