.in-checkout-index {
    margin-top: 20px;
    .in-item-wrapper {
        .padding-bottom-rem(50);
    }
    > .in-container {
        .margin-bottom-rem(118);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .in-cart-bar {
        border-top: 0;
        height: 160px;
        .in-btn-checkout a {
            background-color: #000;
            font-size: 18px;
            height: 47px;
            line-height: 1rem;
            width: 398px;
            padding: 14px 24px;
            border-radius: 4px;
        }
        .in-item-count {
            font-size: 16px;
            font-style: normal;
            height: 22px;
            line-height: 1em;
        }
        .in-total span {
            display: inline-block;
            &:first-child {
                color: #666;
                font-size: 16px;
                font-style: normal;
            }
            &:last-child {
                color: #ff3200;
                font-size: 38px;
                font-weight: normal;
            }
        }
    }
    .in-item-wrapper {
        border-radius: 4px;
        flex-grow: 1;
        padding-bottom: 0;
        width: auto;
        .in-address-title {
            position: relative;
            margin-bottom: 16px;
            i {
                color: #000;
                font-size: 24px;
                position: absolute;
                right: 26px;
                top: 17px;
                font-weight: bold;
            }
        }
        .in-shipAdd {
            align-items: center;
            border-top: 1px solid #e8e8e8;
            flex-direction: row;
            height: auto;
            margin-bottom: 0;
            margin-right: 0;
            padding: 24px;
            width: 100%;
            &.active {
                // border: 2px solid #ff6b00;
                .in-choose-area .in-choose-add {
                    background: #ff6b00;
                    border: 1px solid #ff6b00;
                }
            }
            &:hover {
                box-shadow: none;
            }
            .in-address-area {
                color: #000;
                flex-grow: 1;
                font-size: 16px;
                .in-name {
                    color: #000;
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                .in-tel {
                    font-size: 16px;
                    margin-top: 10px;
                }
            }
            .in-address-edit-area {
                flex-shrink: 0;
                text-align: center;
                width: 120px;
                .in-edit {
                    font-size: 24px;
                    i {
                        font-size: 24px;
                    }
                }
            }
            .in-choose-area {
                flex-shrink: 0;
                padding: 0 30px 0 10px;
                .in-choose-add {
                    background: #fff;
                    border-radius: 50%;
                    border: 1px solid #666666;
                    color: #fff;
                    height: 22px;
                    opacity: 1;
                    position: static;
                    width: 22px;
                    padding-right: 2px;
                    i {
                        font-size: 20px;
                        line-height: 1em;
                        font-weight: bold;
                    }
                }
            }
        }
        .in-shipAdd.active {
            border-color: #999;
            border-radius: 5px;
            .in-choose-area .in-choose-add {
                background: #000;
                border-color: #999;
            }
        }
        .in-shipping-area {
            margin-top: 20px;
            .in-shipMethods {
                align-items: center;
                display: flex;
                width: 100%;
            }
            .in-shipMethods-container {
                margin: 0;
                padding: 24px;
                &.active .in-shipping-choose::before {
                    display: inline-block;
                }
                &::before {
                    display: none;
                }
            }
            .in-shipping-choose {
                align-items: center;
                border-radius: 50%;
                border: 1px solid #dddddd;
                display: flex;
                flex-shrink: 0;
                height: 30px;
                justify-content: center;
                margin: 0 16px;
                width: 30px;
                &::before {
                    background-color: #dddddd;
                    border-radius: 50%;
                    content: " ";
                    display: none;
                    height: 18px;
                    width: 18px;
                }
            }
            .in-shipping-choose:before {
                background-color: #000;
            }
            .in-shipping-info-area {
                flex-grow: 1;
                .in-note {
                    color: #000;
                    font-size: 16px;
                }
                .in-title {
                    color: #000;
                    font-size: 16px;
                }
            }
            .in-shipping-price-area {
                flex-shrink: 0;
                margin-left: 16px;
                span {
                    color: #000;
                    font-size: 18px;
                    font-weight: normal;
                }
            }
        }
        > div {
            background-color: #ffffff;
        }
        h2 {
            margin: 0 17px;
            border-bottom: 1px solid #e8e8e8;
            color: #000;
            font-size: 16px;
            padding: 20px 0;
        }
    }
    .in-order {
        border-radius: 4px;
        flex-shrink: 0;
        margin-left: 20px;
        min-height: 802px;
        padding: 0;
        width: 398px;
        .in-esttimate-shipping-content {
            padding: 0 16px;
        }
        .in-group-order {
            .in-order-item {
                margin: 16px;
                padding: 0;
                .in-item-info {
                    padding-left: 12px;
                    padding-right: 0;
                    h6 {
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
                > img {
                    background-color: #f2f2f2;
                    height: 80px;
                    width: 80px;
                }
            }
            h5 {
                border: 0;
                margin-left: 16px;
            }
        }
        .in-order-details .in-title {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            margin: 24px 16px 0;
            padding: 0;
            i {
                font-size: 20px;
                line-height: 1em;
                margin-right: 16px;
                color: #000;
            }
            h3 {
              padding-left: 0;
            }
        }
        .in-totals-wrapper {
            background-color: #fff;
            border-color: #e8e8e8;
            margin: 0 16px;
            padding: 1rem 0;
            .in-label {
                color: #000;
                font-size: 16px;
                font-style: normal;
            }
            .in-value span {
                color: #000;
                font-size: 16px;
            }
            .in-value[code="grand_total"] span {
                color: #ff3200;
                font-family: @font-medium;
                font-size: 16px;
                font-weight: 500;
            }
            > ul {
                background-color: #f7f7f7;
                li {
                    align-items: center;
                    // border-top: 1px solid #e8e8e8;
                    padding: 12px 8px;
                    &:first-child {
                        border-top: 0;
                    }
                }
            }
        }
        .swiper-container {
            height: 380px;
            margin: 0 0 10px;
        }
        .swiper-container-vertical > .swiper-scrollbar {
            background: #ffffff;
        }
        .swiper-scrollbar-drag {
            background: #e3e3e3;
        }
        h3 {
            color: #000;
            font-size: 16px;
            padding: 13px 16px;
        }
    }
    > .in-container {
        align-items: flex-start;
        margin-bottom: 10rem;
    }
}
