.account-information-edit {
    .in-information-container {
        margin-bottom: 0;
        padding: 25px 30px 60px;
        .in-btn-col {
          width: 100%;
            padding: 60px 0 0;
            text-align: center;
            button {
                background: #000;
                border-radius: 6px;
                font-size: 16px;
                height: 45px;
                width: 400px;
            }
        }
        .in-checkbox-group {
            width: 100%;
            display: flex;
            margin-top: 8px;
            .in-common-checkout {
                margin-right: 24px;
                .in-checkbox-input {
                    align-items: center;
                    background-color: #f3f3f3;
                    border-radius: 2px;
                    display: inline-flex;
                    height: 16px;
                    justify-content: center;
                    line-height: 16px;
                    margin-right: 8px;
                    margin-top: 2px;
                    width: 16px;
                    i {
                        color: #f3f3f3;
                        font-size: 16px;
                    }
                }
                input[type="checkbox"] {
                    display: none;
                    &:checked + .in-checkbox-input {
                        background-color: @main-btn-color;
                    }
                }
                input[type="checkbox"]:checked + .in-checkbox-input {
                    background: #000;
                }
                label {
                    color: #aaa;
                    cursor: pointer;
                    display: flex;
                    font-size: 16px;
                }
            }
        }
        .in-form {
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
        }
        .in-input-col {
            width: 48%;
            padding-bottom: 16px;
            .in-input-control {
              position: relative;
              flex-direction: row;
              align-items: center;
              .is-danger {
                  font-size: 16px;
                  padding-left: 0;
              }
              input {
                  background-color: #f7f7f7;
                  border-radius: 0;
                  border: 0;
                  color: #333;
                  font-size: 16px;
                  height: 45px;
                  max-width: 800px;
                  width: 100%;
              }
              select {
                background-color: #f7f7f7;
                padding: 0 1rem;
                color: #333;
                font-size: 16px;
                height: 45px;
                max-width: 800px;
                width: 100%;
              }

              i.icon-arrow-down {
                position: absolute;
                right: 10px;
                font-size: 26px;
                color: #ccc;
                cursor: pointer;
                pointer-events: none;
              }
              .vdp-datepicker {
                width: 100%;
              }
            }
            label {
                color: #d0d0d0;
                display: inline-block;
                font-size: 16px;
                height: auto;
                line-height: 1em;
                margin-bottom: 8px;
                span {
                    display: inline-block;
                    line-height: 1em;
                }
            }
        }
    }
    .main-container {
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
    }
}
.in-information-content {
  .inventory-form.form-row .form-item {
      width: 100%;
  }
  .inventory-form.form-row .form-label {
      display: block;
  }
  .stock-low {
      font-size: 10px;
      color: green;
  }
  .stock-low-label .accordion-header .accordion-header-div {
      color: red;
 }
  .stock-low-label {
    .stock-low {
        color: red;
    }
  }
  .inventory-row {
    width: 50%;
    float: left;
    padding: 0 10px;
  }
  .inventory-row input {
    border: 1px solid #ccc;
    padding: 5px;
  }
  .inventory-row span {
      float: left;
      padding: 5px;
  }
}
