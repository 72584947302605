.in-net-goods-bill-container {
    .bill-info-table li {
        font-size: 22px;
        margin-bottom: 12px;
        span:first-child {
            color: #333;
            margin-right: 6px;
        }
        span:last-child {
            color: #666;
        }
    }
    .in-table > ul > li {
        .in-action-area {
            align-items: center;
            display: flex;
            justify-content: center;
        }
        span.in-action {
            background-color: #ffebdc;
            border-radius: 24px;
            border: 1px solid @main-btn-color;
            color: @main-btn-color;
            cursor: pointer;
            padding: 6px 24px;
            width: auto;
        }
    }
    .in-title {
        display: flex;
        justify-content: space-between;
        .go-back .xp-icon-account-back {
            color: #999999;
            cursor: pointer;
            font-size: 34px;
        }
        h5 {
            color: #333333;
            font-size: 16px;
            font-weight: normal;
        }
    }
}
.in-net-goods-container {
    margin: 0;
    padding: 32px 42px 60px;
    &.tip,.tip {
        .subTitle {
            border-bottom: none;
            line-height: 2;
            margin-bottom: 0;
            padding: 0;
        }
        .subTitle-tip {
            border-bottom: 1px solid #ccc;
            font-size: 12px;
            margin-bottom: 10px;
            padding: 0 0 10px;
        }
    }
    .in-bank .in-form {
        .in-btn-col {
            padding-bottom: 0;
            button {
                background-color: #000;
                border-radius: 6px;
                font-size: 16px;
                height: 45px;
                width: 400px;
            }
        }
        .in-input-col .in-input-control .help {
            display: block;
            font-size: 16px;
            height: auto;
            line-height: 1.2em;
            margin-left: 8px;
            margin-top: 0;
            padding-left: 0;
        }

        .in-input-col .in-input-control.select {
            position: relative;
        }

        .in-input-col .in-input-control select {
            background-color: #f7f7f7;
            padding: 0 1rem;
            color: #333;
            font-size: 16px;
            height: 45px;
            // max-width: 800px;
            width: 100%;
        }
        .in-input-col .in-input-control .icon-xiala1 {
            .centered-line(40px);
            .padding-right-rem(20);
            .font-size-rem(20);
            color: @base-font-color;
            position: absolute;
            right: 0;
            top: 3px;
        }
        .in-input-col .in-input-control  i.icon-arrow-down {
            position: absolute;
            right: 0px;
            font-size: 26px;
            color: #ccc;
            cursor: pointer;
            pointer-events: none;
        }
        input {
            background-color: #f7f7f7;
            border-radius: 0;
            border: 0;
            font-size: 16px;
            height: 45px;
            width: 100%;
        }
        label {
            color: #d0d0d0;
            display: inline-block;
            font-size: 16px;
            height: auto;
            line-height: 1em;
            margin-bottom: 8px;
        }
    }
    .in-share .in-form .in-btn-col button {
        background-color: #ff3200;
        border-radius: 6px;
        font-size: 28px;
        height: 58px;
        width: 400px;
    }
    .in-share__phone {
        margin: 20px 0 0;
        .go-back {
            color: #999;
            font-size: 14px;
            text-align: right;
            span {
                cursor: pointer;
                display: inline-block;
            }
        }
        img {
            max-width: 200px;
            min-height: 100px;
            min-width: 100px;
        }
        img:not[src] {
            visibility: hidden;
        }
        li {
            border-radius: 5px;
            border: 4px solid #ccc;
            cursor: pointer;
            margin-bottom: 20px;
            margin-right: 20px;
            &.active {
                border-color: @btn-base-color;
            }
            a {
                display: block;
                position: relative;
                &::after {
                    background: url("../images/download.png") no-repeat center;            width: 20px;            height: 20px;            background-size: cover;
                    bottom: 10px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: 10px;
                }
            }
        }
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    .net-container {
        margin-bottom: 50px;
    }
    .subTitle {
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        padding: 20px 0;
    }
}
.in-net-goods-orders-check .in-tag-container {
    cursor: pointer;
    flex-direction: row;
    margin: 0 0 30px;
}
.in-net-goods-share-container {
    margin: 0;
    padding: 32px 42px 60px;
    .in-table {
        .talbe-heade {
            background-color: #e8e8e8;
        }
        > ul > li {
            align-items: center;
            display: flex;
            height: 50px;
            &:last-child {
                border-bottom: 0;
            }
            > span {
                flex-basis: 0;
                flex-grow: 1;
                text-align: center;
            }
            > span.clospan-merge-4 {
                flex-grow: 5;
            }
        }
    }
    .netgoods-filter-container {
        align-items: flex-end;
        display: flex;
        margin-bottom: 16px;
        .field-row {
            margin-right: 15px;
            padding: 10px 0;
            button {
                background-color: #000;
                border-radius: 20px;
                font-size: 14px;
                border: 0;
                color: #fff;
                height: 45px;
                padding: 0 20px;
            }
            input {
                border-radius: 4px;
                border: 1px solid #e8e8e8;
                height: 45px;
                padding: 0 12px;
            }
            label {
                color: #666;
                display: block;
                font-size: 16px;
                margin-bottom: 8px;
            }
        }
    }
    .no-data {
        color: #666;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
}
.in-net-goods-user-container {
    margin: 0;
    padding-right: 3.5rem;
    padding: 32px 42px 60px;
    .in-table {
        .talbe-heade {
            background-color: #e8e8e8;
        }
        > ul > li {
            align-items: center;
            display: flex;
            height: 50px;
            &:last-child {
                border-bottom: 0;
            }
            > span {
                flex-basis: 0;
                flex-grow: 1;
                text-align: center;
            }
        }
    }
    .no-data {
        color: #666;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
    .subTitle {
        border-bottom: none;
    }
    > .subTitle {
        color: #333333;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 10px;
    }
    > .subTitle-tip {
        color: #666666;
        font-size: 24px;
        margin-bottom: 30px;
    }
}
.in-net-goods-click-container {
    .in-table > ul {
        width: 600px;
    }
    .no-data {
        width: 600px;
    }
}

.netgoods-filter-container.filter-col {
    flex-wrap: nowrap;
    margin-bottom: 26px;
    .field-row.field-col {
        align-items: flex-start;
        flex-direction: column;
        width: 20%;
        input {
            width: 100%;
        }
        label {
            margin-bottom: 8px !important;
        }
    }
    .field-row.field-col.btn-group-right {
        margin-right: 0;
        width: auto;
    }
    .in-dropdown-border {
        width: 100%;
        .in-dropdown-name {
            padding: 0;
            text-align: center;
            width: 100%;
        }
        .swiper-container {
            height: 280px;
        }
    }
    .vdp-datepicker {
        width: 100%;
    }
}
.netgoods-filter-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .field-row {
        align-items: center;
        display: flex;
        margin-right: 10px;
        & > label {
            color: #666;
            font-size: 16px;
            margin-right: 6px;
        }
        .action-button {
            background-color: #094;
            border-radius: 3px;
            border: 0;
            color: #fff;
            font-size: 16px;
            height: 40px;
            padding: 0 12px;
            &:hover {
                background-color: rgb(2, 170, 78);
            }
        }
        .vdp-datepicker input {
            font-size: 16px;
            height: 40px;
            padding: 0 6px;
            width: 200px;
        }
    }
}
