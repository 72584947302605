.in-billing-merchant {

  font-family: 'DINPro-Regular';
  width: 80%;
  background-color: white;
  padding: 1rem;
  font-size: .8rem;


  .top-header {
    display: flex;
    width: 100%;
    min-height: 5rem;
    align-items: center;
    //justify-content: space-between;
    gap: .6rem;
    margin-bottom: 1rem;
  }

  .top-header .container {
    text-align: center;
    //border: 2px solid lightgray;
    border-radius: 5px;
    flex: 1;
    height: 100%;
    max-width: 19rem;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }

  .top-header .container:nth-child(1) {
    border: 1px solid #CC3636;
    background-color: #FAEBEB;
  }

  .top-header .container:nth-child(2) {
    border: 1px solid #08A932;
    background-color: #E6F6EA;
  }

  //.top-header .container:hover {
  //  background-color: lightgray;
  //}

  .top-header .container .title {
    font-size: 2.4rem;
    padding-block: .6rem;
  }

  .top-header .container .desc {
    padding-block: .24rem;
    color: #FFFFFF;
  }

  .top-header .container:nth-child(1) .desc {
    background-color: #CC3636;
  }

  .top-header .container:nth-child(2) .desc {
    background-color: #06912B;
  }

  /* Nav Content */

  .nav-content {
    width: 100%;
    //height: 2.8rem;
    //border: 2px solid lightgray;
    border-radius: 5px 5px 0 0;
    //border-top: 4px solid black;
    //margin-top: 1rem;
    display: flex;
    align-items: center;
    //box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    background-color: #383838;
    color: white;
    padding: .6rem .7rem;
  }

  .nav-content-right {
    display: flex;
    align-items: center;
    //margin-left: 1rem;
  }

  .nav-content-status {
    text-align: center;
    line-height: normal;
    margin-right: .4rem;
  }

  .nav-content-status-data {
    display: flex;
    border-radius: .2rem;
    //margin-left: 1rem;
    cursor: pointer;
    //border: 2px solid lightgray;
    width: 5rem;
    height: 1.45rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: black;
    margin-right: 1rem;
  }

  .nav-content-status-data:hover {
    background-color: #ffffff;
    //border-color: #00F661;
    color: black;
  }

  .nav-content-active {
    background-color: #ffffff;
    //border-color: #00F661;
    color: black;
  }

  .circle-nav-content-status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: .5rem;
  }

  .circle-open {
    background-color: #666666;
  }

  .circle-all {
    background-color: #000000;
  }

  .circle-unpaid {
    background-color: #eb5202;
  }

  .circle-paid {
    //background-color: #00f661;
    background-color: #58d5e0;
  }

  /* Content */

  .main-content {
    margin-top: 1rem;
    //height: 73vh;
    height: 80vh;
    display: flex;
    justify-content: space-between;
    /*border: 2px solid lightgray;*/
    /*box-shadow: #666666;*/
  }

  .content-list {
    position: relative;
    height: 100%;
    width: 40%;
    //border: 2px solid #ededed;
    border-radius: 5px;
    //box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

  }

  .data-list-container {
    //height: 92%;
    height: 88%;
    overflow-y: scroll;
  }

  .content-detail {
    height: 100%;
    width: 58%;
    //border: 2px solid #ededed;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    //box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
  }

  .content-list-header {
    width: 100%;
    background-color: #000000;
    //height: 8%;
    z-index: 1;
    display: flex;
    align-items: center;
    color: #ffffff;
    justify-content: space-between;
  }

  .content-list-header .text {
    //margin-left: 1rem;
    //margin-right: 1rem;
    padding: 1rem 0;
    text-align: center;
  }

  .content-list-header .text:nth-child(1) {
    width: 68%;
  }

  .content-list-header .text:nth-child(2) {
    background-color: #262626;
    width: 32%;
  }


  /*.content-list div:nth-child(2) {*/
  /*    margin-top: 10%;*/
  /*}*/

  .data-list {
    //height: 5rem;
    border: 1px solid #D9D9D9;
    //padding: 1.25rem;
    justify-content: space-between;
    display: flex;
    cursor: pointer;
    background-color: #F3F3F3;
  }

  .data-list:hover {
    background-color: white;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .data-list-detail-container {
    display: flex;
    align-items: center;
  }

  .data-list-detail-container img {
    width: 1.5rem;
    height: 1.5rem;
    /*margin-left: 1rem;*/
    margin-right: 1rem;
  }

  .data-date {
    margin-bottom: 0.4rem;
  }

  .data-list-detail-account {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 68%;

    .text.bold {
      background-color: #000;
      color: #FFFFFF;
      padding: 1rem;
    }

    .data-list-detail-container {
      padding: 1rem;

      .data-content {
        display: flex;
        flex-direction: column;
        gap: .3rem;
      }

      img {
        width: 2rem;
        height: 2rem;
        margin-right: .4rem;
      }

      .clipboard {
        background-image: url("../images/clipboard.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 0.7rem;
        height: 0.7rem;
        margin-right: .2rem;
      }

      .data-date {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        min-width: max-content;
      }

      .text.id {
        color: black;
      }

      .circle-nav-content-status {
        margin-right: .2rem;
      }
    }

    .color-select {
      padding: .4rem 1.6rem !important;
      background-color: black;
      color: #FFFFFF;
      text-decoration: none;
      border-radius: .2rem;
      margin-block: auto;
    }
  }

  .order-summary {
    border-radius: .2rem;
    width: 100%;
    overflow: hidden;

    .data-list-detail-container {
      background-color: #F3F3F3;
    }
  }

  .details.details-group {
    max-width: 95%;
  }

  .details.details-group .order-list {
    margin-top: 1rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: .7rem;

    li .details-list, li .details-list-multiple {
      padding: .3rem .7rem;
      border-radius: .2rem;
    }

    li:nth-child(1) .details-list {
      background-color: #E6F6EA;
      color: #08A932;

      .text.bold.label {
        display: flex;
        align-items: center;
        position: relative;
        gap: 10px;

        .tooltip {
          position: relative;
          margin-left: -6px;
          display: flex;
          align-items: center;
          width: max-content;

          .tooltip-icon {
            background-image: url("../images/question-mark.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            cursor: pointer;
          }

          .tooltip-text {
            position: absolute;
            width: 181px;
            padding: 6px 11px 6px 14px;
            font-size: 13px;
            top: -50%;
            left: 20px;
            -webkit-transition: opacity .5s ease-in-out;
            transition: opacity .5s ease-in-out;
            background-image: url('../images/tooltip-bg.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            opacity: 0;
            height: 40px;
          }
        }
      }
    }

    li:nth-child(3) .details-list-multiple {
      //color: #CC3636;
      margin-top: -.5rem;
      margin-left: .5rem;

      .details-list {
        margin-left: -.7rem;
      }

      ul {
        padding: 0;
      }
    }

    li:nth-child(2) .details-list {
      background-color: #FAEBEB;
      color: #CC3636;
    }

    li:nth-child(4) .details-list {
      background-color: #cdcdcd;
      color: #000;
      //padding-left: 1.35rem;
    }

    .details-fee {
      align-items: center;
      height: 1.4rem;
    }
  }

  .data-list-detail-container .text {
    display: flex;
    align-items: center;
    margin-right: .8rem;
  }

  .data-list-detail-container .id {
    color: #8D8D8D;
  }

  .data-list-payout {
    display: flex;
    align-items: center;
    width: 32%;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  .data-list-payout::before {
    content: '';
    position: absolute;
    left: .5rem;
    width: 2px;
    background-color: #D9D9D9;
    height: 4rem;
  }

  .text-container {
    //border-radius: 5px;
    //border: 2px solid #ededed;
    //padding: .2rem .5rem;
    //color: #8D8D8D;
    //margin-right: 1rem;
    text-align: center;
  }

  .details {
    display: flex;
    //padding: 1rem;
    //border-bottom: 2px solid #ededed;
    justify-content: space-between;
  }

  .content-detail .btn-download {
    height: 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .down-position {
    margin-top: auto;
    border-top: 2px solid #ededed;
  }

  .details-group {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
  }

  .details-group .details {
    border-bottom: none;
    padding-right: unset;
    padding-left: unset;
  }

  .color-main {
    background-color: black;
  }

  .color-secondary {
    background-color: #ffdc73;
    border-color: #ffdc73;
  }

  .color-select {
    background-color: #00F661;
    border-color: #00F661;
  }

  ul {
    margin: 0.75em 0;
    padding: 0 1em;
    list-style: none;
  }

  .arrow:before {
    content: "";
    border-color: transparent black;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative;

  }

  .details-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      margin-right: auto;
    }

    .dot {
      width: 7px;
      height: 7px;
      margin-right: 6px;
      border-radius: 50%;
    }

    .dot-red {
      background-color: #CC3636;
    }
    .dot-green {
      background-color: #08A932;
    }

    .dot-black {
      background-color: #000000;
    }
  }

  .order-list .sub-total {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  .sub-total-content {
    text-align: end;
  }

  .export-btn {
    margin-top: 1rem;
    width: 10rem;
    height: 3rem;
    background-color: #00F661;
    border-radius: 5px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
  }

  .transaction-exports {

    border-radius: 5px;
    border: 2px solid #ededed;
    padding: 1rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

  }

  .transaction-exports .title {
    font-size: 1.3em;
    margin-bottom: 1rem;
  }

  .table {
    width: 100%;
    text-align: start;
    margin-bottom: 1rem;
  }

  .table th {
    text-align: start;
    height: 2rem;
  }

  .table td {
    border-bottom: 2px solid #ededed;
    height: 2rem;
  }

  .btn-download {
    border-radius: 25px;
    cursor: pointer;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .w-md {
    width: 4.8rem;
  }

  .mr-1 {
    margin-right: 1rem;
  }

  .align-right {
    margin-left: auto;
  }

  .details-fee {
    display: flex;
    justify-content: space-between;
  }

  //.details-fee div:first-child:before {
  //  content: "";
  //  background-color: black;
  //  border-style: solid;
  //  border-radius: 50%;
  //  display: block;
  //  height: 0;
  //  width: 0;
  //  left: -1em;
  //  top: 0.9em;
  //  position: relative;
  //}

  //.details-fee-total {
  //  text-align: end;
  //}

  .active-white{
    background-color: #fff;
  }

}
