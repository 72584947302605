.catalog-product {
  background-color: #ffffff;
  .in-header-menu {
      border-bottom: 1px solid #e7e7e7;
      box-shadow: none;
  }
  .in-service-list {
      box-shadow: 0px 2px 19px 5px rgba(0, 0, 0, 0.08);
  }

  .discount {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .discount-title {
      display: inline-block;
      width: 3.4rem;
      font-size: 15px;
      color: #111;
      font-weight: normal;
    }

    .coupon {
      padding: 2px 8px;
      margin-right: 20px;
      font-size: 12px;
      color: #FF3200;
      border: 1px solid #FF3200;
      cursor: pointer;
    }

    .learn-more {
      font-size: 12px;
      color: #216AFE;
      margin-left: 30px;
      text-decoration: underline;
    }
  }

  .select-discount-modal {
    position: relative;
    padding: 30px 40px;
    text-align: center;

    .points__container {
      position: relative;
    }

    .obtained {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      padding: 3px 8px 4px 10px;
      font-size: 10px;
      color: #6C6C6C;
      font-weight: 600;
      background-color: #E3E3E3;
      border-radius: 0 0 0 4px;
    }

    .modal-title {
      font-size: 16px;
      margin-bottom: 40px;
    }

    .close-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 20px;
      top: 20px;
      width: 16px;
      height: 16px;
      padding-top: 2px;
      border-radius: 50%;
      background-color: #000;
      color: #fff;
      cursor: pointer;

      i {
        font-size: 14px;
      }
    }

    .points__item {
      width: 100%;
      height: 90px;
      border: none;
      padding: 8px 15px;
      margin-bottom: 15px;
      cursor: inherit;

      &::before,&::after {
        height: 20px;
        width: 20px;
        top: 55px;
      }

      .points__content {
        position: relative;
      }

      .points__quota {
        font-size: 14px;
        color: #111;
        font-weight: bold;
        line-height: 17px;
        margin: 0 0 4px 0;
      }

      .get-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 12px;
        color: #FF3200;
        background-color: #fff;
        padding: 4px 13px;
        border: 1px solid #FF3200;
        border-radius: 13px;
        cursor: pointer;
      }

      .points__date {
        font-size: 12px;
        line-height: 17px;
        color: #999;
      }

      .points__split-line {
        margin: 10px 0 6px;
        border-bottom: 1px dashed #C4C4C4;
      }

      .points__action {
        justify-content: center;
      }

      .points__need {
        margin: 0;
        font-size: 12px;
        line-height: 17px;
        color: #111;
      }
    }
  }
}
