.in-blog-list {
    .clearfix();
    .margin-bottom-rem(50);
    .margin-top-rem(50);
    width: 100%;
    .in-blog-left {
        float: left;
        width: 68%;
        & .in-blog {
            .margin-bottom-rem(20);
            background: #fff;
            display: flex;
            flex-direction: row;
            transition: all .5s;
            .in-blog-short-info {
                .padding-rem(48);        text-align: left;        width: 50%;
                display: flex;
                flex-direction: column;
            }
            > a {
                display: block;
                height: 260px;
                width: 50%;
                & img {
                    height: 260px;
                }
            }
            h6 {
                .font-size-rem(26);        color: #000;        .margin-bottom-rem(20);        overflow: hidden;        text-overflow:ellipsis;        white-space: nowrap;
            }
            p {
                .font-size-rem(14);        color: #000;        line-height: 24px;        text-overflow:ellipsis;        overflow: hidden;        max-height: 96px;
            }
            &:hover {
                box-shadow: 0 0 30px #b5b3b3;
            }
        }
    }
    .in-blog-topic {
        background: #fff;
        float: left;
        margin-left: 4%;
        padding: 30px;
        text-align: left;
        width: 28%;
        > h6 {
            .font-size-rem(18);      color: @base-font-color;      .padding-top-rem(20);      .padding-bottom-rem(20);      overflow: hidden;      text-overflow:ellipsis;      white-space: nowrap;
        }
        ul {
            display: flex;
            flex-direction: column;
            & li {
                .padding-bottom-rem(18);
                .padding-top-rem(18);
                align-items: center;
                border-top: 1px solid #eeeeee;
                display: inline-flex;
                flex-direction: row;
                a {
                    display: block;
                    height: 60px;
                    width: 30%;
                    & img {
                        height: 60px;
                    }
                }
                h6 {
                    .padding-rem(10);
                }
            }
        }
    }
}
.in-blog-list-container {
    text-align: center;
    & .in-ads-title {
        .padding-top-rem(50);
    }
}
