.in-pageation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 100px 0;
    .in-pageation-btn {
        cursor: pointer;
        display: inline-block;
        position: relative;
        & span {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);      white-space: nowrap;      color: @base-font-color;      .font-size-rem(14);
        }
    }
    .last-page {
        &::before {
            border-bottom: 20px solid transparent;
            border-right: 20px solid #fff;
            border-top: 20px solid transparent;
            content: ' ';
            display: inline-block;
            height: 0;
            width: 0;
        }
        &:after {
            background: #fff;
            content: ' ';
            display: inline-block;
            height: 40px;
            width: 150px;
        }
    }
    .next-page {
        &::before {
            background: #fff;
            content: ' ';
            display: inline-block;
            height: 40px;
            width: 150px;
        }
        &:after {
            border-bottom: 20px solid transparent;
            border-left: 20px solid #fff;
            border-top: 20px solid transparent;
            content: ' ';
            display: inline-block;
            height: 0;
            width: 0;
        }
    }
    a.in-pageation-btn {
        background: #fff;
        height: 40px;
        text-align: center;
        width: 170px;
    }
}
.in-pagination-wrapper {
    .padding-bottom-rem(50);
    .padding-left-rem(20);
    .padding-right-rem(20);
    .padding-top-rem(50);
    text-align: right;
    .in-next {
        .centered-line(40px);
        .font-size-rem(14);
        .padding-left-rem(20);
        .padding-right-rem(20);
        border-radius: 100px;
        border: 1px solid #cccccc;
        color: #666666;
        display: inline-block;
        & i {
            vertical-align: middle;
        }
        i {
            display: inline-block;
            transform: rotate(-90deg);
        }
    }
    .in-previous {
        .centered-line(40px);
        .font-size-rem(14);
        .padding-left-rem(20);
        .padding-right-rem(20);
        border-radius: 100px;
        border: 1px solid #cccccc;
        color: #666666;
        display: inline-block;
        & i {
            vertical-align: middle;
        }
        i {
            display: inline-block;
            transform: rotate(90deg);
        }
    }
    .isDisabled {
        color: #cccccc;
    }
    ul {
        display: inline-flex;
        flex-direction: row;
        .active {
            color: @btn-base-color;
        }
        li {
            a,span {
                .padding-left-rem(10);      .padding-right-rem(10);      .centered-line(40px);      .font-size-rem(14);      color: #000;
            }
        }
    }
}

.paginate {
    display: flex;
    justify-content: flex-end;
    .active {
        border-color: #ff6b00;
        color: #000;
    }
    .disabled {
        color: #ccc;
    }
    .next {
        a {
            padding: 7px 10px;
        }
        i {
            display: inline-block;
            transform: rotate(-90deg);
        }
    }
    .prev {
        a {
            padding: 7px 10px;
        }
        i {
            display: inline-block;
            transform: rotate(90deg);
        }
    }
    li {
        margin: 0 4px;
        a {
            border-radius: 2px;
            border: 1px solid #ccc;
            display: inline-block;
            font-size: 14px;
            padding: 8px 10px;
        }
    }
}
.paginate-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    .total {
        font-size: 14px;
        margin-right: 10px;
    }
    .paginate {
      .active {
        a {
          background: #000;
          border-color: #000;
          color: #fff;
        }
      }
    }
}
.panel-action .budge {
    background: #ccc;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    margin: 0 20px;
    padding: 2px 10px;
}
.panel-default {
    background: #fff;
    overflow: hidden;
    padding: 24px;
    &.panel-small .subTitle {
        border-bottom: 1px solid #e0e0e0;
        font-size: 24px;
        margin-top: -24px;
        margin: 0;
        padding: 22px 0;
    }
    + .panel-default {
        margin-top: 24px;
    }
    .action-btn {
        color: #666666;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        + .action-btn {
            margin-left: 40px;
        }
    }
    .info {
        align-items: center;
        display: flex;
        .iconfont,.xp-iconfont {
            color: #999999;
            display: inline-block;
            font-size: 54px;
            margin-right: 8px;
        }
        .info-text {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .panel-action {
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        margin: 0 -24px;
        padding: 15px 24px;
        .icon-arrow-down {
            transform: rotate(90deg);      display: inline-block;
        }
    }
    .panel-body-row {
        display: flex;
        justify-content: space-between;
        padding: 26px 0;
    }
    .subTitle {
        color: #333;
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 10px;
        margin-top: -24px;
        padding: 20px 0;
    }
}
.pick-up-dropdown {
    &.in-dropdown-content {
        width: 225%;
        &::before {
            left: 15%;
        }
        .swiper-container {
            background: none;
            box-shadow: none;
            height: 270px;
        }
    }
    .in-dropdown-items {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 12px 42px 0 rgba(38,38,38,.24);
    }
}
.pick-up-stock {
    display: inline-block;
    color: #ff3200;
    font-size: .8rem;
    margin-left: .5rem;
}
.points__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.points__item {
    background: #f0f0f0;
    border-radius: 4px;
    border: 2px solid #fff;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    height: 180px;
    margin-bottom: 40px;
    overflow: hidden;
    padding: 15px;
    position: relative;
    width: 46%;
    &::after {
        background: #fff;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 50px;
        position: absolute;
        right: 0;
        top: 106px;
        transform: translate(50%, -50%);
        width: 50px;
    }
    &::before {
        background: #fff;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 50px;
        left: 0;
        position: absolute;
        top: 106px;
        transform: translate(-50%, -50%);
        width: 50px;
    }
    .points__action {
        align-items: center;
        display: flex;
        justify-content: space-between;
        &.center {
            justify-content: center;
        }
    }
    .points__date {
        font-size: 14px;
        text-align: center;
    }
    .points__disable {
        background: #fff;
        height: 10px;
        left: 0;
        position: absolute;
        right: 0;
        transform: rotate(45deg);    transform-origin: 50%;    top: 50%;    margin-top: -5px;
    }
    .points__need {
        font-size: 14px;
    }
    .points__quota {
        font-size: 18px;
        font-weight: bold;
        margin: 25px 0 20px;
        text-align: center;
    }
    .points__split-line {
        border-bottom: 1px dashed #333;
        height: 0;
        margin: 15px 0;
    }
    .points__desc {
      text-align: center;
      padding: 0 12px;
      .description {
        font-size: 12px;
        line-height: 1.2;
      }
    }
}
.points__item.active {
    border: 2px solid #ff6b00;
}
.pp-orders-list {
    margin-top: 0 !important;
    .in-items-ordered {
        padding: 0 0 24px;
        + .in-items-ordered {
            border-top: 1px solid #e8e8e8;
        }
    }
}
.pp-product-create .steps-container {
    max-width: 70%;
    display: flex;
}
.pp-product-three .accordion {
    margin-top: 20px;
}
.pp-product-two .upload__container {
    display: inline-block;
}
.pp-shipments {
    .accordion {
        border: 0;
        margin: 0;
    }
    .panel-body-row {
        padding: 0;
    }
}
.pp-shop-main {
    .in-net-goods-user-container .in-table > ul > li > span {
        width: 100% / 6;
        word-break: break-word;
    }
}
.preview-img__box {
    display: flex;
    margin: 20px 0;
    a {
        border-radius: 5px;
        border: 4px solid #ccc;
        max-width: 200px;
        + a {
            margin-left: 20px;
        }
    }
}
.previewImg {
    display: flex;
    flex-direction: row;
    & li {
        .margin-right-rem(20);    border: 1px solid @base-font-color;    background-position: center;    background-repeat: no-repeat;    background-size: cover;
        border-radius: 5px;
        height: 68px;
        overflow: hidden;
        width: 68px;
    }
}
.primary-btn {
    // background-color: #ff6b00;
    background-color: #000;
    border-radius: 24px;
    border: 0;
    color: #fff;
    height: 45px;
    padding: 0 24px;
    & + & {
        margin-left: 14px;
    }
    &:focus {
        outline: none;
    }
}
.primary-color,.telephone {
    color: #333;
}
.recommend__products {
    margin: 50px 0;
    overflow: hidden;
    .in-ads-product .in-product .in-ads-product-info {
        .in-ads-product-name span {
            font-size: 13px;
        }
        .in-ads-product-price {
            .in-price-final,.in-price-max span {
                font-size: 15px;
            }
            .in-price-max.final span {
                font-size: 13px;
            }
        }
    }
    .in-ads-title {
        padding: 0;
        h2 {
            font-size: 26px;
        }
    }
}
.red {
    border: 1px solid red;
}
.register-linked {
    word-break: break-word;
}
.rest-password__notice {
    color: #5d5e5e;
    font-size: 16px;
    margin: 2px 0;
    a {
        color: #000;
        font-weight: bold;
    }
}
.result-list {
    margin: 10px 0;
    word-wrap:break-word;
    &__item {
        display: flex;
        font-size: 16px;
        line-height: 1.2;
        padding: 4px 0;
        text-align: left;
        .label {
            color: #888888;
            width: 25%;
        }
        .value {
            width: 75%;
        }
    }
}
.result-tips {
    color: #ff3200;
    font-size: 16px;
    line-height: 1.2;
    word-wrap: break-word;
}
.return-point {
    font-size: 16px;
}
.reviewsImg {
    > div {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
    i {
        .font-size-rem(68);    cursor: pointer;    color: @base-font-color;
    }
}
.right {
    text-align: right;
}
.rma-action {
    justify-content: flex-end !important;
}
.search-select-result {
    padding-bottom: 50px;
    &__item {
        background: #EEEEEE;
        border-radius: 4px;
        border: 1px solid transparent;
        color: #7C7C7C;
        cursor: pointer;
        font-size: 14px;
        line-height: 1.4;
        margin: 10px;
        padding: 10px;
    }
}
.search-select-selected {
    background: #e3e3e3;
    border-radius: 4px;
    border: 1px solid #000;
    color: #666;
}
.share-btn-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 20px;
    & >a {
        color: #333;
        width: auto;
        margin-right: 10px;
        + a {
            margin-left: 40px;
        }
        i {
            color: #3B5998;
            font-size: 12px;
            img {
              width: 16px;
            }
        }
    }
    span {
        vertical-align: middle;
    }
    .addthis_inline_share_toolbox {
      a {
        padding:0;
        .at-icon-wrapper {
            line-height: 26px !important;
            width: 26px !important;
            height: 26px !important;
            svg {
                width: 26px !important;
                height: 26px !important;
            }
        }
        .at-label {
            line-height: 26px !important;
            height: 26px !important;
        }
        .at4-share-count-container {
          line-height: 26px !important;
        }
      }
    }
}
.share__icon {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1px;
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    width: 18px;
}
.ship-info {
    .icon-arrow-down {
        display: inline-block;
        transform: rotate(-90deg);
    }
    li {
        font-size: 16px;
        padding: 10px 0;
    }
}
.ship-product-list {
    list-style: disc;
    padding-left: 66px;
    .name,.qty {
        display: block;
        font-size: 14px;
        line-height: 1.2;
    }
    li {
        width: auto;
    }
}
.split-line {
    margin-bottom: 10px;
    margin-left: -20px;
    .comment__text {
        display: inline-block;
        font-size: 12px;
        line-height: 1.4;
        padding: 0 20px;
        + .comment__text {
            border-left: 1px solid #666;
        }
    }
}
.status-qty + .status-qty {
    margin-top: 4px;
}
.suc-confirm {
    border-top: 1px solid #eee;
    padding: 48px 0;
    text-align: center;
    & .in-btn a {
        .font-size-rem(14);
    }
    .in-btn a {
        background-color: #000;
    }
}
.sucTips {
    .font-size-rem(14);  color: #1e7415;  line-height: 40px;  border: 1px solid #5ed03b;  background: #f4fff0;  padding: 0 20px;  margin: 30px 0;
}
.tips {
    color: #bebebe;
    font-size: 14px;
    margin: 20px 0;
}
.title-line {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 30px;
    padding: 20px 0 30px;
    text-align: center;
    h2 {
        color: #333;
        font-size: 16px;
        font-weight: 400;
    }
}
.total .grand_total:last-child {
    .font-size-rem(16px);
}
.unbind-container {
    & + & {
        border-top: 1px solid #ccc;
        padding: 30px 0;
    }
    .title {
        font-size: 18px;
        margin-bottom: 10px;
    }
}
.unified-revision {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
    .in-btn button {
        margin-top: 28px;
        width: 110px;
    }
}
.upload-image {
    &-container {
        border: 1px solid #e0e0e0;
        margin-bottom: 10px;
        padding: 10px;
    }
    &-head {
        font-size: 16px;
        margin: 10px 0;
    }
}
.upload__btn {
    align-items: center;
    background-color: #f3f3f3;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    color: #bebebe;
    cursor: pointer;
    display: flex;
    height: 108px;
    justify-content: center;
    margin-bottom: 20px;
    padding: 20px;
    width: 108px;
    i {
        font-size: 3.4rem;
    }
}
.upload__container {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.used-coupon-card .points__item {
    border: 2px solid #ff6b00;
    cursor: initial;
    margin-bottom: 0;
    min-width: 300px;
    overflow: initial;
    position: relative;
    width: 30%;
    .xp-icon-close {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
.vdp-datepicker__calendar header {
    position: initial;
}
.xclubEvaluation {
    position: relative;
    &::before {
        background-image: url("../images/XClub-Unboxing.png");    background-repeat: no-repeat;    background-size: cover;    background-position: center;    position: absolute;    top: -29px;
        content: "";
        display: inline-block;
        height: 45px;
        width: 210px;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(0, 20%);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
div.time-ticker-container {
    display: inline-block;
    position: relative;
    &:hover span.tick-message {
        display: block;
    }
    span.tick-message {
        background: white;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 5px #ccc;
        color: #666;
        display: none;
        font-size: 14px;
        left: -200px;
        left: 0;
        line-height: 20px;
        padding: 10px;
        position: absolute;
        top: 24px;
        width: 500px;
        z-index: 999;
    }
    span.time-ticker {
        border-radius: 5px;
        border: 1px #333 solid;
        cursor: pointer;
        font-size: 10px;
        font-weight: bold;
        padding: 2px 5px;
        &.green {
            border-color: green;
            color: green;
        }
        &.red {
            border-color: red;
            color: red;
        }
        &.yellow {
            border-color: #ff6b00;
            color: #ff6b00;
        }
    }
}
