.in-order-details {
    .active {
        transform: rotate(180deg);
    }
    .icon-arrow-down {
        transition: all .5s;
    }
    .in-group-order {
        padding-right: 10px;
        & h5 {
            .font-size-rem(12);      line-height: 24px;      border-bottom: 2px solid #eeeeee;      color: @base-font-color;
        }
    }
    .in-item-attr {
        .font-size-rem(12);    color: @base-font-color;    font-style: italic;
    }
    .in-item-info {
        .padding-left-rem(15);    .padding-right-rem(30);
    }
    .in-order-item {
        .padding-top-rem(10);    .padding-bottom-rem(10);    align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .in-swiper {
        .margin-top-rem(10);    .margin-bottom-rem(10);
    }
    .in-title {
        .padding-bottom-rem(30);
        .padding-top-rem(50);
        border-bottom: 1px solid #eeeeee;
        color: @base-font-color;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        h3 {
            .font-size-rem(18);      font-weight: 500;
        }
        i {
            cursor: pointer;
            line-height: 23px;
        }
    }
    .swiper-container {
        height: 210px;
        width: 100%;
    }
    .swiper-slide {
        height: auto;
    }
    h6 {
        .font-size-rem(14);    color: #000;
    }
    img {
        height: 50px;
        width: 50px;
    }
}
