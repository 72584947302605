.in-simple-info {
    background: #fff;
    height: 220px;
    width: 100%;
    .in-info {
        .margin-bottom-rem(20);
        .padding-left-rem(70);
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 220px;
        .in-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 94px;
            width: 94px;
            border-radius: 50%;
            background: #e3e3e2;
            margin-right: 2rem;
        }
        .in-infoma {
          h5 {
            font-size: .8rem;
            font-weight: normal;
          }
          p {
            font-size: .8rem;
          }
        }

    }
    h5 {
        .padding-top-rem(10);    .padding-bottom-rem(10);    .font-size-rem(24);
    }
    p {
        .font-size-rem(18);
    }
}
