.in-history-container {
    flex: 1;
    height: auto;
    width: 75%;
    .in-history-action {
        .in-btn {
            a {
                .centered-line(30px);      .font-size-rem(12);      background: none;      border: 1px solid #e6e6e6;      color: @base-font-color;      transition: all .35s;
                width: 110px;
            }
            a:hover {
                background: @btn-base-color;
                border: 1px solid transparent;
                color: #fff;
            }
        }
        .in-status {
            .font-size-rem(16);
        }
        > div       > .in-btn,span {
            .padding-top-rem(5);      .padding-bottom-rem(5);
        }
    }
    .in-history-item {
        .margin-bottom-rem(30);
        .margin-top-rem(30);
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        position: relative;
        .crowd-founding {
            position: absolute;
            right: 0;
            top: 16px;
            padding: 2px 5px;
            border-radius: 5px;
            background: #ff3200;
            color: white;
            font-weight: bold;
        }
        .in-info {
            .padding-left-rem(10);
            h6 {
                .padding-bottom-rem(5);        .font-size-rem(14px);        color: #000;
            }
            span {
                .font-size-rem(12);
                color: @base-font-color;
            }
        }
        .in-ordered-qty {
            .padding-right-rem(30);
            & span {
                .font-size-rem(14);
                display: inline-block;
                font-style: italic;
            }
        }
        > div:first-child {
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        img {
            width: 70px;
        }
    }
    > .in-history {
        transition: all .5s;
        &:hover {
            box-shadow: 0 0 30px #b5b3b3;
        }
    }
    table {
        .margin-bottom-rem(20);
        background: #fff;
        width: 100%;
        tbody tr {
            td {
                border-bottom: 1px solid #fff;
            }
            td:first-child {
                .padding-left-rem(30);        width: 50%;
            }
        }
        thead tr td > span {
            .padding-left-rem(30);
            .padding-right-rem(30);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            & span {
                .centered-line(40px);        .font-size-rem(14px);        color: @base-font-color;
            }
        }
    }
    td,tr {
        border: 1px solid #eee;
    }
    .in-history-action > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .in-order-total,.in-ship-to {
        width: 15%;
        > div {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
        }
        span {
            .font-size-rem(14);    .padding-top-rem(5);    .padding-bottom-rem(5);    text-align: center;
            color: @base-font-color;
        }
    }
}
