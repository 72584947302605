.in-specs {
    width: 100%;
    li span {
        .font-size-rem(16);    line-height: 32px;
    }
    ul {
        .padding-bottom-rem(40);
        .padding-top-rem(40);
        border-bottom: 1px solid #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        li:first-child {
            width: 25%;
        }
        li:last-child {
            width: 75%;
            & > div {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                span:first-child {
                    width: 30%;
                }
                span:last-child {
                    width: 70%;
                }
            }
        }
    }
}
