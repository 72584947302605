.merchant-register {
  .content-wrapper {
    width: 100%;
    padding-block: 80px 32px;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .pre-action-text {
      font-size: 20px;
      color: #737373;
    }

    .title {
      font-size: 32px;
      margin-block: 15px 24px;
    }

    .sub-title {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 15px;

      .tooltip {
        position: relative;
        margin-left: 3.2px;
        display: flex;
        align-items: center;
        width: max-content;

        .question-mark {
          background-image: url("../images/question-mark.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .tooltip-text {
          position: absolute;
          width: max-content;
          padding: 6px 11px 6px 14px;
          //border-radius: 7px 7px 7px 0;
          font-size: 13px;
          //box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
          top: -50%;
          left: 20px;
          transition: opacity .5s ease-in-out;
          background-image: url("../images/tooltip-bg.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent;
          opacity: 0;
        }
      }
    }

    .form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        font-size: 15px;
        line-height: 30px;
      }

      .input-control {
        width: 100%;
        max-width: 520px;
        padding: 13px 16px;
        font-size: 15px;
        border-radius: 4px;
        border: 1px solid black;
      }

      .checkbox-col {
        display: flex;
        align-items: center;
        gap: 35px;

        label {
          line-height: 1;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
        }

        .in-checkbox-input {
          margin-left: 8px;
        }
      }

      select, .btn {
        cursor: pointer;
      }

      .btn, .btn-disable {
        width: 100%;
        max-width: 520px;
        padding: 9px 18px;
        border: none;
        border-radius: 6px;
        font-size: 16px;
        line-height: 30px;
        background: black;
        color: white;
      }

      .btn-disable {
        background-color: #6B6B6B;
        cursor: not-allowed;
      }

      .input-file, .multiple-files {
        width: 100%;
        max-width: 520px;
        border: 1px solid black;
        border-radius: 4px;
        position: relative;
        background-color: white;

        .file-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 7px;
          padding: 14px;
          font-size: 15px;
          border-radius: 4px;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        .file-empty::before {
          content: '';
          width: 98%;
          height: 100%;
          position: absolute;
          background-image: url("../images/border-dashed.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .file-uploaded {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-block: 5px;
          padding-inline: 14px 5px;
          font-size: 15px;
        }

        .file-edit-button, .file-delete-button {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 1px 3px;
          line-height: 1;
          color: white;
          width: 245px;
          max-width: max-content;
          height: 36px;
          background-color: black;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
        }

        .button-content {
          display: flex;
          align-items: center;

          span {
            font-size: 15px;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }

        small {
          font-size: 12px;
          color: #737373;
        }

        small.err-message {
          color: red;
        }

        input[type='file'] {
          opacity: 0;
          cursor: pointer;
        }

        .upload-fullsize {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }

        .upload-button {
          position: absolute;
        }

        .text-blue {
          color: #052443;
        }

        .file-uploaded-wrapper {
          display: flex;
          flex-direction: column;
          margin-block: 10px;
          padding: 0 8px;
          border: 1px solid black;
          border-radius: 6px;
        }
      }

      .multiple-files {
        display: flex;
        flex-direction: column;
        border: none;
        background: transparent;

        .file-uploaded {
          padding-inline: 0;
        }

        .file-edit-button, .file-delete-button {
          height: 28px;
        }

        .button-content {
          display: flex;
          align-items: center;

          span {
            font-size: 14px;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .err-input {
        border: 1px solid red;
        outline: none;
      }

      .input-category {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-top: 10px;
      }

      .-mt-10 {
        margin-top: -14px;
      }

      .file-action-buttons {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .input-select {
      display: flex;
      position: relative;
      align-items: center;
    }
    i.icon-arrow-down {
      position: relative;
      left: -36px;
      font-size: 26px;
      color: #ccc;
      cursor: pointer;
      pointer-events: none;
    }

    .upload-icon, .edit-icon, .delete-icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .upload-icon {
      background-image: url("../images/upload.svg");
      width: 20px;
      height: 20px;
    }

    .edit-icon {
      background-image: url("../images/edit.svg");
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .delete-icon {
      background-image: url("../images/delete-icon.svg");
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .pop-up-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 400px;
    text-align: center;
    font-size: 16px;
    transition: all .7s ease-in-out;

    &__file-name {
      font-weight: bold;
      margin-bottom: 10px;
    }

    &__message {
      margin-bottom: 20px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    &__button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        opacity: .9;
      }
    }

    &__button--yes {
      background-color: #ff6347;
      color: white;
    }

    &__button--no {
      background-color: #6495ed;
      color: white;
    }
  }

  @media screen and (max-width: 720px) {
    .content-wrapper {
      padding-block: 32px;
      padding-inline: 24px;

      .file-empty::before {
        transform: translate(-50%, -50%) scaleY(1.5) !important;
      }
    }
  }
}
