.in-orderinfo-wrapper {
    .margin-bottom-rem(100);
    background: #fff;
    .in-order-header {
        .padding-left-rem(40);
        .padding-right-rem(40);
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        .in-btn-group {
            width: 180px;
            & span {
                font-weight: normal;
            }
        }
        h5 {
            .font-size-rem(30);      .centered-line(90px);
            color: @base-font-color;
        }
    }
    .in-orderItems-wrapper {
        .padding-bottom-rem(10);
        .padding-left-rem(20);
        .padding-right-rem(20);
        .padding-top-rem(10);
        .price,.qty,.total {
            text-align: center;
            width: 15%;
        }
        .product {
            .padding-left-rem(70);
            width: 40%;
        }
        table {
            width: 100%;
            tbody {
                border-bottom: 1px solid #eee;
                .in-qty p {
                    color: #333333;
                    font-style: italic;
                }
                img {
                    min-width: 70px;
                    width: 70px;
                }
                td {
                    text-align: center;
                    & p span {
                        .font-size-rem(18);
                        color: #333;
                    }
                }
                td:first-child {
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  div {
                    display: inline-block
                  }
                }
                tr & td:first-child {
                    .padding-bottom-rem(20);
                    .padding-left-rem(70);
                    .padding-top-rem(20);
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    & .in-info {
                        .padding-left-rem(10);
                        text-align: left;
                        .in-item-params span {
                            .font-size-rem(14);                  color: @btn-base-color;
                        }
                        span {
                            .font-size-rem(18);
                            color: #333333;
                        }
                    }
                }
            }
            thead {
                span {
                    .font-size-rem(14);          .centered-line(40px);
                    color: @base-font-color;
                }
                tr {
                    border-bottom: 1px solid #eee;
                }
            }
        }
    }
    .in-orderShipInfo-wrapper {
        .padding-rem(40);
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        & ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                width: 50%;
                .title {
                    .padding-right-rem(10);
                    color: @base-font-color;
                }
                span {
                    .font-size-rem(18);          color: #333333;          .centered-line(40px);
                }
            }
            li:nth-child(2n) {
                text-align: right;
            }
        }
    }
    .in-shipping-wrapper {
        .padding-bottom-rem(60);
        .padding-left-rem(20);
        .padding-right-rem(20);
        .padding-top-rem(60);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .in-addr {
            display: flex;
            flex-direction: row;
            i {
                .font-size-rem(80);        color: @base-font-color;        .padding-right-rem(10);
            }
            p {
                .font-size-rem(18);        .centered-line(28px);
                color: #333333;
            }
        }
        .in-total-wrapper {
            width: 32%;
            & li {
                .centered-line(28px);
                .font-size-rem(18);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                & > span {
                    color: #333333;
                    &:first-child {
                        font-style: italic;
                    }
                }
            }
        }
        .total {
            .font-size-rem(30);      font-weight: bold;
        }
    }
    .in-shipping-wrapper {
        align-items: center;
        .in-addr {
            align-items: center;
            i {
                font-size: 56px;
            }
        }
    }
}
