.mpesa::before {
    background: url(../images/mpesa_pay.jpg) no-repeat center;
}
.mpsea-container {
    margin: 0 auto;
    max-width: 1100px;
}
.mpsea-panel {
    background: #fff;
    margin-bottom: 130px;
    .left {
        align-items: center;
        border-right: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 334px;
        width: 32%;
        .mpsea-logo {
            background: url(../images/mpsea.jpg) no-repeat center;
            display: inline-block;
            font-size: 0;
            height: 74px;
            width: 148px;
        }
        .total-container {
            align-items: center;
            display: flex;
            flex-direction: row;
            > span {
                padding-right: 10px;
            }
            p span {
                .font-size-rem(28);        color: @btn-base-color;
            }
            span {
                .font-size-rem(18);
            }
        }
    }
    .right {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 68%;
        & ul {
            list-style: disc;
            padding: 0 100px;
            .b {
                color: #000;
                font-weight: 600;
            }
            .red {
                color: #ff0000;
            }
            li {
                .font-size-rem(14);        line-height: 30px;
                color: #666666;
            }
        }
    }
    .suc-msg {
        display: flex;
        flex-direction: row;
    }
}
