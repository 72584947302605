.in-ram-popup {
    padding: 0;
    & .in-title {
        .centered-line(60px);
        background: @btn-base-color;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        .in-icon-close {
            .font-size-rem(30);
            color: #fff;
            cursor: pointer;
            font-style: normal;
            position: absolute;
            right: 30px;
            top: 0;
        }
        > h3 {
            .font-size-rem(20);      color: #fff;      font-weight: 400;
        }
    }
    .history-order-header {
        text-align: center;
    }
    .in-btn {
        margin-bottom: 30px;
        text-align: center;
    }
    .in-ram-popup__content {
        padding: 30px 40px;
    }
    .subTitle {
        .font-size-rem(16);    margin-bottom: 20px;    font-weight: 300;    line-height: 1.2;    border-bottom: 1px solid #e6e6e6;    padding: 10px 0;
        ~ .subTitle {
            margin-top: 40px;
        }
    }
    span.is-danger {
        .font-size-rem(12);
    }
    table {
        margin-bottom: 0;
    }
}
.in-ram-popup__content table {
    .history-order-header {
        .qty,.total {
            width: 10%;
        }
    }
    .in-select__wrapper {
        padding: 4px 10px;
    }
    td {
        padding-left: 4px;
        padding-right: 4px;
    }
}
.in-rma-container .in-history-action {
    width: 15%;
}
