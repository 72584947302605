.catalog-category {
    .xpark-shop-info {
      background-color: #fff;
      .in-container {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EBEBEB;
        padding: 14px 0;
        .xpark-logo {
          width: 166px;
          margin-right: 18px;

        }
        .shop-info {
          display: flex;
          min-width: 300px;
          padding: 0 20px;
          border-left: 1px solid #EBEBEB;

          .logo {
            height: 45px;
            width: 45px;
            margin-right: 15px;

            img {
              width: 100%;
            }
          }

          .detail {
            .title {
              color: #333333;
              font-size: 20px;
              height: 29px;
              text-align: left;
            }
            .description {
              font-size: 16px;
              color:#666666
            }
          }
        }
        .contact {
          height: 45px;
          display: flex;
          align-items: flex-end;
          min-width: 300px;
          font-size: 16px;
          color: #666666;
          padding: 0 20px;
          border-left: 1px solid #EBEBEB;

          .tel {
            margin-right: 50px;
          }

          img {
            vertical-align: bottom;
          }
        }
      }

    }

    .in-ads-title {
        font-family: @font-medium;
        padding-top: 36px;
        .in-ads-hr {
            background-color: #e8e8e8;
            height: 4px;
            margin: 0;
            width: 100%;
        }
        > h2 {
            font-size: 36px;
            height: auto;
            line-height: 36px;
            margin-top: 0;
        }
    }
    .in-group-purchase-wrapper .in-group-item {
        .in-group-content {
            width: 50%;
        }
        > a {
            width: 50%;
        }
    }
    .in-product-list {
        display: flex;
        flex-wrap: wrap;
    }
    .in-productList-container {
        .in-ads-title {
            .padding-top-rem(34);
        }
        .in-product {
            .margin-bottom-rem(40);      .margin-bottom-rem(20);
        }
        .in-product-list {
            .in-product {
                .margin-right-rem(20);
                float: none;
                width: calc(~"(100% - 80px) / 5");
                &:nth-child(5n) {
                    margin-right: 0;
                }
            }
            .in-product-list__content {
                display: flex;
                flex-grow: 1;
                flex-wrap: wrap;
                margin-right: 0;
            }
        }
        .in-sort-by-wrapper {
            margin-bottom: 1rem;
            margin-top: 1.2rem;
            .in-sort-by {
                padding: 1rem 0;
                & > ul {
                    li:last-child {
                        border-right: 0;
                    }
                    span {
                        .font-size-rem(16);
                        .padding-left-rem(20);
                        .padding-right-rem(20);
                        color: #666;
                        line-height: 1;
                        &.active,&:hover {
                            color: @btn-base-color;
                        }
                    }
                }
                > ul {
                    span {
                        .font-size-rem(22px);
                    }
                    span.active,span:hover {
                        color: #000;
                    }
                }
            }
        }
        .in-sort-title {
            .font-size-rem(24);      text-transform: uppercase;      text-align: left;      margin-bottom: 8px;
            color: #cccccc;
        }
    }
}
