.merchant-terms {
  overflow-x: hidden;
  height: 100%;

  .xp-icon-close {
    color: #666;
    font-size: 2.4rem;
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    cursor: pointer;
  }

  .content-wrapper {
    padding-top: 88px;
    padding-left: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .pre-action-text {
      color: #000000;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 13px;
      margin-left: -22px;
      cursor: pointer;

      .back-arrow {
        width: 9px;
        height: 19px;
        background-image: url("../images/back-arrow.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    //.title {
    //  color: #000000;
    //  margin-block: 15px 14px;
    //}

    .subtitle {
      color: #000000;
      font-size: 18px;
      line-height: 27px;
    }

    .text-wrapper {
      max-width: 95%;
      margin-top: 14px;
      margin-bottom: 30px;
      padding-right: 5%;
      padding-bottom: 5%;
      height: 74vh;
      overflow-y: scroll;

      p, li, span {
        font-size: 14px;
        line-height: 1.5;
        //display: flex;
        text-align: justify;
      }
    }

    .text-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #EEEEEE;
      border-radius: 7px;
    }

    .text-wrapper::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-radius: 7px;
      background-color: black;
    }
    .title {
      text-align: center!important;
      font-size: 20px;
      margin-bottom: 20px;
      justify-content: center;
    }

    .text-bold {
      font-weight: bold;
    }

    .text-bold {
      font-weight: bold;
    }

    .paragraph {
      text-indent: 5%
    }

    .custom-ol {
      counter-reset: custom-counter;
      margin-left: 3%;

      li {
        display: flex;
        gap: 10px;
      }
    }

    .custom-ol .list-item {
      counter-increment: custom-counter;
    }

    .number-list {
      font-weight: bold;
    }

    .number-list ol {
      font-weight: normal;
    }

    .number-list:nth-child(1) .custom-ol .list-item::before {
      content: "1." counter(custom-counter) ". ";
    }

    .number-list:nth-child(2) .custom-ol .list-item::before {
      content: "2." counter(custom-counter) ". ";
    }

    .number-list:nth-child(3) .custom-ol .list-item::before {
      content: "3." counter(custom-counter) ". ";
    }

    .number-list:nth-child(4) .custom-ol .list-item::before {
      content: "4." counter(custom-counter) ". ";
    }

    .number-list:nth-child(5) .custom-ol .list-item::before {
      content: "5." counter(custom-counter) ". ";
    }

    .number-list:nth-child(7) .custom-ol .list-item::before {
      content: "7." counter(custom-counter) ". ";
    }

    .number-list:nth-child(8) .custom-ol .list-item::before {
      content: "8." counter(custom-counter) ". ";
    }

    .number-list:nth-child(9) .custom-ol .list-item::before {
      content: "9." counter(custom-counter) ". ";
    }

    .number-list:nth-child(10) .custom-ol .list-item::before {
      content: "10." counter(custom-counter) ". ";
    }

    .number-list:nth-child(11) .custom-ol .list-item::before {
      content: "11." counter(custom-counter) ". ";
    }

    .number-list:nth-child(12) .custom-ol .list-item::before {
      content: "12." counter(custom-counter) ". ";
    }
    .list-content {
      list-style: decimal;
    }
    .btn-agree {
      color: white;
      background: #000000;
      padding: 10px 58px;
      margin-top: 35px;
    }
    .list-item {
      display: flex;
    }

    ul {
      margin-left: 34px;
    }

    .lang-selector {
      display: flex;
      margin-block: 20px;
      gap: 10px;
      font-size: 16px;

      span {
        cursor: pointer;
      }

      .lang-active {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 720px) {
    .content-wrapper {
      padding-block: 32px;
      padding-inline: 32px 24px;
    }

    .pre-action-text {
      color: #000000;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 13px;
      margin-left: -22px;
    }

    .text-wrapper {
      max-width: 100% !important;
      margin-top: 14px;
      padding-right: 0 !important;
      height: 80vh !important;

      span {
        text-align: left !important;
      }
    }

    .text-wrapper::-webkit-scrollbar {
      display: none;
    }
  }
}
