@font-face {
    font-family: HelveticaNeue67;
    src: url('./fonts/Helvtica-Nenu-67/HelveticaNeueLTPro-MdCn.eot'),     url('./fonts/Helvtica-Nenu-67/HelveticaNeueLTPro-MdCn.ttf'),     url('./fonts/Helvtica-Nenu-67/HelveticaNeueLTPro-MdCn.woff'),     url('./fonts/Helvtica-Nenu-67/HelveticaNeueLTPro-MdCn_1.otf');
}
@font-face {
    font-family: HelveticaNeue45;
    src: url('./fonts/Helvtica-Neue-45/HelveticaNeueLTPro-Lt.eot'),  url('./fonts/Helvtica-Neue-45/HelveticaNeueLTPro-Lt.ttf'),  url('./fonts/Helvtica-Neue-45/HelveticaNeueLTPro-Lt.woff');
}
@font-face {
    font-family: OpenSansSemiBold;
    src: url('./fonts/Open_Sans/OpenSans-SemiBold.ttf');
}
@font-face {
    font-family: OpenSansSemiRegular;
    src: url('./fonts/Open_Sans/OpenSans-Regular.ttf');
}
@font-face {
    font-family: Montreal;
    src: url('./fonts/Montreal/Montreal-Serial-Regular.eot'),  url('./fonts/Montreal/Montreal-Serial-Regular.ttf'),  url('./fonts/Montreal/Montreal-Serial-Regular.woff');
}
@font-face {
    font-family: RobotoMedium;
    src: url("./fonts/Roboto-Medium/Roboto-Medium.eot"),    url("./fonts/Roboto-Medium/Roboto-Medium.ttf"),    url("./fonts/Roboto-Medium/Roboto-Medium.woff");
}
@font-face {
    font-family: RobotoRegular;
    src: url("./fonts/Roboto-Regular/Roboto-Regular.eot"),    url("./fonts/Roboto-Regular/Roboto-Regular.ttf"),    url("./fonts/Roboto-Regular/Roboto-Regular.woff");
}
@font-face {
    font-family: DINPro-Bold;
    src: url("./fonts/DINPro-Bold/DINPro-Bold.eot"),    url("./fonts/DINPro-Bold/DINPro-Bold.ttf"),    url("./fonts/DINPro-Bold/DINPro-Bold.woff");
}
@font-face {
    font-family: DINPro-Medium;
    src: url("./fonts/DINPro-Medium/DINPro-Medium.eot"),    url("./fonts/DINPro-Medium/DINPro-Medium.ttf"),    url("./fonts/DINPro-Medium/DINPro-Medium.woff");
}
@font-face {
    font-family: DINPro-Regular;
    src: url("./fonts/DINPro-Regular/DINPro-Regular.eot"),    url("./fonts/DINPro-Regular/DINPro-Regular.ttf"),    url("./fonts/DINPro-Regular/DINPro-Regular.woff");
}
@font-face {
  font-family: DINPro-Light;
  src: url("./fonts/DINPro-Light/DINPro-Light.eot"),    url("./fonts/DINPro-Light/DINPro-Light.ttf"),    url("./fonts/DINPro-Light/DINPro-Light.woff");
}

.font-helNenu67 {
    font-family: @font-regular;
    font-weight: 400;
    letter-spacing: 4px;
}
.font-medium {
    font-family: @font-medium;
    font-weight: 500;
}
.font-montreal {
    font-family: DINPro-Regular;
    letter-spacing: 1px;
}
.font-openSansB {
    font-family: @font-regular;
}
