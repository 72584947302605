.account-register {
    overflow: hidden;
    padding-top: 0;
    & .main-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-between;
        width: 100%;
    }
    .in-account-login-container {
        background-color: #ffffff;
        border-radius: 8px;
        height: auto;
        width: 520px;
    }
    .in-account-register-container {
        background-color: #f7f7f7;
        border-radius: 8px;
        height: auto;
        padding: 32px 0;
        width: 100%;
    }
    .in-brand-area {
        align-items: center;
        background-color: #000;
        display: flex;
        flex-shrink: 0;
        height: 110px;
        img {
            width: 240px;
        }
    }
    .in-form-group {
        margin: 0 0 16px;
        .in-btn-register {
            border-radius: 0;
            font-size: 30px;
            height: 62px;
            line-height: 1em;
            margin-bottom: 0;
            margin-top: 62px;
            padding: 17px 0;
        }
        .in-form-control {
            background-color: #ffffff;
            border-radius: 0;
            border: 1px solid #e8e8e8;
            color: #333333;
            font-size: 24px;
            height: 58px;
            padding: 0 18px;
        }
        .in-msg {
            color: #ff3600;
            margin-top: 10px;
        }
        .input-placeholder {
            position: relative;
            & .in-form-control {
                align-items: center;
                display: flex;
                justify-content: space-between;
                .icon-arrow-down {
                    color: #979797;
                    font-size: 28px;
                    line-height: 23px;
                }
            }
        }

        .verification-code-content {
          display: flex;
          gap: 10px;

          .in-btn-register-area {
            width: 30%;
            height: 45px;

            .in-btn-register {
              height: 100%;
              margin-top: 0;
            }
          }
        }
    }
    .send-tip {
      font-size: 12px;
      padding: 0 12px;
      color: #666;
      line-height: 1.2;
      margin-top: 8px;
    }
    .in-login-content {
        background: url(../images/loginbg.png);    background-position: center;    background-repeat: no-repeat;    background-size: cover;
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        position: relative;
    }
    .in-login-form-area .in-form-group .in-form-control.is-danger {
        border: 1px solid @main-btn-color;
    }
    .in-login-register {
        align-items: center;
        background: transparent;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 46%;
        .in-register-form {
            position: static;
        }
    }
    .in-register-content {
        background-color: #f7f7f7;
        flex-grow: 1;
        overflow-y: auto;
    }
    .in-register-form {
        padding: 32px 30px 64px;
        width: 100%;
        .title {
            display: flex;
            margin-bottom: 24px;
            padding: 17px 0;
            > h2 {
                color: #666666;
                font-size: 30px;
                font-weight: normal;
                text-align: center;
                width: 50%;
                &.active {
                    color: @main-btn-color;
                }
                &:first-child {
                    position: relative;
                    &::before {
                        background-color: #dddddd;
                        content: " ";
                        display: inline-block;
                        height: 24px;
                        position: absolute;
                        right: 0;
                        top: 3px;
                        width: 1px;
                    }
                }
                span {
                    cursor: pointer;
                }
            }
            > h2.active {
                color: #000;
            }
        }
    }
    .in-register-form-area {
        background-color: #fff;
        padding: 48px 32px 64px;
        position: relative;
        width: 1320px;
        .in-btn-register {
            border-radius: 32px;
            height: 60px;
            margin: 42px auto 0;
            padding: 15px 0;
            width: 480px;
        }
        .in-btn-register-area {
            margin-bottom: 0;
        }
        .in-form {
            margin-top: 46px;
            margin: 0 auto;
            width: 710px;
        }
        .in-input-box {
            align-items: center;
            border-radius: 60px;
            border: 1px solid #e8e8e8;
            display: flex;
            padding: 0 20px;
            &.is-danger {
                border: 1px solid #ff3600;
            }
            .in-form-control {
                background-color: transparent;
                border: 0;
            }
            .in-input-label {
                border-right: 1px solid #ddd;
                color: #666;
                flex-shrink: 0;
                min-width: 134px;
                padding-right: 20px;
            }
        }
        .in-to-login-btn {
            color: #666;
            font-size: 22px;
        }
        .issubscrib {
            margin-bottom: 0;
            .in-checkbox-input {
                align-items: center;
                border: 1px solid #e8e8e8;
                display: inline-flex;
                height: 20px;
                justify-content: center;
                line-height: 20px;
                margin-right: 10px;
                width: 20px;
                i {
                    color: #ffffff;
                    font-size: 20px;
                }
            }
            input {
                display: none;
                &:checked + .in-checkbox-input {
                    background-color: @main-btn-color;
                    border: 1px solid @main-btn-color;
                }
            }
            input:checked + .in-checkbox-input {
                background: #000;
                border-color: #000;
            }
            label {
                color: #999;
                cursor: pointer;
                display: inline-flex;
                font-size: 20px;
            }
        }
        > h2 {
            color: #333333;
            font-size: 32px;
        }
    }
    .in-to-login {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 16px 0 0;
        a {
            color: #999999;
            font-size: 20px;
        }
    }
    .main-container {
        flex-direction: column;
    }
}
