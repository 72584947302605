.in-checkout-payment {
    margin-top: 20px;
    .in-item-count {
        .font-size-rem(18);
        cursor: pointer;
    }
    .in-order > h3 {
        .padding-top-rem(30);
    }
    > .in-container {
        .margin-bottom-rem(118);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .in-cart-bar {
        border-top: 0;
        height: 160px;
        .in-btn-checkout a {
            background-color: #000;
            font-size: 18px;
            height: 47px;
            line-height: 1em;
            padding: 15px 24px;
            width: 398px;
            border-radius: 0;
        }
        .in-item-count {
            color: #000;
            font-size: 16px;
            font-style: normal;
            height: 22px;
            line-height: 1em;
            margin-bottom: 12px;
        }
        .in-total span {
            display: inline-block;
            &:first-child {
                color: #000;
                font-size: 16px;
                font-style: normal;
            }
            &:last-child {
                color: #ff3200;
                font-size: 38px;
                font-weight: normal;
            }
        }
    }
    .in-coupon-container {
        padding: 24px;
        .in-coupon-list {
            margin-top: 40px;
        }
        .in-input-container {
            display: flex;
            margin-bottom: 0;
            width: 100%;
            .in-submit {
                background-color: #000;
                font-size: 16px;
                height: 47px;
                line-height: 47px;
                margin-left: 24px;
                position: static;
                width: 346px;
                border-radius: 0;
            }
            > input {
                background-color: #f3f3f3;
                border-radius: 27px;
                border: 0;
                flex-grow: 1;
                font-size: 16px;
                height: 47px;
                padding: 0 16px;
                &::-moz-placeholder,&::-ms-input-placeholder,&::-webkit-input-placeholder {
                    color: #999;
                }
            }
        }
    }
    .points-container {
      font-size: 16px;
      color: #333;

      .title {
        font-size: 17px;
        text-transform: capitalize;
        border: none;
      }

      .points-info {
        display: flex;
        align-items: center;
        padding: 6px 24px 20px 0;
        margin-left: 24px;
        border-bottom: 1px solid #E8E8E8;

        .check-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-right: 22px;
          padding: 2px 2px 0 0;
          border-radius: 50%;
          color: #fff;
          background-color: #e3e3e3;
          font-weight: 700;
          cursor: pointer;

          &.active {
            background-color: #000;
          }
        }
      }
      .points-select {
        display: flex;
        align-items: center;
        padding: 20px 70px 30px;

        .select-btn {
          margin-left: 14px;
          color: #000;
          padding: 10px 21px 10px 15px;
          background-color: #F6F6F6;
          border: 1px solid #DBDBDB;

          .link {
            color: #FF3201;
            text-decoration: underline;
            margin-left: 38px;
            cursor: pointer;
          }
        }
      }

      .black {
        color: #000;
        border: none;
        font-weight: bold;
      }

      .red {
        color: #FF3200;
        border: none;
        font-weight: bold;
      }

      .select-point-modal {
        position: relative;
        padding: 30px;
        text-align: center;

        .close-btn {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 20px;
          top: 20px;
          width: 16px;
          height: 16px;
          padding-top: 2px;
          border-radius: 50%;
          background-color: #000;
          color: #fff;
          cursor: pointer;

          i {
            font-size: 14px;
          }
        }

        h3 {
          font-size: 16px;
          margin-bottom: 28px;
        }

        .point-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          cursor: pointer;

          .check-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-right: 15px;
            padding: 1px 2px 0 0;
            border: 2px solid #FF3201;
            border-radius: 50%;
            color: #fff;
            background-color: #fff;
            font-weight: 700;

            i {
              font-size: 16px;
            }

            &.active {
              background-color: #ff3201;
            }
          }

          .rule {
            font-size: 14px;
            color: #000;
          }
        }

        .confirm-btn {
          width: 100%;
          height: 36px;
          margin-top: 40px;
          font-size: 13px;
          line-height: 36px;
          font-weight: bold;
          color: #fff;
          background-color: #000;
          cursor: pointer;
        }
      }
    }



    .in-item-wrapper {
        border-radius: 4px;
        flex-grow: 1;
        padding-bottom: 0;
        width: auto;
        > div {
            background-color: #ffffff;
            padding: 0 20px;
            &:nth-child(n+2) {
                box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.06);        border-radius: 6px;
                margin-top: 20px;
                padding: 0 20px;
            }
        }
        h2 {
            border-bottom: 1px solid #e8e8e8;
            color: #000;
            font-size: 16px;
            margin: 0;
            padding: 24px;
        }
    }
    .in-order {
        border-radius: 4px;
        flex-shrink: 0;
        margin-left: 20px;
        min-height: 802px;
        padding: 0;
        width: 398px;
        .in-esttimate-shipping-content {
            padding: 0 16px;
        }
        .in-group-order {
            .in-order-item {
                margin: 16px;
                padding: 0;
                .in-item-info {
                    padding-left: 12px;
                    padding-right: 0;
                    h6 {
                        color: #000;
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
                > img {
                    background-color: #f2f2f2;
                    height: 80px;
                    width: 80px;
                }
            }
            h5 {
                border: 0;
                margin-left: 16px;
            }
        }
        .in-order-details .in-title {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            padding: 24px 0 0;
            margin: 0 16px;
            color: #000;
            i {
                font-size: 20px;
                line-height: 1em;
                margin-right: 16px;
                color: #000;
            }
            h3 {
              padding-left: 0;
            }
        }
        .in-totals-wrapper {
            background-color: #fff;
            border-color: #e8e8e8;
            padding: 16px 0;
            margin: 0 16px;
            .in-label {
                color: #000;
                font-size: 16px;
                font-style: normal;
            }
            .in-value span {
                color: #000;
                font-size: 16px;
            }
            .in-value[code="grand_total"] span {
                color: #ff3200;
                font-family: @font-medium;
                font-size: 16px;
                font-weight: 500;
            }
            > ul {
                background-color: #f7f7f7;
                li {
                    align-items: center;
                    padding: 12px 8px;
                    &:first-child {
                        border-top: 0;
                    }
                }
            }
        }
        .swiper-container {
            height: 380px;
            margin: 0 0 10px;
        }
        .swiper-container-vertical > .swiper-scrollbar {
            background: #ffffff;
        }
        .swiper-scrollbar-drag {
            background: #e3e3e3;
        }
        h3 {
            color: #000;
            font-size: 16px;
            padding: 13px 16px;
        }
    }
    .in-payments-container {
        .in-payment {
            margin-bottom: 0;
            padding: 0 24px;
            border-radius: 0.25rem;
            &.active {
                background-color: #fff;
                // border: 2px solid #ff6b00;
                .in-choose-add {
                    background: #ff6b00;
                }
                > div {
                    border-bottom: 0;
                }
            }
            &:last-child > div {
                border-bottom: 0;
            }
            .check-icon {
                flex-shrink: 0;
            }
            .in-choose-add {
                align-items: center;
                background: #e3e3e3;
                border-radius: 50%;
                border: 1px solid #e3e3e3;
                color: #fff;
                display: flex;
                height: 22px;
                justify-content: center;
                opacity: 1;
                position: static;
                width: 22px;
                i {
                    font-size: 20px;
                    line-height: 1em;
                    font-weight: bold;
                    margin-right: 2px;
                }
            }
            .payment-icon {
                flex-shrink: 0;
                margin-left: 16px;
                padding: 0;
                width: 200px;
            }
            .payment-info {
                flex-grow: 1;
                margin-left: 16px;
                .payment-desc {
                    color: #000;
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 16px;
                    padding-top: 0;
                }
                .payment-title {
                    color: #000;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 1em;
                }
            }
            > div {
                align-items: center;
                border-bottom: 1px solid #e8e8e8;
                display: flex;
                padding: 24px 0;
                width: 100%;
            }
        }
        .in-payment.active {
            border-color: #999;
            border: 1px solid;
            border-radius: 5px;
            .in-choose-add {
                background: #000;
            }
        }

        .in-easybuy-container {
            display: flex;
            flex-direction: column;
            margin-top: 1.625rem;
            margin-bottom: 1.25rem;
            padding-bottom: 1rem;

            .in-loan-container {
                display: flex;
                flex-direction: column;
                padding: 0 1.875rem;

                .in-loan-title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        font-weight: 500;
                        font-size: 1.15rem;
                        line-height: 1.5rem;
                        margin-right: 0.375rem;
                    }
                }

                .in-loan-input {
                    span.input-icon {
                        position: absolute;
                        font-size: 1.25rem;
                        margin-left: 0.5rem;
                        margin-top: 0.5rem;
                    }

                    input {
                        border: .063rem solid #333;
                        border-radius: .25rem;
                        height: 2.5rem;
                        width: 9.5rem;
                        font-size: 1.25rem;
                        padding-left: 1.8rem;
                        color: #333;
                    }
                }

                .in-loan-desc {
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                    color: #999;
                }

                .in-loan-error {
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                    color: #FF3200;
                }
            }

            .in-easybuy-terms {
                margin-top: 1.5rem;

                .in-easybuy-terms-items {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 0.25rem;
                    padding: 2rem;
                    border: 0.063rem solid #E3E3E3;
                    margin-bottom: 0.5rem;

                    .span {
                        font-size: 1.2rem;
                        line-height: 1.7rem;
                    }

                    &.active {
                        border: 0.063rem solid #000 !important;
                    }
                }
            }

            .in-easybuy-agree {
                display: flex;
                flex-direction: row;
                margin-top: 1rem;
                input {
                    margin-right: 0.625rem;
                }
                span.blue-link {
                    color: #00ADEF;
                }
            }
        }
    }

    .loading-checkout-payment {
        background-color: #fff;
        //display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        height: calc(100vh - 148px);
        width: 100%;
        z-index: 7777;
        margin-top: -20px;

        .logo-container {
            background: url("../images/xpark_logo.png") no-repeat center/cover;
            display: block;
            width: 18.75rem;
            height: 3.75rem;
            margin-bottom: 2.563rem;
        }

        .loading-container {
            width: 18.75rem;
            background-color: #e3e3e3;
            border-radius: 0.25rem;
            height: 1.5rem;

            .loading-bar {
                background-color: #000;
                color: #fff;
                height: 1.5rem;
                border-radius: 0.25rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 0.5rem;
                font-weight: 700;
                font-size: 0.75rem;
            }
        }

        .loading-text {
            margin-top: 0.813rem;
            font-style: normal;
            font-weight: 300;
            font-size: 1.25rem;
            line-height: 1.438rem;
            letter-spacing: 0.1em;
        }
    }

    .in-reward-point-container {
        padding: 24px;
        .in-input-container {
            display: flex;
            margin-bottom: 0;
            width: 100%;
            .in-submit {
                border-radius: 27px;
                font-size: 26px;
                height: 47px;
                line-height: 47px;
                margin-left: 24px;
                position: static;
                width: 200px;
            }
            input {
                background-color: #f3f3f3;
                border-radius: 27px;
                border: 0;
                flex-grow: 1;
                font-size: 24px;
                height: 47px;
                padding: 0 16px;
            }
        }
    }
    .in-ship-address,.in-ship-time {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 50%;
        > div {
            .margin-left-rem(20);
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        i {
            .font-size-rem(80);      color: @base-font-color;
        }
    }
    .in-shipmation-container {
        flex-direction: column;
        padding: 0 24px;
        .in-ship-address {
            border-bottom: 1px solid #e8e8e8;
            padding: 24px 0;
            width: 100%;
            .in-address,.in-street {
                color: #000;
                font-size: 16px;
                height: auto;
                line-height: 1em;
                margin-bottom: 8px;
            }
            .in-name {
                color: #000;
                font-size: 16px;
                line-height: 1em;
            }
            .in-tel {
                color: #000;
                font-size: 16px;
                line-height: 1.2em;
            }
            .in-time {
                color: #000;
                font-size: 16px;
                line-height: 1.2em;
                .price {
                    color: #000;
                    display: inline-block;
                    font-size: 16px;
                    line-height: 1em;
                    margin-bottom: 8px;
                }
            }
            > div {
                margin-left: 24px;
            }
            i {
                color: #666666;
                font-size: 56px;
            }
        }
        .in-ship-time {
            padding: 24px 0;
            width: 100%;
            .in-address,.in-street {
                color: #666666;
                font-size: 22px;
                height: auto;
                line-height: 1em;
                margin-bottom: 8px;
            }
            .in-name {
                color: #000;
                font-size: 16px;
                line-height: 1em;
            }
            .in-tel {
                color: #000;
                font-size: 16px;
                line-height: 1.2em;
            }
            .in-time {
                color: #000;
                font-size: 16px;
                line-height: 1.2em;
                .price {
                    color: #000;
                    display: inline-block;
                    font-size: 16px;
                    line-height: 1em;
                    margin-bottom: 8px;
                }
            }
            > div {
                margin-left: 24px;
            }
            i {
                color: #666666;
                font-size: 56px;
            }
        }
    }
    > .in-container {
        align-items: flex-start;
        margin-bottom: 10rem;
    }
}
