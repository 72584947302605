.in-shopping-cart-container {
    margin-top: 20px;
    & > .in-container {
        .margin-bottom-rem(118);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .in-cart-bar {
        border-top: 0;
        height: 160px;
        .in-btn-checkout a {
            background-color: #000;
            font-size: 22px;
            height: 52px;
            line-height: 1em;
            padding: 15px 24px;
            width: 372px;
            border-radius: 0;
        }
        .in-item-count {
            font-size: 22px;
            font-style: normal;
            height: 22px;
            line-height: 1em;
            margin-bottom: 12px;
        }
        .in-total span {
            display: inline-block;
            &:first-child {
                color: #666;
                font-size: 26px;
                font-style: normal;
            }
            &:last-child {
                color: #333;
                font-size: 38px;
                font-weight: normal;
            }
        }
    }
    .in-delete-modal {
        padding: 96px 32px 42px;
        text-align: center;
        .modal-delete-btn {
          color: #fff;
            background: #000;
            height: 58px;
            width: 360px;
        }
        .xp-icon-close {
            color: #666;
            display: inline-block;
            font-size: 48px;
            padding: 0;
            right: 32px;
            top: 32px;
        }
        > p {
            color: #333;
            font-size: 28px;
            margin-bottom: 2rem;
        }
    }
    .in-empty-cart {
      background-color: #fff;
      .in-404-note {
        padding-top: .5rem;
        color: #000;
      }
      .in-btn a{
        font-size: 18px;
        border-radius: 4px;
      }
    }
    .in-item-title {
        position: relative;
        .right {
            font-size: 14px;
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10 !important;
            & > span {
                font-size: 16px;
                margin-right: 10px;
            }
        }
    }
    .in-item-wrapper {
        background-color: #fff;
        border-radius: 4px;
        flex-grow: 1;
        padding-bottom: 0;
        width: auto;
        .in-action {
            flex-shrink: 0;
            text-align: center;
            width: 120px;
            i {
                color: #d8d8d8;
                font-size: 30px;
                opacity: 1;
                &:hover {
                    color: #000;
                }
            }
        }
        .in-groups-item .in-item {
            border: 0;
            padding: 0 24px;
            &:first-of-type > div {
                border-top: 0;
            }
            &:hover {
                box-shadow: 0px 1px 14px 1px rgba(0, 0, 0, 0.06);
            }
            > div {
                border-top: 1px solid #e8e8e8;
                display: flex;
                padding: 16px 0 16px 16px;
                width: 100%;
            }
        }
        .in-item-container {
            padding: 0;
        }
        .in-item-outline {
            flex-grow: 1;
            width: auto;
            .in-item-name {
                color: #666;
                font-size: 26px;
                word-break: break-word;
            }
            .in-item-subtotal {
                margin-top: 16px;
                span {
                    color: #333;
                    font-size: 26px;
                }
            }
            > div {
                margin-left: 16px;
                margin-top: 0;
                padding-right: 0;
            }
            img {
                background-color: #f2f2f2;
                height: 160px;
                width: 160px;
            }
        }
        .in-item-qty {
            flex-shrink: 0;
            margin-left: 16px;
            margin-right: 24px;
            margin-top: 0;
            width: 180px;
            > div {
                align-items: center;
                background-color: #f2f2f2;
                border-radius: 23px;
                display: flex;
                height: 40px;
                padding: 6px 16px;
                i {
                    background-color: #f2f2f2;
                    color: #333;
                    font-size: 30px;
                    height: 34px;
                    line-height: 34px;
                    position: static;
                    width: 34px;
                }
                input {
                    background-color: #f2f2f2;
                    color: #666;
                    flex-grow: 1;
                    font-size: 30px;
                    font-style: normal;
                }
            }
        }
        h2 {
            border-bottom: 1px solid #e8e8e8;
            color: #666;
            font-size: 16px;
            margin: 0;
            padding: 24px;
        }
    }
    .in-order {
        border-radius: 4px;
        flex-shrink: 0;
        margin-left: 20px;
        min-height: 802px;
        padding: 0;
        width: 30%;
        .in-esttimate-shipping-content {
            padding: 0 16px;
        }
        .in-totals-wrapper {
            background-color: #fff;
            border-color: #e8e8e8;
            padding: 16px;
            .in-label {
                color: #999;
                font-size: 16px;
                font-style: normal;
            }
            .in-value span {
                color: #666;
                font-size: 16px;
            }
            .in-value[code="grand_total"] span {
                color: #333;
                font-family: @font-medium;
                font-size: 22px;
                font-weight: 500;
            }
            .discount {
              span {
                color: #ff3200;
              }
            }
            > ul {
                background-color: #f7f7f7;
                li {
                    align-items: center;
                    border-top: 1px solid #e8e8e8;
                    padding: 12px 8px;
                    &:first-child {
                        border-top: 0;
                    }
                }
            }
        }
        h3 {
            color: #666;
            font-size: 16px;
            padding: 24px 16px;
        }
    }
    > .in-container {
        align-items: flex-start;
        margin-bottom: 10rem;
    }
}
.in-shopping-cart__left {
    width: 68%;
    .in-ads-product,.in-item-wrapper {
        width: 100%;
    }
}
