#live-container {
  display: flex;
  min-height: 600px;
  #chat__container {
    width: 25%;
  }
  #video__container {
    width: 75%;
  }
  iframe {
    height: auto;
  }
}
.in-item-Info {
  padding-left: 50%;
}
.activity-coupon {
  color: red;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}
.article {
  &__info {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__title {
    font-size: 22px;
    line-height: 1.4;
  }
}
.attr-tips {
  .font-size-rem(12);
  background: #fff;
  bottom: -26px;
  box-shadow: 2px 2px 20px #b5b3b3;
  color: #000;
  display: none;
  left: 12px;
  padding: 4px 6px;
  position: absolute;
  white-space:nowrap;
  &::before {
    border-color: transparent transparent transparent #fff;
    border: 5px solid;
    content: '';
    display: table;
    height: 0;
    left: 0;
    position: absolute;
    top: -5px;
    width: 0;
  }
}
.badge {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 0;
  width: 20px;
}
.badge__more {
  color: #ff9017;
  cursor: pointer;
  display:inline-block;
}
.banner__container {
  margin-top: 20px;
}
.banner_mob {
  display: none;
}
.between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.bg-grey {
  background-color: #f7f7f7;
}
.bill-info .bill-info-table li {
  padding: 6px 0;
}
.bill-order-list {
  display: block;
  padding: 0 0 6px;
}
.bind-tips .in-modal .in-btn-group-new {
  padding: 2rem 0 0;
}
.blog-view-page & .in-ads-product-content .in-swiper {
  padding-left: 0;
  padding-right: 0;
}
.border-left {
  border-left: 1px solid #e0e0e0;
  padding: 4px 10px;
}
.buy-now-show .in-btn-wishlist {
  width: 16%;
}
.cashondelivery::before {
  background: url(../images/cashondelivery.jpg) no-repeat center;
}
.center {
  margin: 10px 0;
  text-align: center;
}
.center-group,.in-btn-group-new.center {
  justify-content: center !important;
}
.check-icon__label {
  background: #e2e2e2;
  border-radius: 50%;
  display: inline-block;
  height: 28px;
  width: 28px;
}
.comming_soon,.finished & .in-btn-group {
  background: @base-font-color;
}
.con-pay-msg &::before {
  background: url('../images/tips.png') no-repeat center;    margin-right: 10px;    vertical-align: middle;
  content: '';
  display: inline-block;
  height: 12px;
  width: 13px;
}
.copyright & p {
  .font-size-rem(20);    text-align: center;    .centered-line(55px);    color: rgba(255, 255, 255, 0.7);
  background: #242424;
}
.emoji {
  width: 23px!important;
}
.err-msg {
  color: red;
  font-size: 12px;
  padding: 8px 0;
}
.error-tips {
  color: red;
  padding: 20px;
}
.fade-enter,.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,.fade-leave-active {
  transition: opacity .5s;
}
.fans-page .in-product-list {
  margin-top: 44px;
}
.fans-share-btn {
  margin-top: 40px;
}
.field-col .native-select {
  padding: 11px 10px;
  width: 100%;
}
.flex {
  display: flex;
  &.split {
    & > div {
      padding: 0 40px;
    }
    .button-group {
      border-left: 1px solid #e0e0e0;
      .in-btn {
        margin-bottom: 20px;
      }
    }
  }
}
.flex-between {
  justify-content: space-between;
}
.flex-left {
  flex-wrap: wrap;
  flex: 1 1 80%;
}
.flex-right {
  flex: 1 1 20%;
}
.header-container {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.hide {
  display: none !important;
}
.in-404-note {
  .padding-top-rem(50);  .padding-bottom-rem(50);  color: @base-font-color;  .font-size-rem(18);
}
.in-action {
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5%;
  & i {
    .font-size-rem(25);
    color: #a1a1a1;
    cursor: pointer;
    opacity: 0;
    transition: all .5s;
  }
}
.in-add-shipAdd {
  .font-size-rem(40);
  color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.in-bank > .subTitle {
  color: #333333;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 10px;
}
.subTitle-tip {
  color: #666666;
  font-size: 24px;
  margin-bottom: 30px;
}
.in-base-banner {
  background: #ccc;
  & .swiper-slide a {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
  }
}
.in-bg-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
.in-bread {
  .centered-line(60px);
    .active {
      color: #000;
    }
    span {
      .font-size-rem(14);    color: @base-font-color;
    }
}
.in-build-link {
  align-items: center;
  display: flex;
  p {
    padding: 0 10px;
  }
}
.in-catalog-category .in-base-banner ~ .in-base-banner {
  margin-top: 50px;
}
.in-checkbox-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
    .in-checkbox-control & input {
      .centered-line(20px);
    }
    label {
      .font-size-rem(14);    .centered-line(20px);    .padding-left-rem(10);
    }
}
.in-col-2 {
  background: transparent;
  padding: 0!important;
  .in-col {
    background: #fff;
    padding: 32px 42px 60px;
    + .in-col {
      margin-top: 20px;
    }
  }
}
.in-container {
  .padding-left-rem(20);  .padding-right-rem(20);  width: 100%;
}
.in-container.in-overview-container {
  display: flex;
}
.in-country & .swiper-container {
  height: 26px;
  width: 100%;
  & .swiper-slide {
    height: auto;
  }
}
.in-coupon-container & .in-input-container {
  .margin-bottom-rem(40);
  margin-top: 0;
}
.in-dashboard-container {
  flex: 1;
  height: auto;
  width: 75%;
}
.in-des {
  .font-size-rem(14);  line-height: 28px;  .margin-bottom-rem(10);  .margin-top-rem(10);  color: #333333;
}
.in-discount-code {
  .in-input-container {
    width: 100%;
  }
  .in-title {
    .padding-top-rem(70);
  }
}
.in-kws,[data-status='Complete'] {
  color: @btn-base-color;
}
.in-lightbox {
  background: url('../images/loginRegister.jpg') no-repeat center center;  background-size: cover;
  position: relative;
  width: 50%;
  z-index: 10;
}
.in-loadmore {
  .padding-bottom-rem(50);
  .padding-top-rem(50);
  position: relative;
  &:before {
    background: url("../images/loadmore.svg") no-repeat center center;
    bottom: 0;
    content: '';
    display: block;
    height: 50px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
  }
}
.in-login-register {
  background: @bg-login-register;
  width: 50%;
  .v--modal a {
    color: @btn-base-color;
  }
}
.in-logo {
  .centered-line(110px);
  margin-right: 20px;
  text-align: left;
  & a {
    align-items: center;
    display: flex;
    height: 100%;
    width: 200px;
  }
}
.in-logout {
  .padding-top-rem(100);  .padding-bottom-rem(100);  text-align: center;
}
.in-msg {
  color: #ff0000;
  display: flex;
  line-height: 20px;
    a {
      color: @btn-base-color;
    }
    i,p {
      .font-size-rem(16);
    }
}
.in-nav-top {
  position: fixed;
  top: 0;
}
.in-note {
  .font-size-rem(14);  color: #666666;  margin: 10px 0;
}
.in-operation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & .in-btn-group {
    background: @btn-base-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & span {
      .font-size-rem(18);
    }
  }
}
.in-overflow {
  overflow: hidden;
}
.in-overview & .in-container {
  .margin-top-rem(100);    .margin-bottom-rem(100);    position: relative;    min-height: 650px;
}
.in-register-content .v--modal a {
  color: @btn-base-color;
}
.in-required,span.is-danger {
  color: #000;
  font-size: 14px;
  line-height: 1.2;
}

.in-required,span.color-danger {
  color: red;
  font-size: 14px;
  line-height: 1.2;
}

.in-search {
  cursor: pointer;
  i {
    .font-size-rem(42);  color: #000;
  }
}
.in-select__wrapper {
  border-radius: 100px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 10px 20px;
}
.in-split-panel {
  border-top: 1px solid #e8e8e8;
  padding: 1.2rem 0;
  .in-panel-title {
    color: #000;
    display: inline-block;
    width: 3.2rem;
    font-size: .6rem;
    font-weight: inherit;
    text-transform: capitalize;
  }
  .no-goods-tip {
    color: #666666;
    display: inline-block;
    font-size: 16px;
  }
}
.in-to-login,.issubscrib {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
    a,label,p {
      .font-size-rem(12);    .padding-right-rem(20);    .padding-left-rem(10);
      color: #999999;
      vertical-align: middle;
    }
}
.in-top-banner .in-bg-banner {
  height: 528px;
  width: 100%;
}
.in-triangle-right {
  border-bottom: 18px solid transparent;
  border-left: 16px solid @btn-base-color;
  border-top: 18px solid transparent;
  content: '';
  height: 0;
  width: 0;
}
.in-usps,.video-container img {
  width: 100%;
}
.in-usps {
  display: flex;
  flex-direction: column;
  img {
    margin: 0 auto;
  }
}
.in-value[code='grand_total'] {
  font-weight: 600;
}
.in-vat {
  .font-size-rem(14);
  .padding-top-rem(20);
  color: @btn-base-color;
  text-align: right;
  & i {
    vertical-align: middle;
  }
}
.in-view-container {
  height: auto;
  width: 75%;

  .in-order-like-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .in-product {
      width: 23%;
      padding: 0 0 20px;

      .in-ads-product-img {
        margin: 0 auto;
        height: 9.6rem !important;
        width: 100% !important;
        a {
          width: auto !important;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .in-ads-product-info {
        height: auto;
        padding: 0 17px;

        .in-ads-product-name {
          color: #000;
          margin-top: 1.4rem;
          display: flex;
          font-size: 13px;
          padding: 0;
        }
        .in-ads-hotProduct-price {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .in-price-final {
            font-size: 15px;
          }

          .in-price-max {
            font-size: 13px;
          }
        }
      }
    }
  }
}
.in-view-more {
  cursor: pointer;
  margin: 15px 0;
  text-align: center;
  &.active .icon-xiala1 {
    transform: rotate(180deg);    transform-origin: center;
  }
  .icon-xiala1 {
    display: inline-block;
    transition: all .5s ease;
    vertical-align: middle;
  }
  span {
    .font-size-rem(14);
    color: #666666;
  }
}
.in-welcome span {
  .centered-line(40px);
}
.info__list {
  font-size: 16px;
  line-height: 1.8;
  dd {
    font-size: 14px;
    line-height: 2;
    margin-left: 10px;
  }
}
.lazy-more {
  overflow: hidden;
  text-align: center;
  .lazy {
    background-image: url('../images/lazyload/loading-bars.svg');    background-size: cover;    background-repeat: no-repeat;    margin: 100px 0;
    display: inline-block;
    height: 50px;
    width: 50px;
  }
  .no-data {
    display: block;
    margin: 100px 0;
  }
}
.list-info {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin: 20px 0 40px;
  li {
    align-items: center;
    display: flex;
  }
  span {
    display: inline-block;
    padding: 0 5px;
  }
  span:first-child {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 11%;
  }
  span:last-child {
    color: #333;
  }
}
.log_note {
  font-size: 14px;
  line-height: 1.4;
  margin: 10px;
}
.mode-translate-fade-enter {
  opacity: 0;
  transform: translateX(10%);
}
.mode-translate-fade-enter-active {
  transition: all .3s;
}
.mode-translate-fade-leave-active {
  opacity: 0;
  transform: translateX(-10%);
  transition: all .3s;
}
.native-select {
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  display: inline-block;
  padding: 10px;
  select {
    appearance: auto;
    background: initial;
    option :hover {
      background: #000;
    }
  }
  select:active,select:focus {
    appearance: auto;
    background: initial;
  }
}
.no-data {
  font-size: 14px;
  margin: 1rem;
  text-align: center;
}
.no-payment-tips {
  font-size: 22px;
  padding: 24px;
}
.no-results {
  color: #7c7c7c;
  font-size: 14px;
  margin: 20px 0;
  text-align: center;
}
.page-container {
  .in-menus-container {
    padding-top: 322px !important;
  }
  header {
    left: 0;
    position: sticky;
    top: 0;
    width: 100%;
  }
}

.is-danger {
  border: 1px solid #ff6b00 !important;
}

.help {
  font-size: 14px;
  top: 2px;
  position: relative;
}
