.in-cms-coupon-page {
    .in-blog-view-container & .in-title h5 {
        .padding-bottom-rem(20);
    }
    .in-coupon-list {
        .margin-bottom-rem(100);
        .margin-top-rem(100);
        justify-content: center;
        & .in-coupon {
            cursor: initial;
            width: 600px;
            .in-coupon-sale & h6 {
                .padding-top-rem(50);          .padding-bottom-rem(50);          .font-size-rem(60);          background-size: 12px 9px;
            }
            .in-coupon-time {
                .padding-bottom-rem(30);
                .padding-top-rem(30);
                .in-btn-group {
                    .centered-line(44px);
                    .margin-top-rem(30);
                    border-radius: 26px;
                    width: 220px;
                    & span {
                        .font-size-rem(18);            font-weight: 400;
                    }
                }
                p {
                    .font-size-rem(18);          line-height: 18px;          .padding-left-rem(40);          .padding-right-rem(40);
                }
            }
        }
    }
}
