.lb-cancel {
    background:url(../images/lazyload/loading-bars.svg) no-repeat;
    display:block;
    height:50px;
    margin:0 auto;
    width:50px;
}
.lb-container > .nav {
    left:0;
}
.lb-data {
    color: #ccc;
    padding: 0 4px;
    .lb-caption {
        font-size: 13px;
        font-weight: 700;
        line-height: 1em;
        a {
            color:#4ae;
        }
    }
    .lb-close {
        background:url(../images/close.png) top right no-repeat;text-align:right;outline:0;filter:alpha(Opacity=70);opacity:.7;-webkit-transition:opacity .2s;-moz-transition:opacity .2s;-o-transition:opacity .2s;transition:opacity .2s;
        display:block;
        float:right;
        height:30px;
        width:30px;
    }
    .lb-close:hover {
        cursor:pointer;
        filter:alpha(Opacity=100);opacity:1;
    }
    .lb-details {
        float:left;
        line-height:1.1em;
        text-align:left;
        width:85%;
    }
    .lb-number {
        clear:left;
        color:#999;
        display:block;
        font-size:12px;
        padding-bottom:1em;
    }
}
.lb-dataContainer {
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    margin:0 auto;
    padding-top:5px;
    width:100%;
}
.lb-dataContainer:after,.lb-outerContainer:after {
    clear: both;
    content: "";
    display: table;
}
.lb-loader {
    height: 25%;
    left: 0;
    line-height: 0;
    position: absolute;
    text-align: center;
    top: 43%;
    width: 100%;
}
.lb-nav {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    a {
        background-image:url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
        outline:0;
    }
    a.lb-next {
        background:url(../images/next.png) right 48% no-repeat;filter:alpha(Opacity=0);opacity:0;-webkit-transition:opacity .6s;-moz-transition:opacity .6s;-o-transition:opacity .6s;transition:opacity .6s;
        float:right;
        right:0;
        width:64%;
    }
    a.lb-next:hover,a.lb-prev:hover {
        filter:alpha(Opacity=100);opacity:1;
    }
    a.lb-prev {
        background:url(../images/prev.png) left 48% no-repeat;filter:alpha(Opacity=0);opacity:0;-webkit-transition:opacity .6s;-moz-transition:opacity .6s;-o-transition:opacity .6s;transition:opacity .6s;
        float:left;
        left:0;
        width:34%;
    }
}
.lb-next,.lb-prev {
    cursor:pointer;
    display:block;
    height:100%;
}
.lb-outerContainer {
    background-color:#fff;
    border-radius:4px;
    height:250px;
    margin:0 auto;
    position:relative;
    width:250px;
}
