.in-preview {
    box-sizing: content-box;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 650px;
    .in-swiper {
        height: 650px;
        width: 100%;
    }
    .swiper-pagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        .swiper-pagination-bullet {
            .margin-bottom-rem(10);        background: #fff;        opacity: 1;
            display: block;
        }
        .swiper-pagination-bullet-active {
            background: @btn-base-color;
            opacity: 1;
        }
        span {
            height: 14px;
            width: 14px;
        }
    }
    .swiper-slide {
        .padding-left-rem(20);    .padding-right-rem(20);    display: flex;    justify-content: center;    align-items: center;
    }
}
