.in-group-purchase-container {
    text-align: center;
    .in-ads-title {
        .padding-top-rem(60);
    }
    .in-not-found-page {
        .margin-top-rem(-50);
    }
    .in-product-timer {
        border-radius: 5px;
        & ul {
            background: none;
            border: none;
        }
    }
}
.in-group-purchase-wrapper {
    .margin-top-rem(60);  .margin-bottom-rem(60);
    .in-group-item {
        overflow: hidden;
    }
}
