.in-input-col {
    .padding-bottom-rem(20);
    display: table;
    width: 100%;
    .in-input-control {
        align-self: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        .help {
            .font-size-rem(12);      .centered-line(40px);      .padding-left-rem(20);
        }
        input {
            .padding-left-rem(20);      .padding-right-rem(20);      width: 400px;      .font-size-rem(16);      color: #000;
            background: #fff;
            border-radius: 100px;
            border: 1px solid #ccc;
            height: 40px;
            outline: none;
        }
        input:-webkit-autofill,select:-webkit-autofill,textarea:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px #fff inset;
            background-image: none;
        }
    }
    label {
        .font-size-rem(14);    .centered-line(40px);
    }
}
.in-input-container {
    .inline-block();
    .margin-bottom-rem(20);
    .margin-top-rem(20);
    position: relative;
    & input {
        .centered-line(40px);    background: #fff;    border: 1px solid #cccccc;    padding: 0;    outline: none;    border-radius: 100px;    .padding-left-rem(20);    .padding-right-rem(60);
        width: 320px;
    }
    .in-submit {
        .inline-block();    .centered-line(40px);    width: 70px;    background: @btn-base-color;    color: #fff;    border-top-right-radius: 100px;    border-bottom-right-radius: 100px;    position: absolute;    right: 0;    top: 0;    text-align: center;    .font-size-rem(14);    cursor: pointer;
    }
}
