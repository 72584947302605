.in-order-view-content {
    .padding-bottom-rem(140);
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 3rem;
    .in-order-information {
        background: #fff;
        .history-info {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-left: 100px;
            & .info {
                margin-left: 20px;
                .tel {
                    color: @btn-base-color;
                    line-height: 30px;
                }
                h6 {
                    .font-size-rem(18);          font-weight: 100;          line-height: 30px;
                }
                p {
                    .font-size-rem(14);
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
        .iconfont {
            .font-size-rem(80);
            color: @base-font-color;
        }
        .in-information-wrapper {
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            flex-direction: row;
            padding: 30px 40px;
        }
        .in-payment-info {
            padding: 30px 40px;
            & p {
                .font-size-rem(18);
            }
            &::before {
                content: '';
                display: block;
                height: 70px;
                width: 266px;
            }
        }
        > h6 {
            .font-size-rem(18);      font-weight: 400;      border-bottom: 1px solid #e6e6e6;      padding-left: 40px;
            line-height: 62px;
        }
    }
    table {
        .margin-bottom-rem(20);
        table-layout: fixed;
        width: 100%;
        .history-order-header {
            background: #f5f5f5;
            .check {
                width: 5%;
            }
            .condition {
                width: 10%;
            }
            .qty,.total {
                width: 15%;
            }
        }
        .history-order-title {
            line-height: 62px;
            & span {
                .font-size-rem(18);
            }
            .btn-rma {
                .font-size-rem(14);        margin-left: 10px;
                color: #999;
                cursor: pointer;
            }
            .in-back {
                .padding-right-rem(30);
                color: #999;
                cursor: pointer;
                text-align: right;
                & span {
                    .font-size-rem(14);
                }
            }
        }
        .item {
            border-bottom: 1px solid #e6e6e6;
            & td {
                .padding-top-rem(20);        .padding-bottom-rem(20);
            }
        }
        .subtotal {
            border-top: 1px solid #eee;
        }
        .total {
            border-bottom: none;
            & td {
                .padding-bottom-rem(10);
                .padding-top-rem(10);
                &:first-child {
                    text-align: right;
                }
            }
        }
        tbody {
            th span {
                .font-size-rem(14);        .centered-line(40px);
                font-weight: 400;
            }
            tr td {
                text-align: center;
            }
        }
        td {
            padding: 0;
            span {
                .font-size-rem(14);      word-wrap: break-word;
            }
        }
        tfoot {
            .centered-line(40px);
        }
        th {
            padding: 0;
        }
    }
    .continue-pay-wrapper .in-btn .in-bg-orange {
        background-color: @main-btn-color;
        cursor: pointer;
    }
    .grand_total {
        color: #666666;
        font-size: 18px;
    }
    .in-dashboard-container {
        flex-grow: 1;
    }
    .in-items-ordered {
        padding: 0 24px 24px;
        &:hover {
            box-shadow: none;
        }
    }
    .in-menus-container {
        flex-shrink: 0;
        margin-right: 20px;
        padding-right: 0;
        padding-top: 302px;
        width: 360px;
        .active,li:hover {
            .in-triangle-right {
                background-color: #000;
                border: 0;
                display: block;
                // height: 50px;
                left: -20px;
                position: absolute;
                top: 12px;
                width: 4px;
            }
            i {
                color: @main-btn-color;
            }
            span {
                background-color: transparent;
                color: #000;
                flex-grow: 1;
            }
        }
        > ul {
            background-color: #fff;
        }
        li {
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            height: 74px;
            padding-right: 0;
            position: relative;
            &:last-child {
                border-bottom: 0;
            }
            .in-triangle-right {
                display: none;
            }
            i {
                color: #666;
                font-size: 42px;
                margin-right: 10px;
            }
            span {
                color: #666;
                font-size: 28px;
                padding-left: 1.2rem;
            }
        }
    }
    .in-order-information {
        margin-top: 20px;
        padding: 0 24px 24px;
        &:hover {
            box-shadow: none;
        }
        .in-information-wrapper {
            border-bottom: 1px solid #e8e8e8;
            border: 0;
            padding: 29px 0;
            .history-info {
                box-sizing: border-box;
                margin-left: 0;
                padding-right: 20px;
                width: 50%;
                > i {
                    color: #999999;
                    font-size: 54px;
                }
            }
            .info {
                margin-left: 24px;
                .tel {
                    color: #999999;
                    font-size: 20px;
                    line-height: 1em;
                    margin-top: 8px;
                }
                h6 {
                    color: #333333;
                    font-size: 24px;
                    line-height: 1em;
                    margin-bottom: 8px;
                }
                p {
                    color: #666666;
                    font-size: 22px;
                }
            }
        }
        .in-payment-info {
            align-items: center;
            display: flex;
            padding: 11px 0;
            &::before {
                display: none;
            }
            .info {
                margin-left: 24px;
                h6 {
                    color: #333333;
                    font-size: 24px;
                    font-weight: normal;
                    line-height: 1em;
                    margin-bottom: 8px;
                }
                p {
                    color: #666666;
                    font-size: 22px;
                }
            }
            > i {
                color: #999999;
                font-size: 54px;
            }
        }
        > h6 {
            border-bottom: 1px solid #e8e8e8;
            font-size: 24px;
            height: 64px;
            padding-left: 0;
        }
    }
    .in-ram-popup {
        .in-ram-popup__content .subTitle {
            border-bottom: 0;
            font-size: 18px;
            margin-bottom: 0;
        }
        .in-title {
            background-color: #fff;
            h3 {
                color: #333;
                font-size: 28px;
                padding-top: 0px;
            }
            i {
                color: #333;
            }
        }
        table .item td {
            padding: 0 10px;
        }
        th.total {
            margin-top: 0;
        }
    }
    .subtotal {
        margin-top: 16px;
    }
    .total {
        color: #999999;
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-top: 12px;
        min-width: 300px;
        div:first-child {
            font-style: italic;
            margin-right: 20px;
        }
    }
    .total-area {
        align-items: flex-end;
        color: #666;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
    }
    table {
        margin-bottom: 0;
        .history-order-header {
            background-color: #eee;
            th {
                border: 1px solid #e8e8e8;
                span {
                    align-items: center;
                    color: #666;
                    display: flex;
                    font-size: 22px;
                    height: 64px;
                    justify-content: center;
                    line-height: 1.2em;
                }
            }
        }
        .history-order-title {
            line-height: 52px;
            .in-back {
                padding-right: 0;
                i {
                    color: #999999;
                    font-size: 34px;
                }
            }
            span {
                color: #333;
                display: inline-block;
                font-size: 24px;
                line-height: 1em;
            }
        }
        .item {
            border-bottom: 0;
            td {
                border: 1px solid #e8e8e8;
                padding: 0;
                span {
                    align-items: center;
                    color: #666;
                    display: flex;
                    font-size: 20px;
                    justify-content: center;
                    line-height: 1.2em;
                    min-height: 112px;
                    padding: 20px;
                    word-break: break-word;
                }
            }
        }
    }
}
